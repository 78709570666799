import fullyHomomorphicEncryption from '../../../resources/img/FullyHomomorphicEncryption.png'
import '../../../styles/privacyEnhancingTechnologies/_keyPrivacyEnhancingTechnologies.scss'
import { CSS } from '../../../utils/domUtils'

const FullyHomomorphicEncryption = () => {
	return (
		<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT}>
			<div>
				<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_BLOCK}>
					<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_TITLE}>WHAT DOES IT DO?</div>
					<div>
						Fully homomorphic encryption encrypts data and - crucially -{' '}
						<b>allows data to remain encrypted during analysis.</b>
					</div>
					<div>
						With FHE, the data controller locks their data using an encryption key that only they own.
					</div>
					<div>
						This encrypted data is then sent to a data processor, who can run calculations on it without
						ever decrypting the data or accessing it in unencrypted form.
					</div>
					<div>
						These computations produce encrypted results which can only be unlocked by using the data
						controller’s key.
					</div>
					<div>
						Imagine that the original data owner’s private data is a sealed envelope. With FHE, the contents
						of the envelope can be analyzed without ever opening it.
					</div>
				</div>
				<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_BLOCK}>
					<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_TITLE}>DOES XTENDR USE IT?</div>
					<div>
						Yes, we use fully homomorphic encryption to allow computations to take place in a secure way
						while retaining maximum data utility.
					</div>
				</div>
			</div>
			<div>
				<div className={CSS.IMG_WRAPPER}>
					<img src={fullyHomomorphicEncryption} alt={'Fully Homomorphic Encryption'} />
				</div>
			</div>
		</div>
	)
}

export default FullyHomomorphicEncryption
