import { useState } from 'react'
import { Helmet } from 'react-helmet'

import { ReactComponent as ArrowDown } from '../../resources/img/firstSectionArrowDown.svg'
import '../../styles/_confirmSubscription.scss'
import { CSS } from '../../utils/domUtils'
import Header from '../common/Header'

const ConfirmSubscriptionRoute: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<div className={CSS.CONFIRM_SUBSCRIPTION}>
			<Helmet>
				<title>Confirm subscription</title>
			</Helmet>
			<div className={`${CSS.TITLE_CARD} ${CSS.BACKGROUND}`}>
				<div className={CSS.CONTAINER}>
					<Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
					<div className={`${CSS.FLEX_CENTER} ${CSS.FLEX_COLUMN}`}>
						<h1 className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>Email confirmed</h1>
					</div>
					<div className={CSS.TITLE_ARROW}>
						<ArrowDown />
					</div>
					<div className={CSS.SUB_TEXT}>Thank you for subscribing!</div>
				</div>
			</div>
		</div>
	)
}

export default ConfirmSubscriptionRoute
