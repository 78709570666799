import { TextField } from '@mui/material'
import React, { useCallback } from 'react'

import { ReactComponent as SearchIcon } from '../../../resources/img/searchIcon.svg'
import { modifiedArticleItems } from '../../data/articles'
import { RenderItemsProps } from '../YouMightBeInterested'

const textFieldSX = {
	'&.MuiTextField-root': {
		background: '#ECFFFA',
		borderRadius: '100px',
		width: '490px',
		'& > *': {
			borderRadius: '100px',
		},
		'.MuiInputBase-input': {
			padding: '20px 35px',
		},
		'.MuiFormLabel-root, .MuiInputBase-input': {
			fontFamily: 'Degular',
			fontSize: '20px',
			fontWeight: 555,
			lineHeight: '24px',
			letterSpacing: '0.02em',
		},
		'.MuiFormLabel-root, .MuiFormLabel-root': {
			top: '-25px',
			color: 'rgba(15, 25, 54, 0.5)',
		},
		'.MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled': {
			color: '#00C495',
		},
		'.MuiInputBase-root': {
			paddingRight: 0,
			fieldset: {
				top: 0,
				border: '2px solid #0F1936',
				transition: 'all 0.3s ease-in-out',
				'& > legend': {
					display: 'none',
				},
			},
		},
		'.MuiInputBase-root.Mui-focused': {
			fieldset: {
				border: '2px solid #00C495',
				borderRadius: '100px',
			},
		},
		'&:hover': {
			fieldset: {
				border: '2px solid #00C495',
				borderWidth: '2px!important',
			},
		},
		button: {
			padding: '21px 40px',
			background: '#0F1936',
			borderRadius: '100px',
			fontFamily: 'Degular',
			fontSize: '20px',
			fontWeight: '555',
			lineHeight: '24px',
			letterSpacing: '0.02em',
			textAlign: 'center',
			color: '#ECFFFA',
			transition: 'all 0.3s ease-in-out',
			border: 0,
			'& > svg': {
				display: 'none',
			},
			'&:hover': {
				background: '#00C495',
			},
		},
	},
}

type SearchProps = {
	onSearchFilter: (articles: RenderItemsProps[]) => void
	value: string
	onValueChange: (value: string) => void
}

const removePunctuation = (text: string): string => text.replace(/[.,#!$%^&*;:{}=\-_`~()]/g, '').toLowerCase()

const CustomSearchField: React.FC<SearchProps> = ({ onSearchFilter, value, onValueChange }) => {
	const handleChangeValue = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onValueChange(e.target.value)
		},
		[onValueChange]
	)

	const handleClickSearch = useCallback(() => {
		const normalizedSearchQuery = removePunctuation(value)

		const results = modifiedArticleItems.filter((item) => {
			const normalizedTitle = removePunctuation(item.title)
			const normalizedDescription = removePunctuation(item.description)
			if (normalizedTitle.includes(normalizedSearchQuery)) return true
			if (normalizedDescription.includes(normalizedSearchQuery)) return true
			const normalizedContent = item.content ? removePunctuation(item.content) : ''

			if (normalizedContent.includes(normalizedSearchQuery)) return true
			return false
		})
		onSearchFilter(results)
	}, [value, onSearchFilter])

	return (
		<TextField
			placeholder="TYPE HERE"
			type="text"
			label={'SEARCH'}
			value={value}
			onChange={handleChangeValue}
			sx={textFieldSX}
			InputLabelProps={{ shrink: true }}
			InputProps={{
				endAdornment: (
					<button onClick={handleClickSearch}>
						<span>SEARCH</span>
						<SearchIcon />
					</button>
				),
			}}
		/>
	)
}

export default CustomSearchField
