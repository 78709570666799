import React from 'react'

import '../../styles/aboutUs/_whyUs.scss'
import { CSS } from '../../utils/domUtils'

const WhyUs: React.FC = () => {
	return (
		<div className={CSS.WHY_US}>
			<div className={CSS.CONTAINER}>
				<h2 className={CSS.WHY_US_TITLE}>Why us?</h2>
				<div className={CSS.WHY_US_CONTENT}>
					<div className={CSS.WHY_US_CARD}>
						<div className={CSS.WHY_US_CARD_LABEL}>knowledge</div>
						<div className={CSS.WHY_US_CARD_DESCRIPTION}>
							xtendr is built on a strong foundation of cryptographic knowledge, including several{' '}
							<b>cryptographic patents</b> which have formed the foundation of what we do.
						</div>
					</div>
					<div className={CSS.WHY_US_CARD}>
						<div className={CSS.WHY_US_CARD_LABEL}>Technology</div>
						<div className={CSS.WHY_US_CARD_DESCRIPTION}>
							Today, <b>we combine a deep understanding of data privacy with the awareness</b> that the
							technology that supports it is changing rapidly - which means that every project is
							different.
						</div>
					</div>
					<div className={CSS.WHY_US_CARD}>
						<div className={CSS.WHY_US_CARD_LABEL}>compliance</div>
						<div className={CSS.WHY_US_CARD_DESCRIPTION}>
							<b>At xtendr, we therefore strive to be adaptable while maintaining excellence</b> - through
							close communication with our clients and a commitment to fully understanding the needs of
							every single project.
						</div>
					</div>
					<div className={CSS.WHY_US_CARD}>
						<div className={CSS.WHY_US_CARD_LABEL}>research</div>
						<div className={CSS.WHY_US_CARD_DESCRIPTION}>
							At the same time, we are in constant pursuit of the very highest data privacy standards,
							which means that{' '}
							<b>we’re constantly researching new approaches and improving on existing ones,</b> to ensure
							that we’re always at the cutting edge.
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default WhyUs
