import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { ReactComponent as ArrowDown } from '../../resources/img/arrowDownGreen.svg'
import '../../styles/_qualityAssurance.scss'
import { CSS } from '../../utils/domUtils'
import Header from '../common/Header'

const QualityAssuranceRoute = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<div className={`${CSS.QUALITY_ASSURANCE} ${CSS.BACKGROUND}`}>
			<Helmet>
				<title>Quality Assurance</title>
			</Helmet>
			<div className={CSS.WRAPPER}>
				<Header isLight={true} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
				<div className={CSS.QUALITY_ASSURANCE_BLOCK}>
					<h1 className={CSS.QUALITY_ASSURANCE_TITLE}>QUALITY ASSURANCE</h1>
					<div className={CSS.TEXT}>
						<div className={CSS.CARD_SUBTITLE}>
							Since 2019, Xtendr Zrt. has been developing its quality management, environmental
							management, and information security practices. In 2020, we underwent a comprehensive audit
							and obtained <strong>ISO 9001, ISO 14001, and ISO 27001</strong> certifications, ensuring
							that our processes meet the highest standards for quality, environmental responsibility, and
							data protection.
						</div>
						<div className={CSS.CARD_SUBTITLE}>
							<div className={CSS.CARD_TEXT}>
								We conduct annual reviews to maintain and verify the sustainability of our integrated
								management system’s certification.
							</div>
						</div>
						<h2 className={CSS.MAIN_TEXT}>Downloadable materials:</h2>
						<div className={CSS.CARD_SUBTITLE}>
							<a
								href={
									'https://drive.google.com/file/d/1qgU4ulTILs0MRdxE3DBrtQWnGThD044R/view?usp=sharing'
								}
								target="_blank"
								rel="noreferrer"
							>
								<p>Integrated Management Policy</p>
							</a>{' '}
							<a
								href={
									'https://drive.google.com/file/d/1FCOPK2e9-9sRmWW0TzOKN0C-94o3mjWD/view?usp=sharing'
								}
								target="_blank"
								rel="noreferrer"
							>
								<p>ISO 9001 & 14001 Certification</p>
							</a>{' '}
							<a
								href={
									'https://drive.google.com/file/d/1IuxbA4NbZH8jXYC_hsseXkjyHdzKpAmU/view?usp=sharing'
								}
								target="_blank"
								rel="noreferrer"
							>
								<p>ISO 27001 Certification</p>
							</a>{' '}
						</div>
					</div>
				</div>
				<div className={CSS.TITLE_ARROW}>
					<ArrowDown />
				</div>
			</div>
		</div>
	)
}

export default QualityAssuranceRoute
