import React, { useCallback } from 'react'

import { CSS } from '../../../utils/domUtils'

const filtersItems = ['Paper', 'Whitepaper', 'Article', 'Update']

type FiltersProps = {
	selectedFilters: string[]
	onFilterChange: (filters: string[]) => void
	onClearFilters: () => void
}

const Filters: React.FC<FiltersProps> = ({ selectedFilters, onFilterChange, onClearFilters }) => {
	const handleClickItem = useCallback(
		(e: React.MouseEvent<HTMLDivElement>): void => {
			const itemTextWithLineEndings: string = e.currentTarget.innerText.toLowerCase()
			const itemText: string = itemTextWithLineEndings.replace(/\n/g, '')
			const filterElement = e.currentTarget.closest('div')
			const isClickedElementActive: boolean | undefined = filterElement?.classList.contains('active')
			if (isClickedElementActive) {
				const filterToRemove = itemText.endsWith('×') ? itemText.slice(0, itemText.length - 1) : itemText
				const newSelectedFilters = selectedFilters.filter((item) => item !== filterToRemove)
				e.currentTarget.classList.remove('active')
				onFilterChange(newSelectedFilters)
			} else {
				const newSelectedFilters = [...selectedFilters, itemText]
				e.currentTarget.classList.add('active')
				onFilterChange(newSelectedFilters)
			}
		},
		[selectedFilters, onFilterChange]
	)

	return (
		<div className={CSS.FILTERS}>
			<div className={CSS.FILTERS_LABEL}>Filter by</div>
			<div className={CSS.FILTERS_ITEMS}>
				{filtersItems.map((item, index) => (
					<div
						key={index}
						className={
							selectedFilters.includes(item.toLowerCase())
								? `${CSS.FILTERS_ITEM} active`
								: `${CSS.FILTERS_ITEM}`
						}
						onClick={handleClickItem}
					>
						<span>{item}</span> <span className="icon">×</span>
					</div>
				))}
			</div>
			<button onClick={onClearFilters}>clear all filters</button>
		</div>
	)
}

export default Filters
