import React from 'react'
import { Link } from 'react-router-dom'

import illustration from '../../resources/img/secureData.svg'
import { ReactComponent as SecureDataArrow } from '../../resources/img/secureDataArrow.svg'
import '../../styles/homePage/_secureDataCard.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'

const items = [
	{ title: 'Healthcare', link: `${PATHS.INDUSTRY_OVERVIEW}${PATHS.HEALTHCARE}` },
	{ title: 'Finance', link: `${PATHS.INDUSTRY_OVERVIEW}${PATHS.FINANCE}` },
	{ title: 'manufacturing', link: `${PATHS.INDUSTRY_OVERVIEW}${PATHS.MANUFACTURING}` },
	{ title: 'social initiatives', link: PATHS.COMING_SOON },
]

const SecureDataCard: React.FC = () => (
	<div className={CSS.SECURE_DATA_CARD}>
		<div className={CSS.CONTAINER}>
			<div className={`${CSS.FLEX_ROW} ${CSS.SECURE_DATA_FIRST_ROW}`}>
				<img className={CSS.SECURE_DATA_IMG} src={illustration} alt={'Secure data collaboration'} />
				<div>
					<h2 className={CSS.CARD_TITLE}>Secure data collaboration</h2>
					<div className={CSS.CARD_SUBTITLE}>
						xtendr facilitates secure data sharing between teams, departments and organizations, across
						borders and industries.
						<br />
						<br />
						<b>Whether you are looking for a fully secure way to share data</b> with an external service
						provider, or want to find new patterns, answers and insights in a broader and more diverse range
						of data sources, <b>we can help.</b>
					</div>
				</div>
			</div>
			<img className={CSS.SECURE_DATA_IMG_MOBILE} src={illustration} alt={'Secure data collaboration'} />
			<h3 className={CSS.PRIVACY_TEXT}>
				At every stage, xtendr uses a unique combination of{' '}
				<Link to={PATHS.PRIVACY_ENHANCING_TECHNOLOGIES}>Privacy Enhancing Technologies</Link> to ensure that
				data is fully protected, and sensitive information is never visible
			</h3>
			<div className={CSS.PRIVACY_SUBTEXT}>
				- meaning that collaboration is compliant with data protection laws and regulations, whatever industry
				you are in.
			</div>
			<div className={CSS.PRIVACY_CARD_CONTAINER}>
				{items.map((item, index) => (
					<Link to={item.link} className={CSS.PRIVACY_CARD} key={index}>
						<SecureDataArrow />
						<span>{item.title}</span>
					</Link>
				))}
			</div>
		</div>
	</div>
)

export default SecureDataCard
