import React from 'react'
import { Link } from 'react-router-dom'

import collaborationProjectImg from '../../resources/img/demo.png'
import '../../styles/collaborationPlatform/_demo.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import ButtonGreen from '../common/ButtonGreen'

const DemoCard: React.FC = () => {
	return (
		<div className={`${CSS.DEMO_CARD} ${CSS.CONTAINER}`}>
			<div className={CSS.WRAPPER}>
				<div className={CSS.IMG_WRAPPER}>
					<img className={CSS.IMG} src={collaborationProjectImg} alt={'Collaboration platform scrrenshot'} />
					<div className={CSS.TRY_DEMO}>
						<ButtonGreen buttonText={'TRY THE DEMO'} to={PATHS.DEMO} />
					</div>
				</div>
				<div className={CSS.PLANNING}>
					<h4 className={CSS.PLANNING_TITLE}>
						Planning a long-term, ongoing collaboration, or looking for features or outcomes that aren’t on
						the list?
					</h4>
					<div className={CSS.PLANNING_SUBTITLE}>
						Take a look at our{' '}
						<Link to={PATHS.CUSTOM_COLLABORATION_SOLUTIONS}>
							<u>fully customizable solutions</u>
						</Link>{' '}
						instead.
					</div>
				</div>
			</div>
		</div>
	)
}

export default DemoCard
