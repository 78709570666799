import { Link } from 'react-router-dom'

import { ReactComponent as ProblemIcon } from '../../resources/img/problemIcon.svg'
import { ReactComponent as SolutionIcon } from '../../resources/img/solutionIcon.svg'
import '../../styles/specificUseCase/_problemSolution.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import { UsecaseType } from './useCaseTexts'

const ProblemSolution: React.FC<Partial<UsecaseType>> = ({
	problemComponent: problem,
	solutionComponent: solution,
}) => (
	<div className={CSS.LIGHT_BACKGROUND}>
		<div className={CSS.PROBLEM_SOLUTION}>
			<div className={CSS.PROBLEM_SOLUTION_BLOCK}>
				<div className={CSS.PROBLEM_SOLUTION_TITLE}>
					<ProblemIcon />
					<div>The problem</div>
				</div>
				{problem &&
					problem.map((Problem, index) => (
						<div key={index} className={CSS.PROBLEM_SOLUTION_TEXT}>
							<Problem />
						</div>
					))}
			</div>
			<div className={CSS.PROBLEM_SOLUTION_BLOCK}>
				<div className={CSS.PROBLEM_SOLUTION_TITLE}>
					<SolutionIcon />
					<div>xtendr’s solution</div>
				</div>
				{solution &&
					solution.map((Solution, index) => (
						<div key={index} className={CSS.PROBLEM_SOLUTION_TEXT}>
							<Solution />
						</div>
					))}
				<Link to={PATHS.COLLABORATION_PLATFORM} className={CSS.PROBLEM_SOLUTION_BUTTON}>
					How it works →
				</Link>
			</div>
		</div>
	</div>
)

export default ProblemSolution
