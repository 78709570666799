import React, { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'

import { useEmailSubmit } from '../../hooks/useEmailSubmit'
import '../../styles/_contact.scss'
import { EmailSeverity, SnackBarSeverity } from '../../utils/const'
import { CSS } from '../../utils/domUtils'
import EmailSnackbarComponent from '../common/EmailSnackBarComponent'
import Header from '../common/Header'
import CustomInput from '../common/formComponents/CustomInput'

const ContactRoute: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const formRef = useRef<HTMLFormElement>(null)
	const handleSubmit = useEmailSubmit()
	const [snackbarState, setSnackbarState] = useState({
		open: false,
		message: '',
		severity: SnackBarSeverity.SUCCESS as EmailSeverity,
	})

	const handleSnackbarClose = () => {
		setSnackbarState({ ...snackbarState, open: false })
	}

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const result = await handleSubmit(event)
		setSnackbarState({
			open: true,
			message: result.message,
			severity: result.success ? SnackBarSeverity.SUCCESS : SnackBarSeverity.ERROR,
		})
	}

	return (
		<div className={CSS.CONTACT}>
			<Helmet>
				<title>Contact Us</title>
			</Helmet>
			<div className={CSS.CONTAINER}>
				<Header isLight={true} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
				<h1 className={CSS.CARD_TITLE}>Contact Us</h1>
				<div className={CSS.CONTACT_CONTAINER}>
					<div className={CSS.CONTACT_CONTENT}>
						<h2 className={CSS.MAIN_TEXT}>
							If you’ve got a use case in mind, are interested in partnership, or would just like to know
							more, we’re always happy to hear from you.
							<br />
						</h2>
						<div className={CSS.SUB_TEXT}>
							Fill out the contact form opposite, or email us at{' '}
							<a href="mailto:hello@xtendr.io">hello@xtendr.io</a> and someone from the team will get back
							to you!
						</div>
					</div>
					<form className={CSS.CONTACT_FORM} onSubmit={onSubmit} ref={formRef}>
						<div className={CSS.CONTACT_ROW}>
							<CustomInput label="FIRST NAME" name="firstName" />
							<CustomInput label="LAST NAME" name="lastName" />
						</div>
						<CustomInput label="EMAIL ADDRESS" type="email" name="email" />
						<CustomInput label="MESSAGE" multiline={true} name="message" />
						<button type="submit" className={CSS.CONTACT_SUBMIT}>
							SUBMIT
						</button>
					</form>
				</div>
			</div>
			<EmailSnackbarComponent
				open={snackbarState.open}
				message={snackbarState.message}
				severity={snackbarState.severity}
				onClose={handleSnackbarClose}
			/>
		</div>
	)
}

export default ContactRoute
