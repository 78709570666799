import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

import { CSS } from '../../utils/domUtils'

type SmallIllustrationCardProps = {
	setIsMenuOpen: (isOpen: boolean) => void
	illustration: FunctionComponent
	redirectLink: string
}

const SmallIllustrationCard: React.FC<SmallIllustrationCardProps> = ({
	setIsMenuOpen,
	illustration: Illustration,
	redirectLink,
}) => {
	return (
		<Link to={redirectLink} onClick={() => setIsMenuOpen(false)}>
			<div className={CSS.SMALL_CARD}>
				<Illustration />
			</div>
		</Link>
	)
}

export default SmallIllustrationCard
