import { Helmet } from 'react-helmet'

import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import ButtonDark from '../common/ButtonDark'
import GreenLink from '../common/GreenLink'
import UseCasesPanel from '../common/UseCasesPanel'
import YouMightBeInterested from '../common/YouMightBeInterested'
import { modifiedArticleItems } from '../data/articles'
import IndustryOutcomes from './IndustryOutcomes'
import { IndustryOverviewType } from './IndustryText'
import PowerBlock from './PowerBlock'
import TitleCard from './TitleCard'

type IndustryOverviewRouteProps = {
	industryOverview: IndustryOverviewType
}

const IndustryOverviewRoute: React.FC<IndustryOverviewRouteProps> = ({ industryOverview }) => {
	const { industry, title, img, shortDescription, StatementOne, StatementTwo, Description, outcomes } =
		industryOverview

	return (
		<div className={CSS.INDUSTRY_OVERVIEW}>
			<Helmet>
				<title>Industry Overview: {industry}</title>
			</Helmet>
			<TitleCard industry={industry} title={title} shortDescription={shortDescription} img={img} />
			<PowerBlock StatementOne={StatementOne} StatementTwo={StatementTwo} />
			<IndustryOutcomes Description={Description} outcomes={outcomes} />
			<div style={{ display: 'none' }}>
				<YouMightBeInterested items={modifiedArticleItems} />
			</div>
			<GreenLink
				route={PATHS.HOW_IT_WORKS}
				title={'How our solution works'}
				margin={window.innerWidth > 1800 ? '35%' : '25%'}
			/>
			<UseCasesPanel industry={industry} />
			<div className={CSS.CONTAINER}>
				<div className={CSS.SPECIFIC_USE_CASE_GET_IN_TOUCH}>
					<div className={CSS.SPECIFIC_USE_CASE_GET_IN_TOUCH_TITLE}>
						Have another use case in mind, or want to know more?
					</div>
					<ButtonDark buttonText={'get in touch'} to={PATHS.CONTACT} />
				</div>
			</div>
		</div>
	)
}

export default IndustryOverviewRoute
