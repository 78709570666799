import useScrollPosition from '../../hooks/useScrollPosition'

type LogoGreenProps = {
	isMenuOpen: boolean
}

const LogoGreen = ({ isMenuOpen }: LogoGreenProps) => {
	const scrollPosition = useScrollPosition()

	return (
		<div className={`changed-logo ${isMenuOpen ? 'side-menu-opened' : scrollPosition > 20 ? 'scrolled' : ''}`}>
			<svg
				className="circle"
				width="56"
				height="56"
				viewBox="0 0 56 56"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect width="56" height="56" rx="28" fill="#0F1936" />
				<path
					d="M27.9704 22.6177L17 16V22.2276L27.9704 28.5214L38.9482 22.2276V16L27.9704 22.6177Z"
					fill="#00C495"
				/>
				<path
					d="M27.9704 33.6889L17 40.3066V34.079L27.9704 27.7778L38.9482 34.079V40.3066L27.9704 33.6889Z"
					fill="#00C495"
				/>
			</svg>

			<svg
				className="text side-menu-opened-logo"
				width="146"
				height="33"
				viewBox="0 0 146 33"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="Logo">
					<path
						id="Vector"
						d="M35.7079 13.6624V22.3413C35.7079 25.2195 37.1942 26.9935 40.4537 26.9935H43.0878V31.9329H39.5855C34.0745 31.9329 30.2926 29.0547 30.2926 23.7325V13.6624H25.5469V8.72303H30.2926V0H35.7079V8.72303H43.0878V13.6624H35.7079Z"
						fill="#00C495"
					/>
					<path
						id="Vector_2"
						d="M70.8713 22.2899H51.9913C52.9478 25.4993 56.2588 27.4648 61.2842 27.4648C63.5357 27.4648 66.5082 27.0305 68.8553 26.2207V31.3515C66.5082 32.0214 64.3009 32.4042 60.8059 32.4042C52.2268 32.4042 46.2891 27.4648 46.2891 20.2729C46.2891 13.1767 51.4616 8.2373 58.7016 8.2373C65.743 8.2373 70.8713 13.3681 70.8713 20.6998V22.2825V22.2899ZM51.8 18.889H65.4045C64.8748 15.2967 62.2407 13.0368 58.5986 13.0368C54.905 13.0368 52.3224 15.2893 51.7927 18.889H51.8Z"
						fill="#00C495"
					/>
					<path
						id="Vector_3"
						d="M97.0208 18.3589V31.9256H91.5613V18.9331C91.5613 17.6891 91.5613 16.4377 91.1787 15.385C90.5091 13.6625 88.7801 12.7497 86.6758 12.7497C83.0337 12.7497 80.2524 15.7678 80.2524 19.6987V31.9256H74.793V8.72311H80.2524V15.7678C81.5474 11.4983 84.9025 8.24463 89.4055 8.24463C92.9078 8.24463 95.3505 10.2616 96.3512 13.1325C97.0208 15.0464 97.0208 17.1075 97.0208 18.3589Z"
						fill="#00C495"
					/>
					<path
						id="Vector_4"
						d="M126.449 0V31.9256H120.99V24.5423C119.651 29.2387 116.008 32.404 111.454 32.404C105.656 32.404 101.293 27.2291 101.293 20.3243C101.293 13.3753 105.656 8.24455 111.454 8.24455C116.008 8.24455 119.651 11.4099 120.99 16.1063V0H126.449ZM120.99 20.3243C120.99 16.1505 118.017 12.9925 114.044 12.9925C110.063 12.9925 107.098 16.1579 107.098 20.3243C107.098 24.4907 110.071 27.6561 114.044 27.6561C118.017 27.6561 120.99 24.4907 120.99 20.3243Z"
						fill="#00C495"
					/>
					<path
						id="Vector_5"
						d="M146 8.24467V14.3324H144.131C140.437 14.3324 137.612 16.4377 137.612 20.3244V31.9257H132.152V8.72315H137.612V16.342C138.715 11.8369 141.732 8.2373 146 8.2373V8.24467Z"
						fill="#00C495"
					/>
					<path
						id="Vector_6"
						d="M10.9704 14.7959L0 8.17822V14.4058L10.9704 20.6996L21.9482 14.4058V8.17822L10.9704 14.7959Z"
						fill="#00C495"
					/>
					<path
						id="Vector_7"
						d="M10.9704 25.8671L0 32.4848V26.2572L10.9704 19.9561L21.9482 26.2572V32.4848L10.9704 25.8671Z"
						fill="#00C495"
					/>
				</g>
			</svg>
		</div>
	)
}

export default LogoGreen
