import React, { useState } from 'react'

import { ReactComponent as ArrowDown } from '../../resources/img/firstSectionArrowDown.svg'
import '../../styles/howItWorks/_titleCard.scss'
import { CSS } from '../../utils/domUtils'
import Header from '../common/Header'

const TitleCard: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<div>
			<div className={`${CSS.TITLE_CARD}`}>
				<div className={CSS.CONTAINER}>
					<Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
					<h1 className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>How it works</h1>
					<div className={CSS.FLEX_CENTER}>
						<div className={CSS.TITLE_CARD_SUBTITLE}>
							How xtendr protects data privacy at every stage of the collaboration process.
						</div>
					</div>
					<div className={CSS.TITLE_ARROW}>
						<ArrowDown />
					</div>
				</div>
			</div>
		</div>
	)
}

export default TitleCard
