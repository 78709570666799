export enum SnackBarSeverity {
	ERROR = 'error',
	SUCCESS = 'success',
}

export type EmailSeverity = SnackBarSeverity.SUCCESS | SnackBarSeverity.ERROR

export const applicationHeader = {
	'Content-Type': 'application/json',
}
