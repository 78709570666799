import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { CSS } from '../../../utils/domUtils'
import { PATHS } from '../../../utils/paths'
import BackToResources from '../../common/BackToResources'
import YouMightBeInterested from '../../common/YouMightBeInterested'
import { modifiedArticleItems } from '../../data/articles'
import TitleCard from './TitleCard'

const Blog5Route: React.FC = () => (
	<div className={CSS.BLOG}>
		<Helmet>
			<title>How Data Sharing is Changing Industries</title>
		</Helmet>
		<TitleCard />
		<div className={CSS.LIGHT_BACKGROUND}>
			<div className={CSS.CONTAINER}>
				<div className={CSS.BLOG_CONTENT}>
					<section>
						<div className={CSS.LEAD}>
							The value of data-driven decision making is widely acknowledged, but often the range of data
							gathered by a single organization or company is not comprehensive enough to provide optimum
							results. Many industries are therefore adopting practices which incorporate the use of
							collaborative data sharing.
						</div>
						<h2>Manufacturing: Enhancing Efficiency and Predictive Maintenance</h2>
						<p>
							Sharing data between manufacturing companies has become an essential tool for{' '}
							<b>
								optimizing production processes, lowering maintenance costs, extending equipment
								lifespan and reducing unplanned downtime.
							</b>{' '}
							One particularly notable application can be found in predictive maintenance, where machine
							performance data is shared between different organizations, allowing manufacturers to create
							models that use real-world information to predict likely equipment failures before they
							happen.
						</p>
						<p>
							An example of this approach in use can be found in{' '}
							<a
								href={
									'https://www.ge.com/digital/documentation/edge-software/c_what_is_predix_platform.html'
								}
								target="_blank"
								rel="noreferrer"
							>
								GE’s Predix platform
							</a>
							, which allows multiple manufacturers to share and analyze machine data. By bringing
							together data gathered from thousands of machines across the world, manufacturers using the
							platform can learn from the performance of similar machines to predict when a specific
							component of their own is likely to fail.
						</p>
					</section>
					<section>
						<h2>Healthcare: Improving Patient Outcomes and Operational Efficiency</h2>
						<p>
							Collaborative data sharing is increasingly used by healthcare organizations to achieve{' '}
							<b>significant improvements in operational efficiency and patient outcomes.</b>
						</p>
						<p>
							One highly effective application can be found in the development of personalized medicine,
							in which prevention, diagnosis and treatment are based on the analysis of each patient’s
							genetic profile. In order to locate significant patterns in an individual’s information,
							however, analysis must take place on genetic information from a wide range of other sources.
							Initiatives such as the{' '}
							<a href={'https://www.ukbiobank.ac.uk/'} target="_blank" rel="noreferrer">
								UK Biobank
							</a>{' '}
							help to achieve this collaborative sharing, allowing access to data from half a million
							participants.
						</p>
						<p>
							This kind of large-scale data sharing has so far resulted in significant discoveries in
							heart disease, cancer and more - as well as laying the ground for more targeted and
							personalized diagnosis and treatment plans.
						</p>
					</section>
					<section>
						<h2>Pharmaceuticals: Accelerating Drug Discovery and Development</h2>
						<p>
							In recent years, the demand for faster drug development and discovery has begun to shift
							pharmaceutical companies away from closely guarding their data, and towards more
							collaborative sharing.
						</p>
						<p>
							During the pandemic, the <b>development of COVID-19 vaccinations</b> was accelerated by
							pharmaceutical companies sharing clinical trial data, genetic sequences of the virus, and
							patient response information with governments and research institutions. The{' '}
							<a
								href={
									'https://webarchive.nationalarchives.gov.uk/ukgwa/20230505083137/https://www.cogconsortium.uk/'
								}
								target="_blank"
								rel="noreferrer"
							>
								COVID-19 Genomics UK (COG-UK) Consortium
							</a>
							, shared virus genome data amongst global researchers during this time, in order to track
							mutations and inform vaccine updates.
						</p>
						<p>
							Amongst other applications, data sharing is also used to identify new uses for existing
							drugs, by identifying patterns in patient outcomes drawn from multiple sources,
							pharmaceutical organizations can track additional effects, and identify strong candidates
							for repurposing.
						</p>
					</section>
					<section>
						<h2>Agriculture: Enhancing Crop Yields and Sustainable Practices</h2>
						<p>
							Collaborative data sharing is revolutionizing{' '}
							<b>how farmers manage their crops and optimize yields</b>, as combining data from sources
							including satellite imagery, coil sensors and weather forecasts allows farmers to make more
							informed decisions based on a comprehensive view of factors affecting their land. The{' '}
							<a href={'https://agdatacoalition.org/'} target="_blank" rel="noreferrer">
								Agricultural Data Coalition
							</a>{' '}
							(ADC) is one notable initiative with the goal of bringing together farmers, agricultural
							businesses, and technology providers to share information that can help to improve farming
							processes and outcomes.
						</p>
						<p>
							Data sharing is also an essential tool in promoting sustainable agricultural practices, as
							environmental impact can be measured in a much more comprehensive way when analyzing larger
							amounts of data relating soil health, water usage, and crop performance worldwide.
						</p>
					</section>
					<section>
						<h2>Telecommunications: Optimizing Network Performance</h2>
						<p>
							In order to maintain and improve the performance of telecommunications networks, data
							sharing between companies is essential. As the number of connected devices and the demand
							for fast connection continues to grow worldwide, telecom companies must collaborate in order
							to <b>improve service quality and minimize network congestion.</b>
						</p>
						<p>
							One example of this can be found in the sharing of network performance data between telecom
							operators. By sharing data on signal strength, network traffic, and patterns in customer
							usage patterns, companies can optimize their networks to handle demand more effectively.
						</p>
					</section>
					<section>
						<h2>What’s next?</h2>
						<p>
							It’s clear that collaborative data sharing is becoming increasingly important in many key
							industries, as drawing on a larger and more diverse range of data sources allows
							organizations to gain insights that would not otherwise be possible.
						</p>
						<p>
							As this need becomes more widespread, it is likely that organizations will struggle with the
							need to achieve compliance with constantly-updated data privacy laws and regulations, as
							well as the importance of protecting intellectual property and business-critical
							information. This risk is already being mitigated in some cases, however - thanks to the use
							of Privacy Enhancing Technologies such as{' '}
							<Link to={`${PATHS.BLOG}/blog1`}>Homomorphic Encryption</Link>, which allows data to remain
							encrypted throughout sharing and analysis.
						</p>
						<p>
							As access to these technologies becomes easier, more and more organizations may therefore
							turn to data sharing solutions which preserve privacy - creating a sustainable future of
							collaborative data sharing, driven by the value of the insights it provides.
						</p>
					</section>
					<section>
						<p className={CSS.EXTRA_SPACE_BEFORE}>
							<b>
								To find out more about xtendr’s secure data collaboration solutions, take a look at our{' '}
								<Link to={PATHS.COLLABORATION_PLATFORM}>Platform</Link>, or{' '}
								<Link to={PATHS.CONTACT}>get in touch</Link> any time to speak to a member of our team.
							</b>
						</p>
					</section>
				</div>
				<YouMightBeInterested items={modifiedArticleItems} />
				<BackToResources />
			</div>
		</div>
	</div>
)

export default Blog5Route
