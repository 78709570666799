import React from 'react'
import { Helmet } from 'react-helmet'

import { CSS } from '../../utils/domUtils'
import Rest from './Rest'
import TitleCard from './TitleCard'

const DemoRoute: React.FC = () => (
	<div className={CSS.DEMO_ROUTE}>
		<Helmet>
			<title>Demo - Try our platform, completely free</title>
		</Helmet>
		<TitleCard />
		<Rest />
	</div>
)

export default DemoRoute
