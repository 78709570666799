import React, { useState } from 'react'

import { ReactComponent as ArrowDown } from '../../resources/img/firstSectionArrowDown.svg'
import '../../styles/aboutUs/_titleCard.scss'
import { CSS } from '../../utils/domUtils'
import Header from '../common/Header'

const TitleCard: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<>
			<div className={`${CSS.TITLE_CARD} ${CSS.BACKGROUND}`}>
				<div className={CSS.CONTAINER}>
					<Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
					<div className={`${CSS.FLEX_CENTER} ${CSS.FLEX_COLUMN}`}>
						<h1 className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>About us</h1>
					</div>
					<div className={CSS.TITLE_ARROW}>
						<ArrowDown />
					</div>
				</div>
			</div>
		</>
	)
}

export default TitleCard
