import React from 'react'
import { Helmet } from 'react-helmet'

import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import GreenLink from '../common/GreenLink'
import ColorBlock from './ColorBlock'
import Rest from './Rest'
import Stepper from './Stepper'
import TitleCard from './TitleCard'

const HowItWorksRoute: React.FC = () => (
	<div className={CSS.HOW_IT_WORKS}>
		<Helmet>
			<title>How it works</title>
		</Helmet>
		<TitleCard />
		<ColorBlock />
		<Stepper />
		<GreenLink
			route={PATHS.COLLABORATION_PLATFORM}
			title={'Find out more about the xtendr collaboration platform'}
			margin={window.innerWidth > 1800 ? '25vw' : '133px'}
		/>
		<Rest />
	</div>
)

export default HowItWorksRoute
