import React, { useCallback, useState } from 'react'

import { ReactComponent as CloseIcon } from '../../resources/img/keyPrivacyEnhancingTechnologiesItemClose.svg'
import { CSS } from '../../utils/domUtils'

type FaqItemProps = {
	question: string
	answer: string
}

const FaqItem = ({ question, answer }: FaqItemProps) => {
	const [isOpened, setIsOpened] = useState<boolean>(false)

	const onHandleClick = useCallback(() => {
		setIsOpened((prevState) => !prevState)
	}, [])

	return (
		<div className={`${CSS.FAQ_ITEM} ${isOpened && 'active'}`}>
			<div className={CSS.FAQ_QUESTION}>
				<div>
					<span>{question}</span>
					<div className={CSS.FAQ_ICON} onClick={onHandleClick}>
						<CloseIcon />
					</div>
				</div>
			</div>
			<div className={CSS.FAQ_ANSWER}>{answer}</div>
		</div>
	)
}

export default FaqItem
