import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../../resources/img/footerLogo.svg'
import '../../styles/_footer.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import Links from './Links'
import NewsLetter from './NewsLetter'

const scrollToTop = () => {
	window.scrollTo(0, 0)
}

const Footer = () => {
	return (
		<div className={`${CSS.FOOTER} ${CSS.BACKGROUND}`}>
			<div className={`${CSS.CONTAINER} ${CSS.FLEX_COLUMN}`}>
				<div className={`${CSS.FLEX_ROW} ${CSS.FOOTER_INFO}`}>
					<NewsLetter />
					<Links />
				</div>
				<img src={logo} className={CSS.FOOTER_LOGO} alt={'logo'} />
				<div className={`${CSS.FLEX_ROW} ${CSS.FOOTER_LEGAL_WRAPPER}`}>
					<div className={`${CSS.FOOTER_LEGAL} ${CSS.FOOTER_PAGES}`}>
						All rights reserved to © xtendr 2024
					</div>
					<div className={CSS.FOOTER_PAGES}>
						<Link to={PATHS.QUALITY_ASSURANCE} onClick={scrollToTop}>
							Quality Assurance
						</Link>
					</div>
					<div className={CSS.FOOTER_PAGES}>
						<Link to={PATHS.PRIVACY_POLICY} onClick={scrollToTop}>
							Privacy Policy
						</Link>
					</div>
					<div className={CSS.FOOTER_PAGES}>
						<Link to={PATHS.IMPRESSUM} onClick={scrollToTop}>
							Impressum
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
