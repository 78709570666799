import { useCallback } from 'react'
import { Link } from 'react-router-dom'

import '../../styles/_youMightBeInterested.scss'
import { CSS } from '../../utils/domUtils'

export type RenderItemsProps = {
	id: string
	picture: string
	label: labels
	date: string
	content?: string
	title: string
	description: string
	link: string
}

export enum labels {
	PAPER = 'PAPER',
	WHITEPAPER = 'WHITEPAPER',
	ARTICLE = 'ARTICLE',
	UPDATE = 'UPDATE',
}

export const labelColors = [
	{
		title: labels.PAPER,
		color: '#ECFFFA',
		background: '#0F1936',
	},
	{
		title: labels.WHITEPAPER,
		color: '#0F1936',
		background: '#00C495',
	},
	{
		title: labels.ARTICLE,
		color: '#ECFFFA',
		background: '#6E7CFF',
	},
	{
		title: labels.UPDATE,
		color: '#0F1936',
		background: '#CDF98C',
	},
]

type YouMightBeInterestedProps = {
	items: Array<RenderItemsProps>
}

const YouMightBeInterested = ({ items }: YouMightBeInterestedProps) => {
	const renderItems = useCallback(({ picture, label, date, title, description, link, id }: RenderItemsProps) => {
		const colors = labelColors.find((item) => item.title === label)

		return (
			<div key={id} className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM}>
				<div className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_PICTURE}>
					<img src={picture} alt={title} />
				</div>
				<div className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_HEADER}>
					<div
						className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_LABEL}
						style={{ color: colors?.color, background: colors?.background }}
					>
						{label}
					</div>
					<div className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_DATE}>{date}</div>
				</div>
				<div className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_TITLE}>{title}</div>
				<div className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_DESCRIPTION}>{description}</div>
				<Link to={link} className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_LINK}>
					READ MORE →
				</Link>
			</div>
		)
	}, [])
	return (
		<div className={CSS.YOU_MIGHT_BE_INTERESTED}>
			<h2 className={CSS.YOU_MIGHT_BE_INTERESTED_TITLE}>You might be interested</h2>
			<div className={CSS.YOU_MIGHT_BE_INTERESTED_ITEMS}>
				{items.map((item) =>
					renderItems({
						picture: item.picture,
						label: item.label,
						date: item.date,
						title: item.title,
						description: item.description,
						link: item.link,
						id: item.id,
					})
				)}
			</div>
		</div>
	)
}

export default YouMightBeInterested
