import React from 'react'

import '../../styles/privacyEnhancingTechnologies/_whatBlock.scss'
import { CSS } from '../../utils/domUtils'

const WhatBlock: React.FC = () => {
	return (
		<div className={CSS.LIGHT_BACKGROUND}>
			<div className={CSS.CONTAINER}>
				<div className={CSS.PRIVACY_ENHANCING_TECHNOLOGIES_WHAT_BLOCK}>
					<h3 className={CSS.PRIVACY_ENHANCING_TECHNOLOGIES_QUESTION}>What are PETs?</h3>
					<div className={CSS.PRIVACY_ENHANCING_TECHNOLOGIES_ANSWER}>
						PETs are technologies that{' '}
						<span>allow data to be collected, shared and analyzed in a secure way</span>, without
						compromising the usefulness and accuracy of that data any more than is necessary to maintain its
						privacy.
					</div>
				</div>
			</div>
		</div>
	)
}

export default WhatBlock
