import React, { useState } from 'react'

import customCollaborationSolutionsImg from '../../resources/img/customCollaborationSolutions.png'
import { ReactComponent as ArrowDown } from '../../resources/img/firstSectionArrowDown.svg'
import '../../styles/customCollaborationSolutions/_titleCard.scss'
import { CSS } from '../../utils/domUtils'
import Header from '../common/Header'

const TitleCard: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<div className={CSS.CUSTOM_COLLABORATION_SOLUTIONS}>
			<div className={`${CSS.TITLE_CARD} ${CSS.BACKGROUND}`}>
				<div className={CSS.CONTAINER}>
					<Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
					<div className={CSS.COLLAB_FLEX_ROW}>
						<div className={CSS.TITLE_WRAPPER}>
							<h1 className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>
								Fully customizable collaboration solutions
							</h1>
							<h2 className={CSS.CARD_SUBTITLE}>
								Custom data collaboration solutions that put data privacy first, built around specific
								project needs.
							</h2>
						</div>
						<div className={CSS.IMG_WRAPPER}>
							<img src={customCollaborationSolutionsImg} alt={'Custom collaboration solutions'} />
						</div>
					</div>
					<div className={CSS.TITLE_ARROW}>
						<ArrowDown />
					</div>
				</div>
			</div>
		</div>
	)
}

export default TitleCard
