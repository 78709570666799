import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Link } from 'react-router-dom'

import { CSS } from '../../utils/domUtils'

type GreenLinkProps = {
	route: string
	title: string
	margin?: string
}

const GreenLink = ({ route, title, margin }: GreenLinkProps) => {
	return (
		<div className={`${CSS.PROTECT_DATA} ${CSS.WRAPPER}`}>
			<div className={CSS.BACKGROUND} style={{ marginLeft: margin || '40%' }}>
				<div className={CSS.CONTAINER}>
					<Link to={route}>
						{title}
						<ArrowForwardIcon
							style={{ width: '50px', height: '48px', marginBottom: '-10px' }}
							sx={{ stroke: '#00C495', strokeWidth: 1 }}
						/>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default GreenLink
