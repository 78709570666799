import React from 'react'
import { Helmet } from 'react-helmet'

import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import ButtonDark from '../common/ButtonDark'
import FaqItem from './FaqItem'
import TitleCard from './TitleCard'

const faqs = [
	{
		question: 'Do I need to share any raw information during the collaboration process?',
		answer: 'No, all data is encrypted before any other processes take place. This encryption happens locally - so without your data being moved to any other location - which means that raw information is never shared at any time.',
	},
	{
		question: 'Is xtendr a file sharing platform?',
		answer: 'No, xtendr is not designed for file sharing. Instead, we facilitate the sharing of large amounts of data in a secure way. This means that the content of that data is protected, so is not visible to or editable by any of the participants.',
	},
	{
		question: 'Does xtendr clean data?',
		answer: 'No, xtendr does not currently offer a data cleaning service. However, we are happy to work with clients and their partners to ensure that data cleaning and collaboration processes work together for the best possible results.',
	},
	{
		question: 'Does xtendr analyze data?',
		answer: 'No, we do not perform analysis on data ourselves. Instead, we provide a platform and the framework required for collaborating parties to run analysis on the data that they are sharing.',
	},
	{
		question: 'Does xtendr provide a ‘Matchmaking’ service to help clients find collaborative partners?',
		answer: 'No, we do not provide a dedicated matchmaking service. However, if you are looking for a partner to help you achieve your goals for a collaboration, we are very happy to help with the process.',
	},
	{
		question: 'Does xtendr use fully homomorphic encryption?',
		answer: 'Yes, you can find out more about the techniques we use on our Privacy Enhancing Technologies page.',
	},
]

const FaqRoute: React.FC = () => (
	<div className={CSS.FAQ}>
		<Helmet>
			<title>Frequently asked questions</title>
		</Helmet>
		<TitleCard />
		<div className={CSS.DARK_CARD}>
			<div className={CSS.CONTAINER}>
				{faqs.map((faq, faqIndex) => (
					<div key={faqIndex} className={CSS.FAQ_CATEGORY}>
						<FaqItem question={faq.question} answer={faq.answer} />
					</div>
				))}
			</div>
		</div>
		<div className={CSS.LIGHT_BACKGROUND}>
			<div className={CSS.CONTAINER}>
				<div className={CSS.FAQ_CONTACT_US}>
					<div className={CSS.FAQ_CONTACT_US_TITLE}>
						If you want to know more, feel free to get in touch any time and we’ll be happy to answer your
						questions!
					</div>
					<ButtonDark buttonText={'contact us'} to={PATHS.CONTACT} />
				</div>
			</div>
		</div>
	</div>
)

export default FaqRoute
