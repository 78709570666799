import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as IdealBlockImg } from '../../resources/img/idealBlock.svg'
import '../../styles/customCollaborationSolutions/_idealBlock.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import ButtonDark from '../common/ButtonDark'

const IdealBlock: React.FC = () => (
	<div className={CSS.IDEAL_BLOCK}>
		<div className={CSS.CONTAINER}>
			<h2 className={CSS.IDEAL_TEXT}>
				Ideal for projects with <span>complex security or analytical needs</span>, as well as long-term
				collaboration partnerships
			</h2>
			<div className={CSS.IDEAL_CONTAINER}>
				<div className={CSS.IDEAL_CONTENT}>
					<div className={CSS.IDEAL_SUBTEXT}>
						which require a more integrated solution than the{' '}
						<Link to={PATHS.COLLABORATION_PLATFORM}>xtendr Data Collaboration Platform</Link>, our custom
						solutions can be applied across industries and use cases.
					</div>
					<ButtonDark
						buttonText={'LEARN MORE'}
						extraClasses={`${CSS.IDEAL_BUTTON}`}
						to={PATHS.COLLABORATION_PLATFORM}
						hasArrow={true}
					/>
				</div>
				<div className={CSS.IDEAL_IMG}>
					<IdealBlockImg />
				</div>
			</div>
		</div>
	</div>
)

export default IdealBlock
