import React, { Fragment, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import '../../styles/_youMightBeInterested.scss'
import '../../styles/resources/_content.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import GreenLink from '../common/GreenLink'
import { RenderItemsProps, labelColors } from '../common/YouMightBeInterested'
import CustomSearchField from '../common/formComponents/CustomSearchField'
import Filters from '../common/formComponents/Filters'
import { modifiedArticleItems } from '../data/articles'

const Content: React.FC = () => {
	const [numberOfItemsShown, setNumberOfItemsShown] = useState<number>(6)
	const [selectedFilters, setSelectedFilters] = useState<Array<string>>([])
	const [searchResults, setSearchResults] = useState<RenderItemsProps[]>(modifiedArticleItems)
	const [searchValue, setSearchValue] = useState<string>('')

	const showMoreClick = () => {
		if (numberOfItemsShown + 3 <= searchResults.length) {
			setNumberOfItemsShown(numberOfItemsShown + 3)
		} else {
			setNumberOfItemsShown(searchResults.length)
		}
	}

	const renderItems = useCallback(({ picture, label, date, title, description, link }: RenderItemsProps) => {
		const colors = labelColors.find((item) => item.title === label)

		return (
			<div className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM}>
				<div className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_PICTURE}>
					<img src={picture} alt={title} />
				</div>
				<div className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_HEADER}>
					<div
						className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_LABEL}
						style={{ color: colors?.color, background: colors?.background }}
					>
						{label}
					</div>
					<div className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_DATE}>{date}</div>
				</div>
				<div className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_TITLE}>{title}</div>
				<div className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_DESCRIPTION}>{description}</div>
				<Link to={link} className={CSS.YOU_MIGHT_BE_INTERESTED_ITEM_LINK}>
					READ MORE →
				</Link>
			</div>
		)
	}, [])

	const filteredItems = searchResults.filter(
		(item) => selectedFilters.length === 0 || selectedFilters.includes(item.label.toLowerCase())
	)

	const handleClearFilters = () => {
		setSelectedFilters([])
		setSearchValue('')
		setSearchResults(modifiedArticleItems)
	}

	return (
		<div className={CSS.LIGHT_BACKGROUND}>
			<div className={CSS.CONTAINER}>
				<div className={CSS.CONTENT}>
					<div className={CSS.FILTERS_CONTAINER}>
						<CustomSearchField
							onSearchFilter={setSearchResults}
							value={searchValue}
							onValueChange={setSearchValue}
						/>
						<Filters
							selectedFilters={selectedFilters}
							onFilterChange={setSelectedFilters}
							onClearFilters={handleClearFilters}
						/>
					</div>
					<div className={CSS.CONTENT_ITEMS_CONTAINER}>
						{filteredItems.length === 0 ? (
							<div className={CSS.FILTERS_LABEL}>No results found.</div>
						) : (
							filteredItems.slice(0, numberOfItemsShown).map((item, index) => (
								<Fragment key={index}>
									{renderItems({
										picture: item.picture,
										label: item.label,
										date: item.date,
										title: item.title,
										description: item.description,
										link: item.link,
										id: item.id,
									})}
								</Fragment>
							))
						)}
					</div>
					{filteredItems.length > numberOfItemsShown && (
						<button className={CSS.CONTENT_SHOW_MORE} onClick={showMoreClick}>
							Show More
						</button>
					)}
				</div>
			</div>
			<GreenLink route={PATHS.FAQ} title={'Visit our FAQ'} margin={window.innerWidth > 1800 ? '25vw' : '9vw'} />
		</div>
	)
}

export default Content
