import { ReactElement, useCallback, useState } from 'react'

import { ReactComponent as AddIcon } from '../../resources/img/addIconNumberAccordion.svg'
import '../../styles/privacyEnhancingTechnologies/_numberAccordion.scss'
import { CSS } from '../../utils/domUtils'

type NumberAccordionProps = {
	number: number
	title: string
	isPercent?: boolean
	text: ReactElement
	width: number
}

const NumberAccordion = ({ number, title, text, isPercent = false, width }: NumberAccordionProps) => {
	const [isActive, setIsActive] = useState<boolean>(false)

	const onHandleClick = useCallback(() => {
		setIsActive((prevState) => !prevState)
	}, [])

	return (
		<div className={`${CSS.NUMBER_ACCORDION} ${isActive ? CSS.ACTIVE : ''}`} style={{ width: `${width}%` }}>
			<div className={`${CSS.NUMBER_ACCORDION_BUTTON} ${isActive ? CSS.ACTIVE : ''}`} onClick={onHandleClick}>
				<AddIcon />
			</div>
			<div className={CSS.NUMBER_ACCORDION_HEADER}>
				<div className={CSS.NUMBER_ACCORDION_NUMBER}>
					{number}
					{isPercent && <span>%</span>}
				</div>
				<div className={CSS.NUMBER_ACCORDION_TITLE}>{title}</div>
			</div>
			<div className={`${CSS.NUMBER_ACCORDION_TEXT} ${isActive ? CSS.ACTIVE : ''}`}>{text}</div>
		</div>
	)
}

export default NumberAccordion
