import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import '../../styles/_comingSoon.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import Header from '../common/Header'

const ComingSoonRoute = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<div className={`${CSS.COMING_SOON_ROUTE} ${CSS.BACKGROUND}`}>
			<Helmet>
				<title>Coming soon</title>
			</Helmet>
			<div className={CSS.WRAPPER}>
				<Header isLight={true} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
				<div className={CSS.COMING_SOON}>
					<h1 className={CSS.CARD_TITLE}>Coming soon!</h1>
					<div className={CSS.TEXT}>
						<h2 className={CSS.MAIN_TEXT}>We’re working on a new version of our website</h2>
						<div className={CSS.CARD_SUBTITLE}>
							Later this year, you’ll be able to read all about xtendr‘s secure data sharing and
							collaboration solutions.
							<br />
							<br />
							Until then, you can contact us with any questions at{' '}
							<a href="mailto:hello@xtendr.io">hello@xtendr.io</a> - or keep up to date with our news on
							LinkedIn.
							<a
								className={CSS.LINKEDIN_BTN}
								href={PATHS.LINKEDIN_COMPANY}
								target="_blank"
								rel="noreferrer"
							>
								Linkedin →
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ComingSoonRoute
