import React from 'react'

import '../../styles/aboutUs/_ourValues.scss'
import { CSS } from '../../utils/domUtils'

const OurValues: React.FC = () => {
	return (
		<div className={CSS.OUR_VALUES}>
			<div className={CSS.OUR_VALUES_BLOCK}>
				<h2 className={CSS.OUR_VALUES_SUBTITLE}>Our values</h2>
				<h3 className={CSS.OUR_VALUES_TITLE}>
					xtendr is built on an absolute commitment to promoting data privacy - because while{' '}
					<span>data has the power to revolutionize research,</span> business processes and insights, it must
					be protected in order to do so in a legal and ethical way.
				</h3>
				<div className={CSS.OUR_VALUES_DESCRIPTION}>
					<b>Our goal is to help more organizations improve their data privacy standards -</b> at the same
					time as maximizing the value of that data through secure data sharing and collaboration.
				</div>
			</div>
		</div>
	)
}

export default OurValues
