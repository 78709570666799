import { CSS } from '../../utils/domUtils'
import LogoScroller from './LogoScroller'

const Partners: React.FC = () => (
	<>
		<div className={CSS.CONTAINER}>
			<h3 className={CSS.PARTNERS_TITLE}>Partnered with</h3>
		</div>
		<LogoScroller />
	</>
)

export default Partners
