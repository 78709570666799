import privateSetIntersection from '../../../resources/img/PrivateSetIntersection.png'
import '../../../styles/privacyEnhancingTechnologies/_keyPrivacyEnhancingTechnologies.scss'
import { CSS } from '../../../utils/domUtils'

const PrivateSetIntersection = () => {
	return (
		<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT}>
			<div>
				<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_BLOCK}>
					<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_TITLE}>WHAT DOES IT DO?</div>
					<div>
						Private set intersection is a technique used to detect the common elements between multiple
						datasets in a secure way.
					</div>
					<div>
						Typically conducted on homomorphically encrypted data, PSI finds which information is present in
						all of the contributing datasets, and then produces a new dataset which only contains that
						intersecting information. This means that none of the original datasets have to be shared or
						revealed to other parties.
					</div>
					<div>
						This also means that the intersecting data, once identified, can be isolated and subjected to
						further analysis.
					</div>
				</div>
				<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_BLOCK}>
					<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_TITLE}>DOES XTENDR USE IT?</div>
					<div>
						Yes, we use private set intersection for queries that require the identification of
						commonalities between datasets.
					</div>
				</div>
			</div>
			<div>
				<div className={CSS.IMG_WRAPPER}>
					<img src={privateSetIntersection} alt={'Private Set Intersection'} />
				</div>
			</div>
		</div>
	)
}

export default PrivateSetIntersection
