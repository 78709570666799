import React from 'react'

import '../../styles/specificUseCase/_caseStudiesBlock.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import ButtonDark from '../common/ButtonDark'
import UseCasesPanel from '../common/UseCasesPanel'

type CaseStudiesBlockProps = {
	industry: string
}

const CaseStudiesBlock: React.FC<CaseStudiesBlockProps> = ({ industry }) => {
	return (
		<div className={CSS.LIGHT_BACKGROUND}>
			<UseCasesPanel industry={industry} />
			<div className={CSS.CONTAINER}>
				<div className={CSS.SPECIFIC_USE_CASE_GET_IN_TOUCH}>
					<div className={CSS.SPECIFIC_USE_CASE_GET_IN_TOUCH_TITLE}>
						Have another use case in mind, or want to know more?
					</div>
					<ButtonDark buttonText={'get in touch'} to={PATHS.CONTACT} />
				</div>
			</div>
		</div>
	)
}

export default CaseStudiesBlock
