import { useCallback, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'

import howItWorksImg1 from '../../resources/img/howItWorksImg1.png'
import howItWorksImg2 from '../../resources/img/howItWorksImg2.png'
import howItWorksImg3 from '../../resources/img/howItWorksImg3.png'
import '../../styles/howItWorks/_stepper.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'

const Stepper = () => {
	const [y, setY] = useState<number>(window.scrollY)
	const [isStep2WasShown, setIsStep2WasShown] = useState<boolean>(false)
	const [isStep3WasShown, setIsStep3WasShown] = useState<boolean>(false)
	const [isScrollDown, setIsScrollDown] = useState<boolean>(true)

	const refNumber1 = useRef<HTMLInputElement>(null)
	const refNumber2 = useRef<HTMLInputElement>(null)
	const refNumber3 = useRef<HTMLInputElement>(null)

	const { ref: refForStep2 } = useInView({
		threshold: 0.5,
		onChange: (inView) => {
			if (isScrollDown && inView) {
				setIsStep2WasShown(true)
				refNumber1?.current?.classList.add('passed')
				return refNumber2?.current?.classList.add('active')
			} else if (isStep2WasShown && !inView) {
				setIsStep2WasShown(false)
				refNumber1?.current?.classList.remove('passed')
				return refNumber2?.current?.classList.remove('active')
			}
		},
	})

	const { ref: refForStep3 } = useInView({
		threshold: 0.5,
		onChange: (inView) => {
			if (isScrollDown && inView) {
				setIsStep3WasShown(true)
				refNumber2?.current?.classList.add('passed')
				return refNumber3?.current?.classList.add('active')
			} else if (isStep3WasShown && !inView) {
				setIsStep3WasShown(false)
				refNumber2?.current?.classList.remove('passed')
				return refNumber3?.current?.classList.remove('active')
			}
		},
	})

	const handleNavigation = useCallback(
		(e: Event) => {
			const window = e.currentTarget as Window
			if (y > window.scrollY) {
				setIsScrollDown(false)
			} else if (y < window.scrollY) {
				setIsScrollDown(true)
			}
			setY(window.scrollY)
		},
		[y]
	)

	useEffect(() => {
		setY(window.scrollY)
		window.addEventListener('scroll', handleNavigation)

		return () => {
			window.removeEventListener('scroll', handleNavigation)
		}
	}, [handleNavigation])

	return (
		<div className={CSS.LIGHT_BACKGROUND}>
			<div className={CSS.CONTAINER}>
				<div className={CSS.STEPPER_MOBILE_IMG_WRAPPER}>
					<img src={howItWorksImg1} alt={''} />
				</div>
				<div className={CSS.STEPPER}>
					<div className={CSS.STEPPER_COMPONENT}>
						<div className={`${CSS.STEPPER_NUMBER} ${CSS.ACTIVE}`} ref={refNumber1}>
							1
						</div>
						<div className={CSS.STEPPER_LINE}></div>
						<div className={CSS.STEPPER_NUMBER} ref={refNumber2}>
							2
						</div>
						<div className={CSS.STEPPER_LINE}></div>
						<div className={CSS.STEPPER_NUMBER} ref={refNumber3}>
							3
						</div>
					</div>
					<div className={CSS.STEPPER_INNER}>
						<div className={CSS.STEPPER_ROW}>
							<div className={CSS.STEPPER_IMG_WRAPPER}>
								<img src={howItWorksImg1} alt={'Data is protected while being stored locally'} />
							</div>
							<div className={CSS.STEPPER_CONTENT}>
								<h3 className={CSS.STEPPER_TITLE}>Data is protected while being stored locally</h3>
								<div className={CSS.STEPPER_TEXT}>
									<h4 className={CSS.STEPPER_LIST_TITLE}>
										xtendr uses two key techniques for protecting data:
									</h4>
									<ol className={CSS.STEPPER_LIST}>
										<b>
											<li className={CSS.STEPPER_LIST_ITEM}>
												<h5 className={CSS.STEPPER_LIST_ITEM_TITLE}>
													Irreversible stripping of direct identifiers
												</h5>
												<div className={CSS.STEPPER_LIST_ITEM_TEXT}>
													All information that is considered a ‘direct identifier’ - such as
													names, ID numbers, contact information, and biometric data - is
													removed permanently and irrevocably from the dataset.
												</div>
											</li>
										</b>
										<b>
											<li className={CSS.STEPPER_LIST_ITEM}>
												<h4 className={CSS.STEPPER_LIST_ITEM_TITLE}>
													Fully homomorphic encryption (FHE)
												</h4>
												<div className={CSS.STEPPER_LIST_ITEM_TEXT}>
													All other data is encrypted using FHE, which allows analysis to take
													place on fully encrypted data - so that accurate and valuable
													results can be generated without ever decrypting or otherwise
													revealing any raw information.
												</div>
												<div className={CSS.STEPPER_LIST_ITEM_TEXT}>
													Both processes take place locally, so before any data is moved or
													shared. Whether data is stripped or encrypted is based on how
													participants label different parts of their datasets during setup.
												</div>
												<div className={CSS.STEPPER_LIST_ITEM_TEXT}>
													Collaborators will also have the opportunity to customize additional
													security elements, including implementing optional differential
													privacy and setting access controls.
												</div>
											</li>
										</b>
									</ol>
									<Link to={PATHS.PRIVACY_ENHANCING_TECHNOLOGIES} className={CSS.STEPPER_BUTTON}>
										Learn more <span>about the PETs we use</span> →
									</Link>
								</div>
							</div>
						</div>
						<div className={CSS.STEPPER_ROW} ref={refForStep2}>
							<div className={CSS.STEPPER_IMG_WRAPPER}>
								<img src={howItWorksImg2} alt={'Data is shared and analyzed'} />
							</div>
							<div className={CSS.STEPPER_CONTENT}>
								<h3 className={CSS.STEPPER_TITLE}>Data is shared and analyzed</h3>
								<div className={CSS.STEPPER_TEXT}>
									<h4 className={CSS.STEPPER_LIST_TITLE}>
										Fully encrypted or irreversibly stripped data is shared across secure channels.
									</h4>
									<div className={CSS.STEPPER_LIST_ITEM}>
										<div className={CSS.STEPPER_LIST_ITEM_TEXT}>
											Once data has been shared, it can be securely computed on - with FHE meaning
											that no participant or third party (including xtendr) will ever be able to
											view or otherwise access the information that is being processed.
										</div>
									</div>
									<div className={CSS.STEPPER_LIST_ITEM}>
										<div className={CSS.STEPPER_LIST_ITEM_TEXT}>
											Supported computations include{' '}
											<b>
												identifying overlapping or shared records, calculating sums, averages,
												and producing aggregate statistics,
											</b>{' '}
											alongside other methods of identifying patterns in data.
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className={CSS.STEPPER_ROW} ref={refForStep3}>
							<div className={CSS.STEPPER_IMG_WRAPPER}>
								<img src={howItWorksImg3} alt={'Results are generated and distributed'} />
							</div>
							<div className={CSS.STEPPER_CONTENT}>
								<h3 className={CSS.STEPPER_TITLE}>Results are generated and distributed</h3>
								<div className={CSS.STEPPER_TEXT}>
									<div className={CSS.STEPPER_LIST_ITEM}>
										<div className={CSS.STEPPER_LIST_ITEM_TEXT}>
											<b>Results are stored and sent in encrypted form,</b> which can only be
											decrypted by the participant who initiated the analysis.
										</div>
									</div>
									<div className={CSS.STEPPER_LIST_ITEM}>
										<div className={CSS.STEPPER_LIST_ITEM_TEXT}>
											All projects also have a predetermined end date, after which all data
											associated with them is permanently deleted.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Stepper
