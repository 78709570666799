import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { ReactComponent as ArrowDown } from '../../resources/img/arrowDownGreen.svg'
import '../../styles/_additionalPage.scss'
import { CSS } from '../../utils/domUtils'
import Header from '../common/Header'

const ImpressumRoute = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<div className={`${CSS.ADDITIONAL_PAGE} ${CSS.BACKGROUND}`}>
			<Helmet>
				<title>Impressum</title>
			</Helmet>
			<div className={CSS.WRAPPER}>
				<Header isLight={true} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
				<div className={CSS.ADDITIONAL_BLOCK}>
					<h1 className={CSS.ADDITIONAL_TITLE}>IMPRESSUM</h1>
					<div className={CSS.TEXT}>
						<h2 className={CSS.MAIN_TEXT}>Website Owner and Operator</h2>
						<div className={CSS.CARD_SUBTITLE}>
							<p>Xtendr Zrt.</p>
							<p>Address: Hungary 1037 Montevideo utca 16a</p>
							<p>Email: hello@xtendr.io</p>
							<p>Company Registration Number: 01 10 140162</p>
							<p>VAT Number: 26642811-2-418</p>
						</div>
						<h2 className={CSS.MAIN_TEXT}>Content Responsibility</h2>
						<div className={CSS.CARD_SUBTITLE}>
							Xtendr Zrt. is responsible for all content, design, and development of this website. The
							information provided on this site is for general informational purposes only. Xtendr
							Zrt.takes reasonable efforts to ensure that the information presented on this website is
							accurate and up-to-date.
						</div>
						<h2 className={CSS.MAIN_TEXT}>Hosting Provider</h2>
						<div className={CSS.CARD_SUBTITLE}>
							<p>
								This website is hosted on Google Cloud Platform (GCP), a service provided by Google LLC.
							</p>
							<p>Address of Hosting Provider:</p>
							<p>Google Cloud EMEA Limited</p>
							<p>Velasco</p>
							<p>Clanwilliam Place</p>
							<p>Dublin 2,Ireland</p>
						</div>
						<h2 className={CSS.MAIN_TEXT}>Disclaimer</h2>
						<div className={CSS.CARD_SUBTITLE}>
							While we strive to provide accurate and up-to-date information, Xtendr Zrt. does not accept
							any liability for the completeness or accuracy of the information on this website. Xtendr
							Zrt. reserves the right to make changes to the content at any time without prior notice.
						</div>
						<h2 className={CSS.MAIN_TEXT}>Copyright</h2>
						<div className={CSS.CARD_SUBTITLE}>
							All content on this website, including text, images, graphics, and logos, is the property of
							Xtendr Zrt., unless otherwise indicated. Unauthorized use or distribution of this material
							without permission is prohibited.
						</div>
						<h2 className={CSS.MAIN_TEXT}>Contact</h2>
						<div className={CSS.CARD_SUBTITLE}>
							For inquiries, please contact us via email at{' '}
							<a href="mailto:hello@xtendr.io">hello@xtendr.io</a>
						</div>
					</div>
				</div>
				<div className={CSS.TITLE_ARROW}>
					<ArrowDown />
				</div>
			</div>
		</div>
	)
}

export default ImpressumRoute
