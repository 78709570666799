import React, { useState } from 'react'

import { ReactComponent as ArrowDown } from '../../resources/img/firstSectionArrowDown.svg'
import '../../styles/demo/_titleCard.scss'
import { CSS } from '../../utils/domUtils'
import DemoButton from '../common/DemoButton'
import Header from '../common/Header'

const TitleCard: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<div>
			<div className={`${CSS.TITLE_CARD} ${CSS.BACKGROUND}`}>
				<div className={CSS.CONTAINER}>
					<Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
					<h1 className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>Try our platform, completely free</h1>
					<div className={CSS.FLEX_CENTER}>
						<DemoButton buttonText={'TRY THE DEMO'} />
					</div>
					<div className={CSS.TITLE_ARROW}>
						<ArrowDown />
					</div>
				</div>
			</div>
		</div>
	)
}

export default TitleCard
