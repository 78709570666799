import { Helmet } from 'react-helmet'

import { CSS } from '../../../utils/domUtils'
import BackToResources from '../../common/BackToResources'
import YouMightBeInterested from '../../common/YouMightBeInterested'
import { modifiedArticleItems } from '../../data/articles'
import TitleCard from './TitleCard'

const Blog2Route: React.FC = () => (
	<div className={CSS.BLOG}>
		<Helmet>
			<title> Paper summary: A Non-interactive Threshold Hashing Protocol with Shamir’s Secret Sharing</title>
		</Helmet>
		<TitleCard />
		<div className={CSS.LIGHT_BACKGROUND}>
			<div className={CSS.CONTAINER}>
				<div className={CSS.BLOG_CONTENT}>
					<section>
						<div className={CSS.LEAD}>
							In our recent paper ‘A Non-interactive Threshold Hashing Protocol with Shamir’s Secret
							Sharing’, xtendr’s cryptographers Robin Frot and Daniel Zentai propose a threshold protocol
							for the anonymization of data records.
						</div>
						<p>
							The term ‘anonymization’ is defined here according to the GDPR, which states that when a
							data record is anonymized, identifiable information is removed from it in a permanent and
							irreversible way. According to this definition, encryption cannot be used to achieve
							anonymization.
						</p>
						<p>
							<b>
								xtendr’s non-interactive threshold hashing protocol builds on existing anonymization
								techniques, such as k-anonymity, differential privacy, and synthetic data, but with
								significant differences.
							</b>{' '}
							Our solution is a purely cryptographic approach which requires the collaboration of several
							participants. In it, the anonymized (hashed) version of a fixed data record remains
							consistent regardless of which of the collaborating participants owns the plaintext. This
							allows participants to find commonalities and patterns in the intersection of their datasets
							without disclosing any sensitive identifying information. It also allows for proper
							parameters to be set while using differential privacy - as it is possible to detect how many
							repetitions there are of each data record within a selected dataset.
						</p>
						<p>
							This protocol is also usable with any hash function, making it a flexible and
							computationally efficient solution.
						</p>
					</section>
					<section>
						<p>
							You can read the extended abstract{' '}
							<a href={'/pdfs/blog2article.pdf'} target="_blank" rel="noreferrer">
								here
							</a>
							.
						</p>
					</section>
					<section>
						<p className={CSS.EXTRA_SPACE_BEFORE}>
							<b>
								If you have any questions about the research or its applications, feel free to contact
								us any time at <a href="mailto:hello@xtendr.io">hello@xtendr.io</a>
							</b>
						</p>
					</section>
				</div>
				<YouMightBeInterested items={modifiedArticleItems} />
				<BackToResources />
			</div>
		</div>
	</div>
)

export default Blog2Route
