import { Link } from 'react-router-dom'

import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'

const BackToResources = () => (
	<Link to={PATHS.RESOURCES} className={CSS.BLOG_LINK}>
		Back to resources →
	</Link>
)

export default BackToResources
