import React from 'react'

import '../../styles/collaborationPlatform/_features.scss'
import { CSS } from '../../utils/domUtils'
import ListItemWithArrow from '../common/ListItemWithArrow'

const listItems = [
	'Easy-to-use interface',
	'Adjustable user permissions',
	'Activity logs',
	'Change requests',
	'Import data manually or via API',
]

const Features: React.FC = () => {
	return (
		<div className={`${CSS.FEATURES_CARD} ${CSS.BACKGROUND}`}>
			<div className={CSS.CONTAINER}>
				<h2 className={CSS.CARD_TITLE}>Features</h2>
				<div className={CSS.LIST}>
					{listItems.map((item, index) => (
						<ListItemWithArrow
							key={index}
							text={item}
							optionalClassName={index === listItems.length - 1 ? CSS.LAST : undefined}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export default Features
