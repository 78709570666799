import { TextField } from '@mui/material'
import React, { useCallback, useState } from 'react'

import { textFieldSX } from '../../../utils/muiSxHelpers'

type InputProps = {
	label: string
	type?: string
	multiline?: boolean
	name?: string
}

const CustomInput = ({ label, name, type = 'text', multiline = false }: InputProps) => {
	const [value, setValue] = useState<string>('')

	const handleChangeValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value)
	}, [])

	return (
		<TextField
			multiline={multiline}
			rows={multiline ? 5 : 1}
			type={type}
			label={label}
			name={name}
			variant="outlined"
			value={value}
			onChange={handleChangeValue}
			sx={textFieldSX(multiline)}
		/>
	)
}

export default CustomInput
