import React from 'react'

import financeUsecase from '../../resources/img/caseStudies/financeUsecase.jpg'
import healthcareUsecase from '../../resources/img/caseStudies/healthcareUsecase.jpg'
import manufacturingUsecase from '../../resources/img/caseStudies/manufacturingUsecase.jpg'

export type UsecaseType = {
	industry: string
	img: string
	title: string
	shortDescription: string
	problemComponent: React.FC[]
	solutionComponent: React.FC[]
	DataPrivacyComponent: React.FC
	dataPrivacySubtextComponents: React.FC[]
	outcomes: string
}

export const healthcareUseCase: UsecaseType = {
	industry: 'Healthcare',
	img: healthcareUsecase,
	title: 'Data collaboration for improved research into rare health conditions',
	shortDescription:
		'Leveraging multiple data sources while protecting privacy, for improved data access that enables deeper research and better results.',
	problemComponent: [
		() => (
			<>
				<b>Compiling highly sensitive data</b> on rare medical conditions{' '}
				<b>is a challenge for all healthcare organizations</b>, as there may be only a few instances of each
				condition recorded per hospital.
			</>
		),
		() => (
			<>
				As well as limiting the information available for research and analysis, this also means that, in the
				event of an attack, <b>it would potentially be easier for this information to be reidentified.</b>
			</>
		),
		() => (
			<>
				This makes the process of understanding these conditions <b>slow and complex</b>, and limits progress on
				improving diagnostics, treatment, protection and care.
			</>
		),
	],
	solutionComponent: [
		() => (
			<>
				xtendr allows multiple healthcare organizations to selectively share their data, in a fully private and
				compliant way.
			</>
		),
		() => (
			<>
				<b>This allows teams to access information on rare conditions</b> that has not just been gathered from
				within one medical institution, but from several - increasing the range of relevant data available for
				research and analysis.
			</>
		),
		() => (
			<>
				We also take <b>measures to prevent reidentification</b>, so that research can continue{' '}
				<b>without putting sensitive data at risk.</b>
			</>
		),
	],
	DataPrivacyComponent: () => (
		<>
			xtendr protects the privacy of all sensitive data using a combination of{' '}
			<u>Privacy Enhancing Technologies</u>, which are designed to maximize the usefulness of data without it ever
			being visible or vulnerable at any point during the process.
		</>
	),
	dataPrivacySubtextComponents: [
		() => (
			<>
				At the core of xtendr’s approach to data privacy is Fully Homomorphic Encryption, a leading
				cryptographic technique which <b>allows computations to be run on data while it is in encrypted form</b>{' '}
				- making it possible to utilize sensitive information that would otherwise be inaccessible.
			</>
		),
		() => (
			<>
				We combine this with our proprietary Multiparty Commutative Hashing Protocol, which is used to
				irreversibly strip away the most sensitive PII, in order to provide the most robust data privacy while
				retaining maximum utility.
			</>
		),
	],
	outcomes:
		'By making more data available for use, while preserving the privacy of the sensitive information that it contains, xtendr facilitates faster and more efficient understanding of rare medical conditions, leading to medical advancement, better patient experience, and improved patient outcomes.',
}

export const financeUseCase: UsecaseType = {
	industry: 'Finance',
	img: financeUsecase,
	title: 'Enhancing credit risk assessment with secure data collaboration',
	shortDescription:
		'Refining credit scoring models by integrating encrypted telco/external(?) data with financial data while maintaining privacy.',
	problemComponent: [
		() => (
			<>
				Traditional <b>credit scoring methods often fall short when accurately assessing creditworthiness</b> -
				especially for individuals with <b>limited financial history available.</b>
			</>
		),
		() => (
			<>
				This can result in higher default rates and reduced access to financial services for underrepresented
				populations.
			</>
		),
	],
	solutionComponent: [
		() => (
			<>
				xtendr allows banks to <b>securely incorporate encrypted external data</b>, such as call records and
				location data, into their credit scoring models using <b>Fully Homomorphic Encryption</b> (FHE).
			</>
		),
		() => (
			<>
				This approach <b>enhances the accuracy of credit assessments</b> by providing a more comprehensive view
				of customer behavior, all while ensuring that sensitive <b>data remains protected</b> throughout the
				process.
			</>
		),
	],
	DataPrivacyComponent: () => (
		<>
			xtendr&apos;s commitment to data privacy is underpinned by deep knowledge of advanced{' '}
			<u>Privacy Enhancing Technologies</u> - with multiple techniques providing measurable benefits.
		</>
	),
	dataPrivacySubtextComponents: [
		() => (
			<>
				By utilizing FHE, we ensure that all <b>computations are performed on data while it is encrypted</b>, so
				that sensitive information is never exposed.
			</>
		),
		() => (
			<>
				Secure Multiparty Computation and Differential Privacy also have the potential to further strengthen
				data protection, <b>minimizing the risk of reidentification and safeguarding customer privacy.</b>
			</>
		),
	],
	outcomes:
		'Applyning seecure data collaboration to credid risk assessment leads to lower default rates, and increased access to financial services for a broader range of customers, while maintaining stringent data privacy standards.',
}

export const manufucturingCase: UsecaseType = {
	industry: 'Finance',
	img: manufacturingUsecase,
	title: 'Confidential sharing of maintenance data',
	shortDescription:
		'Securely sharing maintenance data with external vendors while protecting sensitive equipment information.',
	problemComponent: [
		() => (
			<>
				Manufacturing plants require specialized maintenance services from external vendors, but sharing
				detailed equipment specifications and maintenance records poses a <b>risk of unauthorized access.</b>
			</>
		),
		() => (
			<>
				This challenge <b>complicates otherwise highly-benficial collaborations</b>, as vendors need sufficient
				information to perform their tasks, but plants must <b>safeguard their own proprietary data.</b>
			</>
		),
	],
	solutionComponent: [
		() => (
			<>
				xtendr uses Fully Homomorphic Encryption (FHE) to{' '}
				<b>securely share encrypted maintenance logs, equipment diagnostics, and service requests</b> with
				external service providers.
			</>
		),
		() => (
			<>
				This allows vendors to <b>analyze encrypted data</b> without ever accessing or decrypting sensitive
				equipment details - <b>fully protecting proprietary information</b> at all times.
			</>
		),
	],
	DataPrivacyComponent: () => (
		<>
			xtendr&apos;s in-house cryptography team combines <u>best-in-market data privacy techniques</u> with
			proprietary cryptographic protocols, to provide optimum protection for data at every stage of the process.
		</>
	),
	dataPrivacySubtextComponents: [
		() => (
			<>
				Our data privacy strategy is centered on FHE, which{' '}
				<b>allows the secure analysis of encrypted data without exposing underlying information.</b>
			</>
		),
		() => (
			<>
				This ensures that <b>sensitive maintenance records and equipment performance data remain protected</b>{' '}
				throughout the analytical process.
			</>
		),
		() => (
			<>
				Additional layers of privacy, such as Differential Privacy, further minimize the risk of data exposure
				or reidentification.
			</>
		),
	],
	outcomes:
		'By enabling secure and confidential maintenance data sharing, xtendr enhances collaboration with service providers, protects critical equipment specifications, streamlines maintenance workflows, and reduces the risk of data exposure to third parties, leading to more efficient and secure operations.',
}
