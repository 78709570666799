import howPetsWork from '../../resources/img/HowPetsWork.png'
import '../../styles/specificUseCase/_dataPrivacy.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import ButtonDark from '../common/ButtonDark'
import { UsecaseType } from './useCaseTexts'

const DataPrivacy: React.FC<Partial<UsecaseType>> = ({ DataPrivacyComponent, dataPrivacySubtextComponents }) => (
	<div className={CSS.LIGHT_BACKGROUND}>
		<div className={CSS.CONTAINER}>
			<div className={CSS.DATA_PRIVACY}>
				<div className={CSS.DATA_PRIVACY_BLOCK}>
					<div className={CSS.DATA_PRIVACY_SUBTITLE}>Data privacy</div>
					<div className={CSS.DATA_PRIVACY_TITLE}>{DataPrivacyComponent && <DataPrivacyComponent />}</div>
				</div>
				<div className={CSS.DATA_PRIVACY_ROW}>
					<div className={`${CSS.IMG_WRAPPER} ${CSS.DATA_PRIVACY_IMG}`}>
						<img src={howPetsWork} alt={'Data privacy'} />
					</div>
					<div className={CSS.DATA_PRIVACY_CONTENT}>
						{dataPrivacySubtextComponents &&
							dataPrivacySubtextComponents.map((Component, index) => (
								<div className={CSS.DATA_PRIVACY_TEXT} key={index}>
									<Component />
								</div>
							))}
						<ButtonDark
							buttonText={'Learn more'}
							to={PATHS.PRIVACY_ENHANCING_TECHNOLOGIES}
							hasArrow={true}
							extraClasses={CSS.DATA_PRIVACY_BUTTON}
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
)

export default DataPrivacy
