import React from 'react'
import { Link } from 'react-router-dom'

import illustration from '../../resources/img/customizable.svg'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'

const CollaborationCustomizable: React.FC = () => (
	<div className={`${CSS.CUSTOMIZIZATION_CARD} ${CSS.BACKGROUND}`}>
		<div className={CSS.CONTAINER}>
			<div className={CSS.FULL_HEIGHT}>
				<img src={illustration} alt={'Customizable collaboration solutions'} />
			</div>
			<div className={CSS.PADDING}>
				<h2 className={CSS.CARD_TITLE}>Customizable collaboration solutions</h2>
				<div className={CSS.CARD_SUBTITLE}>
					For new projects with specific requirements, or longer-term collaborations,
					<b> we offer fully customizable solutions built around individual client needs </b>- from
					implementing new query types, to developing tailored security solutions, to integrating secure
					machine learning features.
				</div>
				<div className={CSS.TEXT_LINK}>
					<Link to={PATHS.CUSTOM_COLLABORATION_SOLUTIONS}>FIND OUT MORE&nbsp;&nbsp; →</Link>
				</div>
			</div>
		</div>
	</div>
)

export default CollaborationCustomizable
