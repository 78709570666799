import ScrollToTop from './components/common/ScrollToTop'
import Footer from './components/footer/Footer'
import RenderRoutes from './components/routes/RenderRoutes'

const App = () => (
	<>
		<ScrollToTop />
		<RenderRoutes />
		<Footer />
	</>
)

export default App
