import React, { useState } from 'react'

import blogAuthorImg1 from '../../../resources/img/blog/authors/DánielZentai.png'
import blogAuthorImg2 from '../../../resources/img/blog/authors/MihailPlesa.jpeg'
import blogAuthorImg3 from '../../../resources/img/blog/authors/RobinFrot.png'
import { ReactComponent as ArrowDown } from '../../../resources/img/firstSectionArrowDown.svg'
import blogTitleImg from '../../../resources/img/howItWorksImg1.png'
import '../../../styles/blog/_titleCard.scss'
import { CSS } from '../../../utils/domUtils'
import Header from '../../common/Header'

const TitleCard: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<>
			<div className={`${CSS.TITLE_CARD} ${CSS.BACKGROUND}`}>
				<div className={CSS.CONTAINER}>
					<Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
					<div className={`${CSS.FLEX_CENTER} ${CSS.FLEX_COLUMN}`}>
						<h1 className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>
							Paper Summary: A Multiparty Commutative Hashing Protocol based on the Discrete Logarithm
							Problem
						</h1>
						<div className={`${CSS.BLOG_DETAILS} ${CSS.EXTRA_SPACE_BEFORE}`}>
							<div className={CSS.BLOG_AUTHOR}>
								<img src={blogAuthorImg1} alt={'Author'} />
								<span>Daniel Zentai</span>
							</div>
							<div className={CSS.BLOG_AUTHOR}>
								<img src={blogAuthorImg2} alt={'Author'} />
								<span>Mihail Plesa</span>
							</div>
							<div className={CSS.BLOG_AUTHOR}>
								<img src={blogAuthorImg3} alt={'Author'} />
								<span>Robin Frot</span>
							</div>
							<div className={CSS.BLOG_DATE}>Aug 8, 2024</div>
						</div>
					</div>

					<div className={CSS.TITLE_ARROW}>
						<ArrowDown />
					</div>
				</div>
			</div>
			<div className={CSS.LIGHT_BACKGROUND}>
				<div className={CSS.CONTAINER}>
					<div className={CSS.IMG_WRAPPER}>
						<img
							className={CSS.IMG}
							src={blogTitleImg}
							alt={'blog illustration'}
							style={{ borderRadius: '40px' }}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default TitleCard
