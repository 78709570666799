import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useCallback, useState } from 'react'

import { ReactComponent as ArrowDropdownIcon } from '../../../resources/img/arrowDropdown.svg'
import { textFieldSX } from '../../../utils/muiSxHelpers'

type MenuItemType = {
	value: string
	option: string
}

type SelectProps = {
	id: string
	label: string
	menuItems?: Array<MenuItemType>
	onChange: (e: SelectChangeEvent) => void
	name: string
}

const CustomSelect = ({ label, menuItems, id, onChange, name }: SelectProps) => {
	const [value, setValue] = useState<string>('')

	const handleChangeValue = useCallback(
		(e: SelectChangeEvent) => {
			setValue(e.target.value)
			onChange(e)
		},
		[onChange]
	)

	return (
		<FormControl fullWidth sx={textFieldSX(false)}>
			<InputLabel id={id}>{label}</InputLabel>
			<Select
				variant="outlined"
				labelId={id}
				value={value}
				label={label}
				onChange={handleChangeValue}
				IconComponent={ArrowDropdownIcon}
				name={name}
			>
				{menuItems?.map((item: MenuItemType, index: number) => (
					<MenuItem key={index} value={item.value}>
						{item.option}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

export default CustomSelect
