import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Fade from '@mui/material/Fade'
import Snackbar from '@mui/material/Snackbar'
import React from 'react'

import { EmailSeverity } from '../../utils/const'

type SnackbarComponentProps = {
	open: boolean
	message: string
	severity: EmailSeverity
	onClose: () => void
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const EmailSnackbarComponent: React.FC<SnackbarComponentProps> = ({ open, message, severity, onClose }) => {
	return (
		<Snackbar
			open={open}
			autoHideDuration={3000}
			onClose={onClose}
			TransitionComponent={Fade}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<Alert onClose={onClose} severity={severity}>
				{message}
			</Alert>
		</Snackbar>
	)
}

export default EmailSnackbarComponent
