import React from 'react'

import HowItWorks1 from '../../resources/img/howItWorks/howItWorks1.svg'
import HowItWorks2 from '../../resources/img/howItWorks/howItWorks2.svg'
import HowItWorks3 from '../../resources/img/howItWorks/howItWorks3.svg'
import HowItWorks4 from '../../resources/img/howItWorks/howItWorks4.svg'
import HowItWorks5 from '../../resources/img/howItWorks/howItWorks5.svg'
import '../../styles/collaborationPlatform/_howItWorks.scss'
import { CSS } from '../../utils/domUtils'
import AccordionItem from './AccordionItem'

const HowItWorks = () => {
	const [activeBullet, setActiveBullet] = React.useState(1)
	const imgs = [HowItWorks1, HowItWorks2, HowItWorks3, HowItWorks4, HowItWorks5]

	return (
		<div className={`${CSS.HOW_IT_WORKS_CARD} ${CSS.BACKGROUND} ${CSS.DARK_CARD}`}>
			<div className={CSS.CONTAINER}>
				<h2 className={CSS.CARD_TITLE}>How it works</h2>
				<div className={CSS.FLEX}>
					<div className={CSS.HOW_IT_WORKS_WRAPPER}>
						<AccordionItem
							nr={1}
							titleText={'Initial agreements'}
							setActiveBullet={setActiveBullet}
							activeBullet={activeBullet}
							panelElement={
								<p>
									All participating organizations agree on the goals and terms of their project in in
									in advance - including the desired outcomes of the collaboration, specific queries
									that they want to run, and how they want to set up data privacy elements.
								</p>
							}
							img={HowItWorks1}
						/>
						<AccordionItem
							nr={2}
							titleText={'Platform setup'}
							setActiveBullet={setActiveBullet}
							activeBullet={activeBullet}
							panelElement={
								<>
									<p>
										Collaborators define roles and processes in the xtendr Collaboration Platform.
									</p>
									<ul>
										<li>Teams set up and Team Admins assigned</li>
										<li>Dataset structures defined (data types, column names, etc)</li>
										<li>
											Queries defined (choose from predefined simple queries and a broad range of
											possible custom SQL inputs)
										</li>
										<li>Project end date set</li>
									</ul>
								</>
							}
							img={HowItWorks2}
						/>
						<AccordionItem
							nr={3}
							titleText={'Project approval'}
							setActiveBullet={setActiveBullet}
							activeBullet={activeBullet}
							panelElement={
								<>
									<p>Platform setup is published and circulated for approval by all Team Admins.</p>
									<p>
										Node setup instructions are sent for implementation by participants’ IT teams.
									</p>
								</>
							}
							img={HowItWorks3}
						/>
						<AccordionItem
							nr={4}
							titleText={'Data sharing and session initiation'}
							setActiveBullet={setActiveBullet}
							activeBullet={activeBullet}
							panelElement={
								<p>
									Participants share their fully encrypted datasets. Pre-approved queries are then run
									without ever decrypting or revealing raw information.
								</p>
							}
							img={HowItWorks4}
						/>
						<AccordionItem
							nr={5}
							titleText={'Results & project end'}
							setActiveBullet={setActiveBullet}
							activeBullet={activeBullet}
							panelElement={
								<>
									<p>
										Query results are shared with the user that initiated the session, who can
										securely download them as CSV files.
									</p>
									<p>
										When the scheduled project end date arrives, the project is archived and all
										data is permanently deleted.
									</p>
								</>
							}
							img={HowItWorks5}
						/>
					</div>
					<div className={`${CSS.HOW_IT_WORKS_IMG_WRAPPER} ${CSS.ACTIVE}`}>
						{!!activeBullet && (
							<img className={CSS.HOW_IT_WORKS_IMG} src={imgs[activeBullet - 1]} alt="How it works" />
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default HowItWorks
