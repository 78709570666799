import { ReactElement, useCallback, useState } from 'react'

import { ReactComponent as CloseIcon } from '../../resources/img/keyPrivacyEnhancingTechnologiesItemClose.svg'
import '../../styles/privacyEnhancingTechnologies/_keyPrivacyEnhancingTechnologies.scss'
import { CSS } from '../../utils/domUtils'

type KeyPrivacyEnhancingTechnologiesItemProps = {
	title: string
	content: ReactElement
	index: number
}

const KeyPrivacyEnhancingTechnologiesItem = ({ title, content, index }: KeyPrivacyEnhancingTechnologiesItemProps) => {
	const [isOpened, setIsOpened] = useState<boolean>(false)

	const onHandleClick = useCallback(() => {
		setIsOpened((prevState) => !prevState)
	}, [])

	return (
		<div
			className={`${CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM} ${CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM}-${index}`}
			style={{ background: isOpened ? '#ECFFFA' : '#00C495' }}
		>
			<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_HEADER}>
				<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_TITLE}>{title}</div>
				<div
					className={`${CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_BUTTON} ${isOpened ? 'opened' : ''}`}
					onClick={onHandleClick}
				>
					<CloseIcon />
				</div>
			</div>
			{isOpened ? content : null}
		</div>
	)
}

export default KeyPrivacyEnhancingTechnologiesItem
