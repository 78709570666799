import React, { useState } from 'react'

import { ReactComponent as ArrowDown } from '../../resources/img/firstSectionArrowDown.svg'
import '../../styles/homePage/_titleCard.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import ButtonDark from '../common/ButtonDark'
import Header from '../common/Header'

const TitleCard: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<>
			<div className={`${CSS.TITLE_CARD} ${CSS.BACKGROUND}`}>
				<div className={CSS.CONTAINER}>
					<Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
					<h1 className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>
						Connecting data, protecting privacy
						<ButtonDark
							buttonText={'Our Platform'}
							to={PATHS.COLLABORATION_PLATFORM}
							extraClasses={`${CSS.DISPLAY_BIGSCREEN} ${CSS.OUR_PLATFORM_BUTTON}`}
							hasArrow={true}
						/>
					</h1>
					<div className={CSS.CARD_SUBTITLE}>
						Access, share and analyze sensitive data in a fully secure and legally compliant way, with
						secure data collaboration solutions powered by <b>Privacy Enhancing Technologies</b>.
					</div>
					<ButtonDark
						buttonText={'Our Platform'}
						extraClasses={`${CSS.DISPLAY_SMALLSCREEN} ${CSS.OUR_PLATFORM_BUTTON}`}
						to={PATHS.COLLABORATION_PLATFORM}
						hasArrow={true}
					/>
					<div className={CSS.TITLE_ARROW}>
						<ArrowDown />
					</div>
				</div>
			</div>
		</>
	)
}

export default TitleCard
