import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import useScrollPosition from '../../hooks/useScrollPosition'
import { ReactComponent as hMenuDarkGreen } from '../../resources/img/hMenu/hMenuDarkGreen.svg'
import { ReactComponent as HMenuLight } from '../../resources/img/hMenu/hMenuLight.svg'
import { ReactComponent as HMenu } from '../../resources/img/hMenu/hMenuOpen.svg'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import ButtonDark from './ButtonDark'
import ButtonGreen from './ButtonGreen'
import LogoDark from './LogoDark'
import LogoGreen from './LogoGreen'
import SideMenu from './SideMenu'

type HeaderProps = {
	isLight?: boolean
	isMenuOpen: boolean
	setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Header: React.FC<HeaderProps> = ({ isLight, setIsMenuOpen, isMenuOpen }) => {
	const [activeItem, setActiveItem] = React.useState<number | null>(0)
	const scrollPosition = useScrollPosition()

	const Logo = isLight ? <LogoGreen isMenuOpen={isMenuOpen} /> : <LogoDark isMenuOpen={isMenuOpen} />
	const MenuButton = isLight ? HMenuLight : hMenuDarkGreen

	useEffect(() => {
		if (isMenuOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'unset'
		}
	}, [isMenuOpen])

	const closeMenu = () => {
		setIsMenuOpen(false)
		setActiveItem(0)
	}

	return (
		<div className={`${CSS.HEADER}`}>
			<div
				className={`${CSS.CONTAINER} ${CSS.MAIN}`}
				style={{
					transitionDelay: isMenuOpen ? '0.25s' : 'unset',
					background: isMenuOpen ? '#0F1936' : 'none',
					pointerEvents: 'none',
				}}
			>
				<Link
					to={PATHS.HOME}
					onClick={() => setIsMenuOpen(false)}
					className={`${CSS.HEADER_LOGO} ${CSS.CLICKABLE}`}
				>
					{Logo}
				</Link>
				<div className={`${CSS.FLEX} ${CSS.GAP}`}>
					{isMenuOpen ? (
						<ButtonGreen
							buttonText={'Contact us'}
							to={PATHS.CONTACT}
							extraClasses={`${CSS.CONTACT_BUTTON} ${CSS.CLICKABLE}`}
						/>
					) : (
						<ButtonDark
							buttonText={'Contact us'}
							to={PATHS.CONTACT}
							extraClasses={`${CSS.CONTACT_BUTTON} ${CSS.CLICKABLE} ${isLight && CSS.LIGHT} ${scrollPosition > 20 && 'scrolled'}`}
						/>
					)}
					{isMenuOpen ? (
						<HMenu className={`${CSS.MENU_BUTTON} ${CSS.CLICKABLE} opened`} onClick={closeMenu} />
					) : (
						<MenuButton
							className={`${CSS.MENU_BUTTON} ${CSS.CLICKABLE} closed`}
							onClick={() => setIsMenuOpen(!isMenuOpen)}
						/>
					)}
				</div>
			</div>
			<SideMenu
				isMenuOpen={isMenuOpen}
				setIsMenuOpen={setIsMenuOpen}
				activeItem={activeItem}
				setActiveItem={setActiveItem}
			/>
		</div>
	)
}

export default Header
