import React, { useState } from 'react'

import blogAuthorImg from '../../../resources/img/blog/authors/MirandaOverett.png'
import blogTitleImg from '../../../resources/img/blog/illustrations/blog3.jpg'
import { ReactComponent as ArrowDown } from '../../../resources/img/firstSectionArrowDown.svg'
import '../../../styles/blog/_titleCard.scss'
import { CSS } from '../../../utils/domUtils'
import Header from '../../common/Header'

const TitleCard: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<>
			<div className={`${CSS.TITLE_CARD} ${CSS.BACKGROUND}`}>
				<div className={CSS.CONTAINER}>
					<Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
					<div className={`${CSS.FLEX_CENTER} ${CSS.FLEX_COLUMN}`}>
						<h1 className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>
							Protecting Privacy in AI Model Training: How Privacy Enhancing Technologies Keep Data Safe
						</h1>
						<h2 className={CSS.CARD_TEXT}>
							As the use of AI becomes increasingly widespread, important questions are being asked about
							how to maintain the privacy of training data. Here’s how Privacy Enhancing Technologies can
							help.
						</h2>
						<div className={CSS.BLOG_DETAILS}>
							<div className={CSS.BLOG_AUTHOR}>
								<img src={blogAuthorImg} alt={'Author'} />
								<span>Miranda Overett</span>
							</div>
							<div className={CSS.BLOG_DATE}>Oct 8, 2024</div>
						</div>
					</div>

					<div className={CSS.TITLE_ARROW}>
						<ArrowDown />
					</div>
				</div>
			</div>
			<div className={CSS.LIGHT_BACKGROUND}>
				<div className={CSS.CONTAINER}>
					<div className={CSS.IMG_WRAPPER}>
						<img
							className={CSS.IMG}
							src={blogTitleImg}
							alt={'blog illustration'}
							style={{ borderRadius: '40px' }}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default TitleCard
