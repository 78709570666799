import { Link } from 'react-router-dom'

import '../../styles/privacyEnhancingTechnologies/_keyPrivacyEnhancingTechnologies.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import KeyPrivacyEnhancingTechnologiesItem from './KeyPrivacyEnhancingTechnologiesItem'
import DifferentialPrivacy from './keyContents/DifferentialPrivacy'
import FullyHomomorphicEncryption from './keyContents/FullyHomomorphicEncryption'
import MultipartyCommutativeHashingProtocol from './keyContents/MultipartyCommutativeHashingProtocol'
import PrivateSetIntersection from './keyContents/PrivateSetIntersection'
import SecureMultipartyComputation from './keyContents/SecureMultipartyComputation'

const items = [
	{
		title: 'Fully Homomorphic Encryption (FHE)',
		content: <FullyHomomorphicEncryption />,
	},
	{
		title: 'Multiparty Commutative Hashing Protocol',
		content: <MultipartyCommutativeHashingProtocol />,
	},
	{
		title: 'Private Set Intersection (PSI)',
		content: <PrivateSetIntersection />,
	},
	{
		title: 'Secure Multiparty Computation (SMPC)',
		content: <SecureMultipartyComputation />,
	},
	{
		title: 'Differential Privacy',
		content: <DifferentialPrivacy />,
	},
]

const KeyPrivacyEnhancingTechnologies: React.FC = () => {
	return (
		<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES}>
			<div className={CSS.CONTAINER}>
				<h2 className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_TITLE}>Key Privacy Enhancing Technologies</h2>
				<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_SUBTITLE}>
					Expand the topics below to learn more about the Privacy Enhancing Technologies that we use at
					xtendr, or go to our <Link to={PATHS.HOW_IT_WORKS}>How It Works</Link> page to find out how we use
					them in our solutions.
				</div>
				{items.map((item, index) => (
					<KeyPrivacyEnhancingTechnologiesItem
						key={index}
						title={item.title}
						content={item.content}
						index={index}
					/>
				))}
			</div>
		</div>
	)
}

export default KeyPrivacyEnhancingTechnologies
