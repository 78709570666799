import React from 'react'

import howPetsWork from '../../resources/img/HowPetsWork.png'
import '../../styles/privacyEnhancingTechnologies/_howPetsWork.scss'
import { CSS } from '../../utils/domUtils'

const HowPetsWork: React.FC = () => {
	return (
		<div className={CSS.LIGHT_BACKGROUND}>
			<div className={CSS.HOW_PETS_WORK}>
				<div className={CSS.CONTAINER}>
					<div className={CSS.HOW_PETS_WORK_CONTENT}>
						<div>
							<h2 className={CSS.HOW_PETS_WORK_TITLE}>How PETs work</h2>
							<div className={CSS.HOW_PETS_WORK_TEXT}>
								<div>
									All Privacy Enhancing Technologies work in unique ways, and have valuable
									applications when used individually. However, for many projects, simply using one is
									not enough.{' '}
								</div>
								<div>
									At xtendr, the majority of{' '}
									<b>
										our solutions combine multiple privacy enhancing technologies in order to
										achieve the best possible results.
									</b>
								</div>
							</div>
						</div>
						<div className={`${CSS.IMG_WRAPPER} ${CSS.HOW_PETS_WORK_IMG}`}>
							<img src={howPetsWork} alt={'How PETs work'} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HowPetsWork
