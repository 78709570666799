export enum CSS {
	ABOUT_US = 'about-us',
	ACCORDION = 'accordion',
	ACCORDION_IMG = 'accordion-img',
	ACCORDION_WRAPPER = 'accordion-wrapper',
	ACTIVE = 'active',
	ADDITIONAL_PAGE = 'additional-page',
	ALERT = 'alert',
	ARROW_ICON = 'arrow-icon',
	ARROW_UP = 'arrow-up',
	ARROW_DOWN = 'arrow-down',
	BACKGROUND = 'background',
	BUTTON_DARK = 'button-dark',
	BUTTON_GREEN = 'button-green',
	BLOG = 'blog',
	BLOG_CONTENT = 'blog-content',
	BLOG_LINK = 'blog-link',
	BLOG_DATE = 'blog-date',
	BLOG_DETAILS = 'blog-details',
	BLOG_AUTHOR = 'blog-author',
	BUTTON = 'button',
	CAREERS = 'careers',
	CAREERS_BLOCK = 'careers-block',
	CARD_FLEX = 'card-flex',
	CARD_LABEL = 'card-label',
	CARD_TITLE = 'card-title',
	CARD_TEXT = 'card-text',
	CARD_SUBTITLE = 'card-subtitle',
	CASE_STUDIES = 'case-studies',
	CASE_STUDIES_TITLE = 'case-studies-title',
	CASE_STUDIES_SWIPER = 'case-studies-swiper',
	CASE_STUDIES_ITEM = 'case-studies-item',
	CASE_STUDIES_ITEM_PHOTO = 'case-studies-item-photo',
	CASE_STUDIES_ITEM_BLOCK = 'case-studies-item-block',
	CASE_STUDIES_ITEM_LOGO = 'case-studies-item-logo',
	CASE_STUDIES_ITEM_TITLE = 'case-studies-item-title',
	CASE_STUDIES_ITEM_DESCRIPTION = 'case-studies-item-description',
	CASE_STUDIES_ITEM_LINK = 'case-studies-item-link',
	CLICKABLE = 'clickable',
	CLOSED_MENU = 'closed-menu',
	COLLAB_FLEX_ROW = 'collab-flex-row',
	COLLAB_SMALL_IMG = 'collab-small-img',
	COLLAB_WRAPPER = 'collab-wrapper',
	COLLABORATION_CARD = 'collaboration-card',
	COLLABORATION_TEXT = 'collaboration-text',
	COLLABORATION_PLATFORM = 'collaboration-platform',
	COLLABORATION_PROJECT_IMG = 'collaboration-project-img',
	COLOR_BLOCK = 'color-block',
	COLOR_BLOCK_TITLE = 'color-block-title',
	COLOR_BLOCK_SUBTITLE = 'color-block-subtitle',
	CONFIRM_SUBSCRIPTION = 'confirm-subscription',
	CONTENT = 'content',
	CONTENT_ITEMS_CONTAINER = 'content-items-container',
	CONTENT_SHOW_MORE = 'content-show-more',
	COMBINING_CARD = 'combining-card',
	COMING_SOON = 'coming-soon',
	COMING_SOON_ROUTE = 'coming-soon-route',
	COMMITED = 'commited',
	CONSULTATION = 'consultation',
	CONSULT_WRAPPER = 'consult-wrapper',
	CONTAINER = 'container',
	CONTACT = 'contact',
	CONTACT_CONTAINER = 'contact-container',
	CONTACT_CONTENT = 'contact-content',
	CONTACT_FORM = 'contact-form',
	CONTACT_ROW = 'contact-row',
	CONTACT_SUBMIT = 'contact-submit',
	CONTACT_BUTTON = 'contact-button',
	CONTACT_LINK = 'contact-link',
	CUSTOM_COLLABORATION_IMG = 'custom-collaboration-img',
	CUSTOM_COLLABORATION_SOLUTIONS = 'custom-collaboration-solutions',
	DARK_CARD = 'dark-card',
	DATA_PRIVACY = 'data-privacy',
	DATA_PRIVACY_BLOCK = 'data-privacy-block',
	DATA_PRIVACY_BUTTON = 'data-privacy-button',
	DATA_PRIVACY_ROW = 'data-privacy-row',
	DATA_PRIVACY_CONTENT = 'data-privacy-content',
	DATA_PRIVACY_IMG = 'data-privacy-img',
	DATA_PRIVACY_SUBTITLE = 'data-privacy-subtitle',
	DATA_PRIVACY_TITLE = 'data-privacy-title',
	DATA_PRIVACY_TEXT = 'data-privacy-text',
	DEMO_DESCRIPTION = 'demo-description',
	DEMO_FORM = 'demo-form',
	DEMO_ROUTE = 'demo-route',
	DEMO_CARD = 'demo-card',
	DEMO_CHECKBOXES = 'demo-checkboxes',
	DEMO_GET_IN_TOUCH = 'demo-get-in-touch',
	DEMO_LIST = 'demo-list',
	DEMO_SUBMIT = 'demo-submit',
	DEMO_UNDERFORM_TEXT = 'demo-underform-text',
	CUSTOMIZIZATION_CARD = 'customization-card',
	CUSTOMIZATIONS = 'customizations',
	CUSTOMIZATIONS_TITLE = 'customizations-title',
	CUSTOMIZATIONS_LIST = 'customizations-list',
	DISCOVER_CARD = 'discover-card',
	DISCOVER_CARD_LIST = 'discover-card-list',
	LIST_ITEM_WITH_ARROW = 'list-item-with-arrow',
	DISPLAY_BIGSCREEN = 'display-bigscreen',
	DISPLAY_GIANTSCREEN = 'display-giantscreen',
	DISPLAY_MIDDLESCREEN = 'display-middlescreen',
	DISPLAY_SMALLSCREEN = 'display-smallscreen',
	EMAIL_INPUT = 'email-input',
	EXTRA_SPACE_BEFORE = 'extra-space-before',
	FILTERS_CONTAINER = 'filters-container',
	FILTERS = 'filters',
	FILTERS_LABEL = 'filters-label',
	FILTERS_ITEM = 'filters-item',
	FILTERS_ITEMS = 'filters-items',
	FAQ = 'faq',
	FAQ_ITEM = 'faq-item',
	FAQ_QUESTION = 'faq-question',
	FAQ_ANSWER = 'faq-answer',
	FAQ_ICON = 'faq-icon',
	FAQ_SUBTITLE = 'faq-subtitle',
	FAQ_CATEGORY = 'faq-category',
	FAQ_CONTACT_US = 'faq-contact-us',
	FAQ_CONTACT_US_TITLE = 'faq-contact-us-title',
	FEATURES_CARD = 'features-card',
	FLEX = 'flex',
	FLEX_CENTER = 'flex-center',
	FLEX_ROW = 'flex-row',
	FLEX_COLUMN = 'flex-column',
	FOOTER = 'footer',
	FOOTER_INFO = 'footer-info',
	FOOTER_LOGO = 'footer-logo',
	FOOTER_LEGAL = 'footer-legal',
	FOOTER_LEGAL_WRAPPER = 'footer-legal-wrapper',
	FOOTER_PAGES = 'footer-pages',
	GAP = 'gap',
	FULL_HEIGHT = 'full-height',
	ADDITIONAL_BLOCK = 'additional-block',
	ADDITIONAL_TITLE = 'additional-title',
	INDUSTRY = 'industry',
	INDUSTRY_DESCRIPTION = 'industry-description',
	INDUSTRY_BLOCK = 'industry-block',
	INDUSTRY_OVERVIEW = 'industry-overview',
	INDUSTRY_OUTCOMES = 'industry-outcomes',
	INDUSTRY_OUTCOMES_BLOCK = 'industry-outcomes-block',
	INDUSTRY_OUTCOMES_SUBTITLE = 'industry-outcomes-subtitle',
	INDUSTRY_OUTCOMES_LIST = 'industry-outcomes-list',
	INDUSTRY_OUTCOMES_LIST_ITEM = 'industry-outcomes-list-item',
	HEADER = 'header',
	HEADER_FOOTER = 'header-footer',
	HEADER_LINK = 'header-link',
	HEADER_LINKS = 'header-links',
	HEADER_LOGO = 'header-logo',
	HEADING_7 = 'heading-7',
	HOME_PAGE_ROUTE = 'home-page-route',
	HOW_IT_WORKS = 'how-it-works',
	HOW_IT_WORKS_CARD = 'how-it-works-card',
	HOW_IT_WORKS_IMG = 'how-it-works-img',
	HOW_IT_WORKS_IMG_SMALL = 'how-it-works-img-small',
	HOW_IT_WORKS_IMG_WRAPPER = 'how-it-works-img-wrapper',
	HOW_IT_WORKS_REST = 'how-it-works-rest',
	HOW_IT_WORKS_WRAPPER = 'how-it-works-wrapper',
	HOW_PETS_WORK = 'how-pets-work',
	HOW_PETS_WORK_IMG = 'how-pets-work-img',
	HOW_PETS_WORK_CONTENT = 'how-pets-work-content',
	HOW_PETS_WORK_TITLE = 'how-pets-work-title',
	HOW_PETS_WORK_TEXT = 'how-pets-work-text',
	HOW_PETS_WORK_QUOTE = 'how-pets-work-quote',
	HOW_PETS_WORK_QUOTE_TITLE = 'how-pets-work-quote-title',
	HOW_PETS_WORK_QUOTE_TEXT = 'how-pets-work-quote-text',
	HOVER = 'hover',
	IDEAL_BLOCK = 'ideal-block',
	IDEAL_BUTTON = 'ideal-button',
	IDEAL_CONTAINER = 'ideal-container',
	IDEAL_CONTENT = 'ideal-content',
	IDEAL_IMG = 'ideal-img',
	IDEAL_TEXT = 'ideal-text',
	IDEAL_SUBTEXT = 'ideal-subtext',
	IMG = 'img',
	ILLUSTRATION_INNER_WRAPPER = 'illustration-inner-wrapper',
	ILLUSTRATIONS_WRAPPER = 'illustrations-wrapper',
	KEY_PRIVACY_ENHANCING_TECHNOLOGIES = 'key-privacy-enhancing-technologies',
	KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM = 'key-privacy-enhancing-technologies-item',
	KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT = 'key-privacy-enhancing-technologies-item-content',
	KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_BLOCK = 'key-privacy-enhancing-technologies-item-content-block',
	KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_TITLE = 'key-privacy-enhancing-technologies-item-content-title',
	KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_HEADER = 'key-privacy-enhancing-technologies-item-header',
	KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_TITLE = 'key-privacy-enhancing-technologies-item-title',
	KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_BUTTON = 'key-privacy-enhancing-technologies-item-button',
	KEY_PRIVACY_ENHANCING_TECHNOLOGIES_TITLE = 'key-privacy-enhancing-technologies-title',
	KEY_PRIVACY_ENHANCING_TECHNOLOGIES_SUBTITLE = 'key-privacy-enhancing-technologies-subtitle',
	LIGHT = 'light',
	MAIN = 'main',
	MENU = 'menu',
	MENU_BUTTON = 'menu-button',
	MENU_ITEM = 'menu-item',
	MENU_ITEM_ACTIVE = 'menu-item-active',
	MENU_OPEN = 'menu-open',
	MENU_WRAPPER = 'menu-wrapper',
	LAST = 'last',
	LAPTOP_IMG = 'laptop-img',
	LEAD = 'lead',
	LEFT_MARGIN = 'left-margin',
	LEFT_MIDDLE_TEXT = 'left-middle-text',
	LEFT_SUBTITLE = 'left-subtitle',
	LIGHT_BACKGROUND = 'light-background',
	LINKEDIN_BTN = 'linkedin-btn',
	LINKEDIN_BIGSCREEN = 'linkedin-bigscreen',
	LINKEDIN_SMALLSCREEN = 'linkedin-smallscreen',
	LINKS = 'links',
	LINKS_SMALLSCREEN = 'links-smallscreen',
	LINK_COLUMN = 'link-column',
	LIST = 'list',
	LOGO = 'logo',
	LOGO_DARK = 'logo-dark',
	LOGO_SCROLLER = 'logo-scroller',
	NEWSLETTER = 'newsletter',
	NEWSLETTER_BUTTON = 'newsletter-button',
	NEWSLETTER_BUTTON_TEXT = 'newsletter-button-text',
	MAIN_TEXT = 'main-text',
	NUMBER_ACCORDION = 'number-accordion',
	NUMBER_ACCORDION_BUTTON = 'number-accordion-button',
	NUMBER_ACCORDION_HEADER = 'number-accordion-header',
	NUMBER_ACCORDION_NUMBER = 'number-accordion-number',
	NUMBER_ACCORDION_TITLE = 'number-accordion-title',
	NUMBER_ACCORDION_TEXT = 'number-accordion-text',
	IMG_WRAPPER = 'img-wrapper',
	OPEN_MENU = 'open-menu',
	OUR_VALUES = 'our-values',
	OUR_VALUES_BLOCK = 'our-values-block',
	OUR_VALUES_SUBTITLE = 'our-values-subtitle',
	OUR_VALUES_TITLE = 'our-values-title',
	OUR_VALUES_DESCRIPTION = 'our-values-description',
	OUR_PLATFORM_BUTTON = 'our-platform-button',
	OUTCOMES = 'outcomes',
	OUTCOMES_SUBTITLE = 'outcomes-subtitle',
	OUTCOMES_TITLE = 'outcomes-title',
	OUR_TEAM = 'our-team',
	OUR_TEAM_CARD = 'our-team-card',
	OUR_TEAM_CARD_PHOTO = 'our-team-card-photo',
	OUR_TEAM_CARD_NAME = 'our-team-card-name',
	OUR_TEAM_CARD_TITLE = 'our-team-card-title',
	OUR_TEAM_CARD_LINK = 'our-team-card-link',
	OUR_TEAM_MEMBERS = 'our-team-members',
	OUR_TEAM_TITLE = 'our-team-title',
	OUR_TEAM_SUBTITLE = 'our-team-subtitle',
	OUR_TEAM_JOINING_BLOCK = 'our-team-joining-block',
	OUR_TEAM_JOINING_TITLE = 'our-team-joining-title',
	OUTCOMES_CARD = 'outcomes-card',
	OUTCOMES_CARD_TITLE = 'outcomes-card-title',
	OUTCOMES_BASE = 'outcomes-base',
	OUTOMES_IMG = 'outcomes-img',
	PADDING = 'padding',
	PANEL = 'panel',
	PARTNERS_TITLE = 'partners-title',
	PARTNERS = 'partners',
	PLANNING = 'planning',
	PLANNING_TITLE = 'planning-title',
	PLANNING_SUBTITLE = 'planning-subtitle',
	POWER_BLOCK = 'power-block',
	POWER_TITLE = 'power-title',
	POWER_SUBTITLE = 'power-subtitle',
	PROBLEM_SOLUTION = 'problem-solution',
	PROBLEM_SOLUTION_BLOCK = 'problem-solution-block',
	PROBLEM_SOLUTION_BUTTON = 'problem-solution-button',
	PROBLEM_SOLUTION_TITLE = 'problem-solution-title',
	PROBLEM_SOLUTION_TEXT = 'problem-solution-text',
	PRIVACY_CARD_HORIZONTAL = 'privacy-card-horizontal',
	PRIVACY_CARD_VERTICAL = 'privacy-card-vertical',
	PRIVACY_ENHANCING_TECHNOLOGIES = 'privacy-enhancing-technologies',
	PRIVACY_TEXT = 'privacy-text',
	PRIVACY_SUBTEXT = 'privacy-subtext',
	PRIVACY_CARD_CONTAINER = 'privacy-card-container',
	PRIVACY_CARD = 'privacy-card',
	PRIVACY_ENHANCING_TECHNOLOGIES_ANSWER = 'privacy-enhancing-technologies-answer',
	PRIVACY_ENHANCING_TECHNOLOGIES_QUESTION = 'privacy-enhancing-technologies-question',
	PRIVACY_ENHANCING_TECHNOLOGIES_WHAT_BLOCK = 'privacy-enhancing-technologies-what-block',
	PRIVACY_ENHANCING_TECHNOLOGIES_WHY_BLOCK = 'privacy-enhancing-technologies-why-block',
	PRIVACY_ENHANCING_TECHNOLOGIES_WHY_CONTAINER = 'privacy-enhancing-technologies-why-container',
	PRIVACY_POLICY = 'privacy-policy',
	PROTECT_DATA = 'protect-data',
	RIGHT_SUBTITLE = 'right-subtitle',
	ROUNDED = 'rounded',
	RESOURCES = 'resources',
	QUALITY_ASSURANCE = 'quality-assurance',
	QUALITY_ASSURANCE_BLOCK = 'quality-assurance-block',
	QUALITY_ASSURANCE_TITLE = 'quality-assurance-title',
	SECURE_DATA_TITLE = 'secure-data-title',
	SECURE_DATA_CARD = 'secure-data-card',
	SECURE_DATA_IMG = 'secure-data-img',
	SECURE_DATA_IMG_MOBILE = 'secure-data-img-mobile',
	SECURE_DATA_FIRST_ROW = 'secure-data-first-row',
	SECURITY_BLOCK = 'security-block',
	SECURITY_TEXT = 'security-text',
	SECURITY_SUBTEXT = 'security-subtext',
	SIDE_MENU = 'side-menu',
	SMALL_CARD = 'small-card',
	SNACKBAR_CONTAINER = 'snackbar-container',
	STEPPER = 'stepper',
	STEPPER_BUTTON = 'stepper-button',
	STEPPER_COMPONENT = 'stepper-component',
	STEPPER_CONTENT = 'stepper-content',
	STEPPER_LINE = 'stepper-line',
	STEPPER_NUMBER = 'stepper-number',
	STEPPER_MOBILE_IMG_WRAPPER = 'stepper-mobile-img-wrapper',
	STEPPER_INNER = 'stepper-inner',
	STEPPER_IMG_WRAPPER = 'stepper-img-wrapper',
	STEPPER_ROW = 'stepper-row',
	STEPPER_TEXT = 'stepper-text',
	STEPPER_TITLE = 'stepper-title',
	STEPPER_LIST_TITLE = 'stepper-list-title',
	STEPPER_LIST = 'stepper-list',
	STEPPER_LIST_ITEM = 'stepper-list-item',
	STEPPER_LIST_ITEM_TITLE = 'stepper-list-item-title',
	STEPPER_LIST_ITEM_TEXT = 'stepper-list-item-text',
	SPECIFIC_USE_CASE = 'specific-use-case',
	SPECIFIC_USE_CASE_GET_IN_TOUCH = 'specific-use-case-get-in-touch',
	SPECIFIC_USE_CASE_GET_IN_TOUCH_TITLE = 'specific-use-case-get-in-touch-title',
	SUBMENU_WRAPPER = 'submenu-wrapper',
	SUBSCRIBE_BTN = 'subscribe-btn',
	SUB_TEXT = 'sub-text',
	TEXT_LINK = 'text-link',
	TEXT = 'text',
	TITLE_ARROW = 'title-arrow',
	TITLE_CARD = 'title-card',
	TITLE_CARD_SUBTITLE = 'title-card-subtitle',
	TITLE_CARD_TITLE = 'title-card-title',
	TITLE_WRAPPER = 'title-wrapper',
	TRY_DEMO = 'try-demo',
	TRY_IT_NOW = 'try-it-now',
	USEFUL_RESOURCES = 'useful-resources',
	USEFUL_RESOURCES_TITLE = 'useful-resources-title',
	USEFUL_RESOURCES_ITEM = 'useful-resources-item',
	USEFUL_RESOURCES_ITEM_HEADER = 'useful-resources-item-header',
	USEFUL_RESOURCES_ITEM_LABEL = 'useful-resources-item-label',
	USEFUL_RESOURCES_ITEM_DATE = 'useful-resources-item-date',
	USEFUL_RESOURCES_ITEM_TITLE = 'useful-resources-item-title',
	USEFUL_RESOURCES_ITEMS = 'useful-resources-items',
	YOU_MIGHT_BE_INTERESTED = 'you-might-be-interested',
	YOU_MIGHT_BE_INTERESTED_ITEM = 'you-might-be-interested-item',
	YOU_MIGHT_BE_INTERESTED_ITEM_PICTURE = 'you-might-be-interested-item-picture',
	YOU_MIGHT_BE_INTERESTED_ITEM_HEADER = 'you-might-be-interested-item-header',
	YOU_MIGHT_BE_INTERESTED_ITEM_LABEL = 'you-might-be-interested-item-label',
	YOU_MIGHT_BE_INTERESTED_ITEM_DATE = 'you-might-be-interested-item-date',
	YOU_MIGHT_BE_INTERESTED_ITEM_TITLE = 'you-might-be-interested-item-title',
	YOU_MIGHT_BE_INTERESTED_ITEM_DESCRIPTION = 'you-might-be-interested-item-description',
	YOU_MIGHT_BE_INTERESTED_ITEM_LINK = 'you-might-be-interested-item-link',
	YOU_MIGHT_BE_INTERESTED_TITLE = 'you-might-be-interested-title',
	YOU_MIGHT_BE_INTERESTED_ITEMS = 'you-might-be-interested-items',
	WHY_US = 'why-us',
	WHY_US_TITLE = 'why-us-title',
	WHY_US_CONTENT = 'why-us-content',
	WHY_US_CARD = 'why-us-card',
	WHY_US_CARD_LABEL = 'why-us-card-label',
	WHY_US_CARD_DESCRIPTION = 'why-us-card-description',
	WRAPPER = 'wrapper',
}
