import { Helmet } from 'react-helmet'

import { CSS } from '../../utils/domUtils'
import Content from './Content'
import TitleCard from './TitleCard'

const ResourcesRoute = () => (
	<div className={CSS.RESOURCES}>
		<Helmet>
			<title>Resources</title>
		</Helmet>
		<TitleCard />
		<Content />
	</div>
)
export default ResourcesRoute
