import React from 'react'
import { Helmet } from 'react-helmet'

import { CSS } from '../../utils/domUtils'
import CaseStudiesBlock from './CaseStudiesBlock'
import DataPrivacy from './DataPrivacy'
import Outcomes from './Outcomes'
import ProblemSolution from './ProblemSolution'
import TitleCard from './TitleCard'
import { UsecaseType } from './useCaseTexts'

type SpecificUseCaseRouteProps = {
	usecase: UsecaseType
}

const SpecificUseCaseRoute: React.FC<SpecificUseCaseRouteProps> = ({ usecase }) => {
	const {
		industry,
		title,
		img,
		shortDescription,
		problemComponent,
		solutionComponent,
		DataPrivacyComponent,
		dataPrivacySubtextComponents,
		outcomes,
	} = usecase

	return (
		<div className={CSS.SPECIFIC_USE_CASE}>
			<Helmet>
				<title>{industry} Use Case</title>
			</Helmet>
			<TitleCard title={title} img={img} shortDescription={shortDescription} />
			<ProblemSolution problemComponent={problemComponent} solutionComponent={solutionComponent} />
			<DataPrivacy
				DataPrivacyComponent={DataPrivacyComponent}
				dataPrivacySubtextComponents={dataPrivacySubtextComponents}
			/>
			<Outcomes outcomes={outcomes} />
			<CaseStudiesBlock industry={industry} />
		</div>
	)
}

export default SpecificUseCaseRoute
