import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { CSS } from '../../../utils/domUtils'
import { PATHS } from '../../../utils/paths'
import BackToResources from '../../common/BackToResources'
import YouMightBeInterested from '../../common/YouMightBeInterested'
import { modifiedArticleItems } from '../../data/articles'
import TitleCard from './TitleCard'

const BlogRoute: React.FC = () => (
	<div className={CSS.BLOG}>
		<Helmet>
			<title>Real-World Uses of Fully Homomorphic Encryption</title>
		</Helmet>
		<TitleCard />
		<div className={CSS.LIGHT_BACKGROUND}>
			<div className={CSS.CONTAINER}>
				<div className={CSS.BLOG_CONTENT}>
					<section>
						<div className={CSS.LEAD}>
							It’s widely known that collaborative data sharing can yield highly valuable results - but
							the process can pose a risk to the privacy and security of the information being processed.
							This has implications not only for the safety of business-critical or personally
							identifiable information, but also for compliance with data privacy laws and regulations -
							so protective measures are essential.
						</div>
						<p>
							One of the most promising solutions, which both{' '}
							<b>
								ensures the privacy of sensitive information, and also allows it to be used in a
								valuable way
							</b>
							, is Fully Homomorphic Encryption (FHE) - a leading cryptographic technique that makes it
							possible to <b>perform computations on encrypted data</b> without ever needing to decrypt
							it.
						</p>
						<p>
							While FHE is an established and proven cryptographic concept, implementation is still in its
							early stages in many industries - partly due to issues around processing speed. However, as
							technological advances begin to overcome this barrier and improve the ease of
							implementation, a number of groundbreaking projects have successfully implemented the
							solution - paving the way for secure innovation.
						</p>
					</section>
					<section>
						<h2>Healthcare: Secure Analysis of Patient Data and Genomic Information</h2>
						<p>
							Many healthcare initiatives benefit from collaborative research - pooling information on
							symptoms, treatment and outcomes from multiple healthcare sources, or sharing data with an
							external analysis partner. However, this work is regularly limited by legal and regulatory
							issues with the sharing of sensitive information.
						</p>
						<p>
							FHE is therefore an ideal candidate for improving the efficiency of these efforts, and is
							already in use in{' '}
							<a href={'https://www.ibm.com/downloads/cas/MEG82W8B'} target="_blank" rel="noreferrer">
								IBM’s Healthcare Solutions
							</a>
							, where it enables the{' '}
							<b>secure sharing and analysis of patient data among healthcare providers</b>. This allows
							institutions to collaborate on patient care while maintaining strict privacy protections.
						</p>
						<p>
							Microsoft has also developed{' '}
							<a
								href={'https://www.microsoft.com/en-us/research/project/microsoft-seal/'}
								target="_blank"
								rel="noreferrer"
							>
								SEAL
							</a>{' '}
							- an encryption library powered by homomorphic encryption - which is used in academic and
							research settings to perform <b>privacy-preserving analysis on genomic data</b>. This
							enables researchers to perform computations on encrypted genomic data, ensuring the privacy
							of sensitive genetic information.
						</p>
					</section>
					<section>
						<h2>Manufacturing: Predictive Maintenance and IoT Data Transmission</h2>
						<p>
							One of the most compelling applications for FHE in manufacturing is in predictive
							maintenance, where equipment data is analyzed to predict failures before they happen. Here,
							FHE can be used to{' '}
							<b>securely share encrypted equipment data with maintenance service providers</b>, who could
							then perform the necessary predictive analytics - without ever accessing any
							business-critical or IP information.
						</p>
						<p>
							It is also being explored by{' '}
							<a
								href={
									'https://www.bosch.com/research/research-fields/digitalization-and-connectivity/research-on-security-and-privacy/trustworthy-computing-data-sovereignty-while-connected/'
								}
								target="_blank"
								rel="noreferrer"
							>
								Bosch
							</a>{' '}
							as a key technology to ensure the <b>secure transmission of data within IoT systems</b>. By
							encrypting sensor data and other production information, they can share critical data across
							the supply chain without exposing sensitive details.
						</p>
					</section>
					<section>
						<h2>Telecommunications: Securing Data in 5G Networks</h2>
						<p>
							In telecommunications,{' '}
							<a
								href={
									'https://d1p0gxnqcu0lvz.cloudfront.net/documents/Nokia_leverages_privacy-preserving_technologies_to_protect_personal_data_White_Uv2gD5z.pdf'
								}
								target="_blank"
								rel="noreferrer"
							>
								Nokia
							</a>{' '}
							is assessing potential applications of different Privacy Enhancing Technologies in{' '}
							<b>securing data transfer within 5G networks</b> - including Fully Homomorphic Encryption.
							With vast amounts of personal data transferred on a daily basis, this is an important step
							forward with applications in both present and future next-generation telecom networks.
						</p>
					</section>
					<section>
						<h2>Finance: Credit Scoring and Secure Machine Learning</h2>
						<p>
							In the financial industries, homomorphic encryption is a strong candidate for use in the
							area of{' '}
							<a
								href={
									'https://techhq.com/2023/10/how-can-credit-scoring-with-fully-homomorphic-encryption-improve-data-privacy/'
								}
								target="_blank"
								rel="noreferrer"
							>
								credit scoring
							</a>
							, where it allows institutions to{' '}
							<b>analyze an applicant’s data in order to establish their credit score</b>, while keeping
							all personally identifiable information securely encrypted at all times.
						</p>
						<p>
							IBM has also conducted trials to prove the viability of FHE in{' '}
							<a href={'https://eprint.iacr.org/2019/1113.pdf'} target="_blank" rel="noreferrer">
								training Machine Learning models
							</a>{' '}
							on big data in the financial sector, including a 2020 pilot with Brazilian bank Banco
							Bradesco SA. This project was focused on developing a quantum-resistant solution, leveraging
							the{' '}
							<a
								href={'https://www.helpnetsecurity.com/2024/03/19/quantum-apocalypse/'}
								target="_blank"
								rel="noreferrer"
							>
								quantum-proof
							</a>{' '}
							status of FHE.
						</p>
					</section>
					<section>
						<h2>Government: National Security and E-Voting</h2>
						<p>
							In the USA, DARPA is investing in the development of homomorphic encryption techniques as
							part of their Data Protection in Virtual Environments{' '}
							<a
								href={'https://www.darpa.mil/program/data-protection-in-virtual-environments'}
								target="_blank"
								rel="noreferrer"
							>
								(DPRIVE)
							</a>{' '}
							initiative. FHE is also a strong candidate for supporting the{' '}
							<a href={'https://eprint.iacr.org/2016/776.pdf'} target="_blank" rel="noreferrer">
								creation of secure e-voting systems
							</a>
							, where it could allow for <b>electronic tallying of votes</b> without risking the reveal of
							any individual’s voting choice.
						</p>
					</section>
					<section>
						<h2>Agriculture: Early Experiments in Precision Agriculture</h2>
						<p>
							While still in the early stages of adoption, pilot projects are underway in the agricultural
							sector to implement Fully Homomorphic Encryption in a variety of roles.
						</p>
						<p>
							One of the most promising is{' '}
							<a
								href={
									'https://www.scribd.com/document/671980281/DATA-SECURITY-IN-SMART-PRECISION-AGRICULTURE-RESEARCH-AREAS'
								}
								target="_blank"
								rel="noreferrer"
							>
								Precision Agriculture
							</a>
							, where FHE can be used to{' '}
							<b>
								analyze sensitive data collected from various sources including sensors, drones, and
								satellites
							</b>{' '}
							without exposing any raw information. For example, a farm might use FHE to securely share
							soil data with a service provider that offers tailored advice on fertilizer use, without
							ever revealing the actual data to the provider.
						</p>
					</section>
					<section>
						<h2>What’s next?</h2>
						<p>
							As the processing speed of FHE improves, and its{' '}
							<a
								href={
									'https://iapp.org/news/a/the-latest-in-homomorphic-encryption-a-game-changer-shaping-up'
								}
								target="_blank"
								rel="noreferrer"
							>
								recognition in data protection legislation
							</a>{' '}
							becomes more widespread, more and more industries are recognising its value in a range of
							projects - particularly those where collaborative data sharing is beneficial. With each new
							project paving the way for the next, fully homomorphic encryption looks set to dominate the
							future of data protection solutions, making it possible for organizations to achieve
							otherwise-impossible outcomes while preserving the privacy of sensitive information.
						</p>
					</section>
					<section>
						<p className={CSS.EXTRA_SPACE_BEFORE}>
							<b>
								As part of our data collaboration solutions, xtendr utilizes homomorphic encryption in
								combination with other Privacy Enhancing Technologies to enable secure data access,
								sharing and analysis. <Link to={PATHS.CONTACT}>Contact us</Link> to find out more
							</b>
						</p>
					</section>
				</div>
				<YouMightBeInterested items={modifiedArticleItems} />
				<BackToResources />
			</div>
		</div>
	</div>
)

export default BlogRoute
