import { Helmet } from 'react-helmet'

import { CSS } from '../../utils/domUtils'
import HowPetsWork from './HowPetsWork'
import KeyPrivacyEnhancingTechnologies from './KeyPrivacyEnhancingTechnologies'
import TitleCard from './TitleCard'
import UsefulResources from './UsefulResources'
import WhatBlock from './WhatBlock'
import WhyBlock from './WhyBlock'

const PrivacyEnhancingTechnologyRoute: React.FC = () => (
	<div className={CSS.PRIVACY_ENHANCING_TECHNOLOGIES}>
		<Helmet>
			<title>Privacy Enhancing Technologies</title>
		</Helmet>
		<TitleCard />
		<WhatBlock />
		<WhyBlock />
		<HowPetsWork />
		<KeyPrivacyEnhancingTechnologies />
		<UsefulResources />
	</div>
)

export default PrivacyEnhancingTechnologyRoute
