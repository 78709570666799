import React, { useState } from 'react'

import collaborationProjectImg from '../../resources/img/collaborationProjectImg.png'
import { ReactComponent as ArrowDown } from '../../resources/img/firstSectionArrowDown.svg'
import '../../styles/collaborationPlatform/_titleCard.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import ButtonDark from '../common/ButtonDark'
import ButtonGreen from '../common/ButtonGreen'
import Header from '../common/Header'

const TitleCard: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	return (
		<div className={CSS.COLLABORATION_PLATFORM}>
			<div className={`${CSS.TITLE_CARD} ${CSS.BACKGROUND}`}>
				<div className={CSS.CONTAINER}>
					<Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
					<div className={CSS.COLLAB_FLEX_ROW}>
						<div className={CSS.TITLE_WRAPPER}>
							<h1 className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>
								The xtendr Data Collaboration Platform
							</h1>
							<div className={`${CSS.IMG_WRAPPER} ${CSS.DISPLAY_SMALLSCREEN}`}>
								<img src={collaborationProjectImg} alt={'The xtendr Data Collaboration Platform'} />
							</div>
							<div className={CSS.CARD_SUBTITLE}>
								<b>Accessible, adaptable and secure,</b> the xtendr Collaboration Platform is designed
								for streamlined collaboration and powerful results.
							</div>
						</div>
						<div className={`${CSS.FLEX_COLUMN} ${CSS.DISPLAY_BIGSCREEN}`}>
							<div className={CSS.IMG_WRAPPER}>
								<img src={collaborationProjectImg} alt={'collaboration platform UI example'} />
							</div>
							<div className={CSS.TRY_DEMO}>
								<ButtonGreen
									buttonText={'TRY THE DEMO'}
									extraClasses={CSS.DISPLAY_BIGSCREEN}
									to={PATHS.DEMO}
								/>
							</div>
						</div>
						<ButtonDark
							buttonText={'TRY THE DEMO'}
							extraClasses={`${CSS.DISPLAY_SMALLSCREEN} ${CSS.OUR_PLATFORM_BUTTON}`}
							to={PATHS.DEMO}
						/>
					</div>
					<div className={CSS.TITLE_ARROW}>
						<ArrowDown />
					</div>
				</div>
			</div>
		</div>
	)
}

export default TitleCard
