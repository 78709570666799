import { Helmet } from 'react-helmet'

import { CSS } from '../../../utils/domUtils'
import BackToResources from '../../common/BackToResources'
import YouMightBeInterested from '../../common/YouMightBeInterested'
import { modifiedArticleItems } from '../../data/articles'
import TitleCard from './TitleCard'

const Blog4Route: React.FC = () => (
	<div className={CSS.BLOG}>
		<Helmet>
			<title>A Multiparty Commutative Hashing Protocol based on the Discrete Logarithm Problem</title>
		</Helmet>
		<TitleCard />
		<div className={CSS.LIGHT_BACKGROUND}>
			<div className={CSS.CONTAINER}>
				<div className={CSS.BLOG_CONTENT}>
					<section>
						<div className={CSS.HEADING_7}>
							Published in Computer Science & Information Technology (CS & IT), ISSN : 2231 - 5403, Volume
							13, Number 21, November 2023.
						</div>
						<div className={CSS.HEADING_7}>
							Presented at the 3rd international Conference on Cryptography and Blockchain (CRBL2023),
							2023.
						</div>
						<div className={`${CSS.LEAD} ${CSS.EXTRA_SPACE_BEFORE}`}>
							This paper formalizes a key area of xtendr’s cryptographic research, dedicated to developing
							a streamlined cryptographic solution which retains data utility during analysis, while
							achieving fully legally compliant protection of sensitive data through anonymization.
						</div>
						<p>
							In the paper, xtendr’s team of cryptographers propose a protocol - based on the Discrete
							Logarithm Problem, and built around the Chaum-van Heijst-Pfitzmann hash function - that
							allows multiple parties to anonymize sensitive data in a collaborative way, while ensuring
							that no participant can see any sensitive information in unprotected form.
						</p>
						<p>
							It also allows the detection of cases where multiple different participants share the same
							plaintext data, without any party knowing what that plaintext is - so that analysis can
							detect overlapping information and identify trends and patterns in shared data, without
							exposing sensitive data.
						</p>
						<p>
							Unlike common anonymization techniques such as k-anonymity, l-diversity and differential
							privacy, this protocol does not cause significant distortion in the data - therefore
							enabling Private Set Intersection-style operations to be performed.
						</p>
						<p>
							The paper also proposes a threshold protocol, which would allow the protocol to be
							successfully run by a subset of participants while preserving its privacy.
						</p>
					</section>
					<section>
						<p>
							Read the full paper&nbsp;
							<a
								href={'https://aircconline.com/csit/papers/vol13/csit132110.pdf'}
								target="_blank"
								rel="noreferrer"
							>
								here
							</a>
							.
						</p>
					</section>
				</div>
				<YouMightBeInterested items={modifiedArticleItems} />
				<BackToResources />
			</div>
		</div>
	</div>
)

export default Blog4Route
