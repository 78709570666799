import React from 'react'

import '../../styles/howItWorks/_colorBlock.scss'
import { CSS } from '../../utils/domUtils'

const ColorBlock: React.FC = () => (
	<div className={CSS.LIGHT_BACKGROUND}>
		<div className={CSS.COLOR_BLOCK}>
			<h2 className={CSS.COLOR_BLOCK_TITLE}>
				At xtendr, we put data privacy first - making sure that{' '}
				<span>personal and sensitive data is protected at all times</span>, while still enabling collaborations
				to produce high value results.
			</h2>
			<div className={CSS.COLOR_BLOCK_SUBTITLE}>Here’s how we do it.</div>
		</div>
	</div>
)

export default ColorBlock
