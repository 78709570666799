import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as LinkedinIcon } from '../../resources/img/linkedinIcon.svg'
import alexAlpek from '../../resources/img/team/AlexAlpek.png'
import andrasSimon from '../../resources/img/team/AndrásSimon.png'
import annaSabransky from '../../resources/img/team/AnnaSabransky.png'
import balintPurece from '../../resources/img/team/BálintPurece.png'
import danielZentai from '../../resources/img/team/DánielZentai.png'
import gergoPetrik from '../../resources/img/team/GergőPetrik.png'
import mihailPlesa from '../../resources/img/team/MihailPlesa.png'
import mirandaOverett from '../../resources/img/team/MirandaOverett.png'
import robinFrot from '../../resources/img/team/RobinFrot.png'
import szentesiKorinna from '../../resources/img/team/SzentesiKorinna.png'
import viktoriaParoczi from '../../resources/img/team/ViktóriaParóczi.png'
import aronBalazs from '../../resources/img/team/ÁronBalázs.png'
import '../../styles/aboutUs/_ourTeam.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import ButtonGreen from '../common/ButtonGreen'

type renderMemberCardProps = {
	photo: string
	link: string
	name: string
	title: string
}

const members = [
	{
		photo: aronBalazs,
		link: PATHS.COMING_SOON,
		name: 'Áron Balázs',
		title: 'CEO',
	},
	{
		photo: andrasSimon,
		link: PATHS.COMING_SOON,
		name: 'András Simon',
		title: 'CTO',
	},
	{
		photo: gergoPetrik,
		link: PATHS.COMING_SOON,
		name: 'Gergő Petrik',
		title: 'CTO',
	},
	{
		photo: mirandaOverett,
		link: PATHS.COMING_SOON,
		name: 'Miranda Overett',
		title: 'Head of Communications & Marketing',
	},
	{
		photo: viktoriaParoczi,
		link: PATHS.COMING_SOON,
		name: 'Viktória Paróczi',
		title: 'Head of Business Development',
	},
	{
		photo: danielZentai,
		link: PATHS.COMING_SOON,
		name: 'Dániel Zentai',
		title: 'Head of Cryptography Research',
	},
	{
		photo: mihailPlesa,
		link: PATHS.COMING_SOON,
		name: 'Mihail Plesa',
		title: 'Research Engineer',
	},
	{
		photo: alexAlpek,
		link: PATHS.COMING_SOON,
		name: 'Alex Alpek',
		title: 'Software Developer',
	},
	{
		photo: robinFrot,
		link: PATHS.COMING_SOON,
		name: 'Robin Frot',
		title: 'Software Developer',
	},
	{
		photo: annaSabransky,
		link: PATHS.COMING_SOON,
		name: 'Anna Sabransky',
		title: 'Software Engineer',
	},
	{
		photo: balintPurece,
		link: PATHS.COMING_SOON,
		name: 'Bálint Purece',
		title: 'Software Engineer',
	},
	{
		photo: szentesiKorinna,
		link: PATHS.COMING_SOON,
		name: 'Szentesi Korinna',
		title: 'Office Manager',
	},
]

const OurTeam: React.FC = () => {
	const renderMemberCard = ({ photo, link, name, title }: renderMemberCardProps) => {
		return (
			<div className={CSS.OUR_TEAM_CARD}>
				<div className={CSS.OUR_TEAM_CARD_PHOTO}>
					<Link to={link} className={CSS.OUR_TEAM_CARD_LINK}>
						<LinkedinIcon />
					</Link>
					<img src={photo} alt="" />
				</div>
				<div className={CSS.OUR_TEAM_CARD_NAME}>{name}</div>
				<div className={CSS.OUR_TEAM_CARD_TITLE}>{title}</div>
			</div>
		)
	}
	return (
		<div className={CSS.OUR_TEAM}>
			<div className={CSS.DARK_CARD}>
				<div className={CSS.CONTAINER}>
					{false && (
						<>
							<div className={CSS.OUR_TEAM_TITLE}>Our team</div>
							<div className={CSS.OUR_TEAM_SUBTITLE}>
								Our fast-growing team is based in Budapest, although you’ll often find us at summits and
								conferences - check our{' '}
								<a target="_blank" href={PATHS.LINKEDIN_JOBS} rel="noreferrer">
									LinkedIn
								</a>{' '}
								to find out where we’ll be next.
							</div>
							<div className={CSS.OUR_TEAM_MEMBERS}>
								{members.map((member, index) => (
									<Fragment key={index}>
										{renderMemberCard({
											photo: member.photo,
											link: member.link,
											name: member.name,
											title: member.title,
										})}
									</Fragment>
								))}
							</div>
						</>
					)}
					<div className={CSS.OUR_TEAM_JOINING_BLOCK}>
						<div className={CSS.OUR_TEAM_JOINING_TITLE}>
							Interested in joining the team? <br />
							Visit our Careers page.
						</div>
						<ButtonGreen buttonText={'careers  →'} to={PATHS.CAREERS} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default OurTeam
