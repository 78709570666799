import React, { useCallback } from 'react'

import '../../styles/privacyEnhancingTechnologies/_usefulResources.scss'
import { CSS } from '../../utils/domUtils'
import YouMightBeInterested from '../common/YouMightBeInterested'
import { modifiedArticleItems } from '../data/articles'

type ItemsProps = {
	label: string
	date?: string
	link: string
	title: string
}

const items = [
	{
		label: 'REPORT',
		date: 'JUNE 2023',
		link: 'https://www.oecd.org/en/publications/emerging-privacy-enhancing-technologies_bf121be4-en.html',
		title: 'OECD Emerging Privacy Enhancing Technologies Current Regulatory And Policy Approaches Report',
	},
	{
		label: 'REPORT',
		date: 'JUNE 2023',
		link: 'https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/data-sharing/privacy-enhancing-technologies/',
		title: 'ICO Privacy-Enhancing Technologies Report',
	},
	{
		label: 'WEBSITE',
		link: 'https://iapp.org/',
		title: 'The International Association of Privacy Professionals (IAPP)',
	},
]

const UsefulResources: React.FC = () => {
	const renderItems = useCallback(({ label, date, link, title }: ItemsProps) => {
		return (
			<a href={link} className={CSS.USEFUL_RESOURCES_ITEM}>
				<div className={CSS.USEFUL_RESOURCES_ITEM_HEADER}>
					<div className={CSS.USEFUL_RESOURCES_ITEM_LABEL}>{label}</div>
					{date && <div className={CSS.USEFUL_RESOURCES_ITEM_DATE}>{date}</div>}
				</div>
				<div className={CSS.USEFUL_RESOURCES_ITEM_TITLE}>{title}</div>
			</a>
		)
	}, [])
	return (
		<div className={CSS.LIGHT_BACKGROUND}>
			<div className={CSS.CONTAINER}>
				<div className={CSS.USEFUL_RESOURCES}>
					<h2 className={CSS.USEFUL_RESOURCES_TITLE}>Useful resources</h2>
					<div className={CSS.USEFUL_RESOURCES_ITEMS}>
						{items.map((item) =>
							renderItems({
								label: item.label,
								date: item?.date,
								link: item.link,
								title: item.title,
							})
						)}
					</div>
				</div>
				<YouMightBeInterested items={modifiedArticleItems} />
			</div>
		</div>
	)
}

export default UsefulResources
