import React from 'react'

import Activities from '../../resources/img/outcomes/activities.svg'
import Audence from '../../resources/img/outcomes/audence.svg'
import Safely from '../../resources/img/outcomes/safely.svg'
import Utilize from '../../resources/img/outcomes/utilize.svg'
import '../../styles/collaborationPlatform/_outcomes.scss'
import { CSS } from '../../utils/domUtils'
import OutcomesCard from './OutcomesCard'

const Outcomes: React.FC = () => {
	return (
		<div className={`${CSS.OUTCOMES_BASE} ${CSS.BACKGROUND}`}>
			<div className={CSS.CONTAINER}>
				<h2 className={CSS.CARD_TITLE}>Outcomes</h2>
				<div className={CSS.FLEX_COLUMN}>
					<div className={CSS.FLEX}>
						<OutcomesCard
							img={Safely}
							titleText={'Safely share data for analysis'}
							listElement={
								<ul>
									<li>Securely share data with analytics service providers</li>
									<li>Achieve otherwise-impossible analysis</li>
								</ul>
							}
						/>
						<OutcomesCard
							img={Activities}
							titleText={'Detect overlapping activities'}
							listElement={
								<ul>
									<li>Validate and detect trends in shared users</li>
									<li>Highlight high-risk or high-value behaviors</li>
								</ul>
							}
						/>
					</div>
					<div className={CSS.FLEX}>
						<OutcomesCard
							img={Audence}
							titleText={'Detect overlapping audiences'}
							listElement={
								<ul>
									<li>Gain deeper audience insights</li>
									<li>Assess the value of potential partnerships</li>
								</ul>
							}
						/>
						<OutcomesCard
							img={Utilize}
							titleText={'Utilize more - and more diverse - data'}
							listElement={
								<ul>
									<li>Conduct more comprehensive research</li>
									<li>Train more accurate ML models</li>
								</ul>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Outcomes
