import { Helmet } from 'react-helmet'

import { CSS } from '../../utils/domUtils'
import OurTeam from './OurTeam'
import OurValues from './OurValues'
import TitleCard from './TitleCard'
import WhyUs from './WhyUs'

const AboutUsRoute: React.FC = () => (
	<div className={CSS.ABOUT_US}>
		<Helmet>
			<title>About us</title>
		</Helmet>
		<TitleCard />
		<OurValues />
		<WhyUs />
		<OurTeam />
	</div>
)

export default AboutUsRoute
