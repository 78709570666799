import React from 'react'

import '../../styles/homePage/_discoverCard.scss'
import { CSS } from '../../utils/domUtils'
import ListItemWithArrow from '../common/ListItemWithArrow'

const listItems = [
	'More accurate and comprehensive research outcomes',
	'Detection of new trends & validation of existing patterns',
	'In-depth mapping of behaviours and processes',
	'Enhanced audience segmentation and insights',
	'Improved Machine Learning outcomes',
]

const DiscoverCard: React.FC = () => (
	<div className={`${CSS.DISCOVER_CARD} ${CSS.BACKGROUND} ${CSS.DARK_CARD}`}>
		<div className={CSS.CONTAINER}>
			<h2 className={CSS.CARD_TITLE}>Discover patterns, enhance insights and improve outcomes</h2>
			<div className={CSS.CARD_SUBTITLE}>
				Gain deeper business insights and achieve real-world results including:
			</div>
			<div className={CSS.DISCOVER_CARD_LIST}>
				{listItems.map((item, index) => (
					<ListItemWithArrow
						key={index}
						text={item}
						optionalClassName={index === listItems.length - 1 ? CSS.LAST : undefined}
					/>
				))}
			</div>
		</div>
	</div>
)

export default DiscoverCard
