import React from 'react'
import { Helmet } from 'react-helmet'

import '../../styles/_homePage.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import GreenLink from '../common/GreenLink'
import CollaborationCard from './CollaborationCard'
import DiscoverCard from './DiscoverCard'
import Partners from './Partners'
import SecureDataCard from './SecureDataCard'
import TitleCard from './TitleCard'

const HomepageRoute: React.FC = () => (
	<div className={CSS.HOME_PAGE_ROUTE}>
		<Helmet>
			<title>Connecting data, protecting privacy</title>
		</Helmet>
		<TitleCard />
		<SecureDataCard />
		<GreenLink
			title="How we protect data"
			route={PATHS.HOW_IT_WORKS}
			margin={window.innerWidth > 1800 ? '36vw' : '27%'}
		/>
		<DiscoverCard />
		<CollaborationCard />
		<Partners />
	</div>
)

export default HomepageRoute
