import blog1 from '../../resources/img/blog/illustrations/blog1.jpg'
import blog3 from '../../resources/img/blog/illustrations/blog3.jpg'
import blog5 from '../../resources/img/blog/illustrations/blog5.jpg'
import blog4 from '../../resources/img/howItWorksImg1.png'
import blog2 from '../../resources/img/idealBlock.svg'
import { PATHS } from '../../utils/paths'
import { RenderItemsProps, labels } from '../common/YouMightBeInterested'
import contents from './contents'

type articleType = {
	id: string
	picture: string
	label: labels
	date: string
	title: string
	description: string
	link: string
}

const articleItems: articleType[] = [
	{
		id: 'blog1',
		picture: blog1,
		label: labels.ARTICLE,
		date: '10/08/2024',
		title: 'Secure Access to Sensitive Data: The Real-World Uses of Fully Homomorphic Encryption',
		description:
			'Implementation of Fully Homomorphic Encryption is still in the early stages, but a few pioneering organizations are already putting it to use. Here’s how and why they’re doing it.',
		link: `${PATHS.BLOG}${PATHS.BLOG1_ROUTE}`,
	},
	{
		id: 'blog2',
		picture: blog2,
		label: labels.PAPER,
		date: '08/08/2024',
		title: 'Paper summary: A Non-interactive Threshold Hashing Protocol with Shamir’s Secret Sharing',
		description:
			'In our recent paper ‘A Non-interactive Threshold Hashing Protocol with Shamir’s Secret Sharing’, xtendr’s cryptographers Robin Frot and Daniel Zentai propose a threshold protocol for the anonymization of data records.',
		link: `${PATHS.BLOG}${PATHS.BLOG2_ROUTE}`,
	},
	{
		id: 'blog3',
		picture: blog3,
		label: labels.ARTICLE,
		date: '10/08/2024',
		title: 'Protecting Privacy in AI Model Training: How Privacy Enhancing Technologies Keep Data Safe',
		description:
			'As the use of AI becomes increasingly widespread, important questions are being asked about how to maintain the privacy of training data. Here’s how Privacy Enhancing Technologies can help.',
		link: `${PATHS.BLOG}${PATHS.BLOG3_ROUTE}`,
	},
	{
		id: 'blog4',
		picture: blog4,
		label: labels.PAPER,
		date: '09/08/2024',
		title: 'Paper Summary: A Multiparty Commutative Hashing Protocol based on the Discrete Logarithm Problem',
		description:
			'This paper formalizes a key area of xtendr’s cryptographic research, dedicated to developing a streamlined cryptographic solution which retains data utility during analysis, while achieving fully legally compliant protection of sensitive data through anonymization.',
		link: `${PATHS.BLOG}${PATHS.BLOG4_ROUTE}`,
	},
	{
		id: 'blog5',
		picture: blog5,
		label: labels.ARTICLE,
		date: '09/08/2024',
		title: 'The Power of Collaboration: How Data Sharing is Changing Industries',
		description:
			'More data means deeper insights - here’s how collaborative data sharing is being used today to achieve real-world results.',
		link: `${PATHS.BLOG}${PATHS.BLOG5_ROUTE}`,
	},
]

const generateDynamicArticleItems = (articles: articleType[]): RenderItemsProps[] => {
	return articles.map((article) => ({
		...article,
		content: contents[article.id],
	}))
}

export const modifiedArticleItems = generateDynamicArticleItems(articleItems)
