import React from 'react'

import '../../styles/customCollaborationSolutions/_customizations.scss'
import { CSS } from '../../utils/domUtils'
import ListItemWithArrow from '../common/ListItemWithArrow'

const listItems = [
	'Developing advanced query types to meet project needs',
	'Implementing Privacy Preserving Machine Learning methods',
	'Changes to system architecture',
	'Implementing specific privacy techniques to meet legal or regulatory requirements',
	'Integration of the platform into existing systems',
	'More tailored processes and outcomes',
]

const Customizations: React.FC = () => (
	<div className={`${CSS.CUSTOMIZATIONS} ${CSS.BACKGROUND} ${CSS.DARK_CARD}`}>
		<div className={CSS.CONTAINER}>
			<h2 className={CSS.CUSTOMIZATIONS_TITLE}>Customizations include</h2>
			<div className={CSS.CUSTOMIZATIONS_LIST}>
				{listItems.map((item, index) => (
					<ListItemWithArrow
						key={index}
						text={item}
						optionalClassName={index === listItems.length - 1 ? CSS.LAST : undefined}
					/>
				))}
			</div>
		</div>
	</div>
)

export default Customizations
