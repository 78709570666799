import React from 'react'

import '../../styles/customCollaborationSolutions/_securityBlock.scss'
import { CSS } from '../../utils/domUtils'

const SecurityBlock: React.FC = () => (
	<div className={CSS.SECURITY_BLOCK}>
		<div className={CSS.CONTAINER}>
			<h2 className={CSS.SECURITY_TEXT}>
				Some collaborations require a purpose-built solution, which is why we offer a{' '}
				<span>fully customizable approach</span>, designed to meet specific project and client needs.
			</h2>
			<div className={CSS.SECURITY_SUBTEXT}>
				Developed through close cooperation with our clients, our customizable solutions combine xtendr’s core
				cryptographic techniques with the opportunity to implement and develop a wider range of features,{' '}
				<b>specifically created to solve the needs of a project or industry.</b>
			</div>
		</div>
	</div>
)

export default SecurityBlock
