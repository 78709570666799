import { SelectChangeEvent } from '@mui/material'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { useEmailSubmit } from '../../hooks/useEmailSubmit'
import fullLaptop from '../../resources/img/fullLaptop.png'
import '../../styles/_contact.scss'
import '../../styles/demo/_rest.scss'
import { EmailSeverity, SnackBarSeverity } from '../../utils/const'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import ButtonDark from '../common/ButtonDark'
import EmailSnackbarComponent from '../common/EmailSnackBarComponent'
import CustomCheckboxes from '../common/formComponents/CustomCheckboxes'
import CustomInput from '../common/formComponents/CustomInput'
import CustomSelect from '../common/formComponents/CustomSelect'

const industryOptions = [
	{
		value: 'healthcare',
		option: 'Healthcare',
	},
	{
		value: 'banking',
		option: 'Banking',
	},
	{
		value: 'insurance',
		option: 'Insurance',
	},
	{
		value: 'public-sector',
		option: 'Public Sector',
	},
	{
		value: 'academia',
		option: 'Academia',
	},
	{
		value: 'it',
		option: 'IT',
	},
	{
		value: 'marketing',
		option: 'Marketing',
	},
	{
		value: 'manufacturing',
		option: 'Manufacturing',
	},
	{
		value: 'commerce',
		option: 'Commerce',
	},
	{
		value: 'agriculture',
		option: 'Agriculture',
	},
	{
		value: 'entertainment-media',
		option: 'Entertainment & Media',
	},
	{
		value: 'telecommunications',
		option: 'Telecommunications',
	},
	{
		value: 'other',
		option: 'Other',
	},
]

const roleOptions = [
	{
		value: 'it',
		option: 'IT',
	},
	{
		value: 'business-development',
		option: 'Business Development',
	},
	{
		value: 'project-management',
		option: 'Project Management',
	},
	{
		value: 'data-science',
		option: 'Data science',
	},
	{
		value: 'programming-development',
		option: 'Programming / Development',
	},
	{
		value: 'innovation-transformation',
		option: 'Innovation / Transformation',
	},
	{
		value: 'industry-specialist',
		option: 'Industry Specialist',
	},
	{
		value: 'marketing',
		option: 'Marketing',
	},
	{
		value: 'c-level',
		option: 'C-Level',
	},
	{
		value: 'other',
		option: 'Other',
	},
]

const Rest: React.FC = () => {
	const formRef = useRef<HTMLFormElement>(null)
	const [selectedOptions, setSelectedOptions] = useState({
		industry: '',
		role: '',
	})

	const handleSubmit = useEmailSubmit()

	const [snackbarState, setSnackbarState] = useState({
		open: false,
		message: '',
		severity: SnackBarSeverity.SUCCESS as EmailSeverity,
	})

	const handleSnackbarClose = () => {
		setSnackbarState({ ...snackbarState, open: false })
	}

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const result = await handleSubmit(event)
		setSnackbarState({
			open: true,
			message: result.message,
			severity: result.success ? SnackBarSeverity.SUCCESS : SnackBarSeverity.ERROR,
		})
	}

	const handleSelectChange = (event: SelectChangeEvent<string>) => {
		setSelectedOptions({
			...selectedOptions,
			[event.target.name]: event.target.value,
		})
	}

	return (
		<div className={CSS.LIGHT_BACKGROUND}>
			<div className={CSS.CONTAINER}>
				<div className={CSS.IMG_WRAPPER}>
					<img className={CSS.IMG} src={fullLaptop} alt={'Collaboration platform screenshot'} />
				</div>
				<h2 className={CSS.COMMITED}>
					At xtendr, we’re committed to transparency about our processes, and our solutions. Which is why we
					invite everyone to explore the{' '}
					<Link to={PATHS.COLLABORATION_PLATFORM}>xtendr Collaboration Platform</Link> via our demo.
				</h2>
				<div className={CSS.DEMO_DESCRIPTION}>
					Just fill out the form below, and you can get started with a few clicks.
				</div>
				<div className={CSS.DEMO_FORM}>
					<form onSubmit={onSubmit} ref={formRef}>
						<div className={CSS.DEMO_LIST}>
							<CustomInput label="FIRST NAME" name="firstName" />
							<CustomInput label="LAST NAME" name="lastName" />
							<CustomInput label="EMAIL ADDRESS" type="email" name="email" />
							<CustomInput label="COMPANY" name="company" />
							<CustomSelect
								label="INDUSTRY"
								id="industry"
								menuItems={industryOptions}
								onChange={handleSelectChange}
								name="industry"
							/>
							<CustomSelect
								label="ROLE"
								id="role"
								menuItems={roleOptions}
								onChange={handleSelectChange}
								name="role"
							/>
						</div>
						<div className={CSS.DEMO_CHECKBOXES}>
							<span>Would you like a guided tour? *</span>
							<CustomCheckboxes />
						</div>
						<button type="submit" className={CSS.DEMO_SUBMIT}>
							SUBMIT
						</button>
					</form>
				</div>
				<div className={CSS.DEMO_UNDERFORM_TEXT}>
					* Alternatively, if you’d like one of our team to give you the guided tour, check the box and
					someone will be in touch to set up a time.
				</div>
				<div className={CSS.DEMO_GET_IN_TOUCH}>
					<span>
						If you’ve got any questions about the demo, or about anything else, feel free to contact us at
						any time.
					</span>
					<ButtonDark buttonText={'GET IN TOUCH'} to={PATHS.CONTACT} />
				</div>
			</div>
			<EmailSnackbarComponent
				open={snackbarState.open}
				message={snackbarState.message}
				severity={snackbarState.severity}
				onClose={handleSnackbarClose}
			/>
		</div>
	)
}

export default Rest
