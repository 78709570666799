import React from 'react'

import '../../styles/privacyEnhancingTechnologies/_whyBlock.scss'
import { CSS } from '../../utils/domUtils'
import NumberAccordion from './NumberAccordion'

const items = [
	{
		number: 73,
		title: 'of business data is not being used',
		isPercent: true,
		text: (
			<p>
				Data helps organizations to shape processes, understand audiences, and improve results - but according
				to a Forrester study, <span>between 60% and 73% of business data is not being analyzed</span>.
			</p>
		),
		width: window.innerWidth > 700 ? 90 : 100,
	},
	{
		number: 160,
		title: 'countries currently have data protection laws',
		text: (
			<p>
				As of January 2024, 160 countries and territories worldwide have{' '}
				<a href={'https://papers.ssrn.com/sol3/papers.cfm?abstract_id=1951416'}>data protection laws</a> in
				place.
			</p>
		),
		width: window.innerWidth > 700 ? 85 : 90,
	},
	{
		number: 64,
		title: 'of consumers have chosen not to work with a business due to privacy concerns',
		isPercent: true,
		text: (
			<p>
				As awareness and understanding of data privacy risks becomes more widespread, many consumers are
				stepping away from companies that don’t display commitment to data protection.
			</p>
		),
		width: window.innerWidth > 700 ? 83 : 90,
	},
	{
		number: 60,
		title: 'of businesses are predicted to use at least one PET by 2025',
		isPercent: true,
		text: (
			<p>
				Increasingly recommended as a tool for complying with data protection laws, use of PETs is growing fast.
			</p>
		),
		width: window.innerWidth > 700 ? 80 : 90,
	},
]

const WhyBlock: React.FC = () => {
	return (
		<div className={`${CSS.BACKGROUND} ${CSS.DARK_CARD}`}>
			<div className={`${CSS.CONTAINER} ${CSS.PRIVACY_ENHANCING_TECHNOLOGIES_WHY_CONTAINER}`}>
				<div className={CSS.PRIVACY_ENHANCING_TECHNOLOGIES_WHY_BLOCK}>
					<h3 className={CSS.PRIVACY_ENHANCING_TECHNOLOGIES_QUESTION}>Why use PETs?</h3>
					<div className={CSS.FLEX_COLUMN}>
						{items.map((item, index) => (
							<NumberAccordion
								key={index}
								number={item.number}
								title={item.title}
								text={item.text}
								isPercent={item.isPercent}
								width={item.width}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default WhyBlock
