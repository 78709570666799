import React from 'react'

import '../../styles/industryOverview/_powerBlock.scss'
import { CSS } from '../../utils/domUtils'
import { IndustryOverviewType } from './IndustryText'

const PowerBlock: React.FC<Partial<IndustryOverviewType>> = ({ StatementOne, StatementTwo }) => (
	<div className={CSS.LIGHT_BACKGROUND}>
		<div className={CSS.CONTAINER}>
			<div className={CSS.POWER_BLOCK}>
				<h2 className={CSS.POWER_TITLE}>{StatementOne && <StatementOne />}</h2>
				<div className={CSS.POWER_SUBTITLE}>{StatementTwo && <StatementTwo />}</div>
			</div>
		</div>
	</div>
)

export default PowerBlock
