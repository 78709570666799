export const textFieldSX = (multiline: boolean) => ({
	'&.MuiTextField-root': inputSX(multiline),
	'&.MuiFormControl-root': {
		...inputSX(multiline),
		'.MuiSelect-icon': {
			right: '32px',
			top: 'calc(50% - 4px)',
		},
	},
})

const inputSX = (multiline: boolean) => ({
	background: '#ECFFFA',
	borderRadius: multiline ? '32px' : '100px',
	width: 'calc(50% - 17px)',

	'& > *': {
		borderRadius: multiline ? '32px' : '100px',
	},
	'.MuiFormLabel-root, .MuiInputBase-input': {
		fontFamily: 'Degular',
		fontSize: '20px',
		fontWeight: 555,
		lineHeight: '24px',
		letterSpacing: '0.02em',
	},
	'.MuiInputBase-input': {
		padding: '20px 35px',
	},
	'.MuiFormLabel-root': {
		transform: 'translate(35px, 23px) scale(1)',
		color: 'rgba(15, 25, 54, 0.5)',
	},
	'.MuiFormLabel-root.Mui-focused, .MuiFormLabel-filled': {
		color: '#00C495',
		top: '-30px',
		transform: 'none',
	},
	'.MuiInputBase-root': {
		fieldset: {
			top: 0,
			'& > legend': {
				display: 'none',
			},
		},
	},
	'.MuiInputBase-root.Mui-focused': {
		fieldset: {
			border: '2px solid #00C495',
			borderRadius: multiline ? '32px' : '100px',
		},
	},
	'&:hover': {
		fieldset: {
			border: '2px solid #00C495',
			borderWidth: '2px!important',
		},
	},
})
