import differentialPrivacy from '../../../resources/img/DifferentialPrivacy.png'
import '../../../styles/privacyEnhancingTechnologies/_keyPrivacyEnhancingTechnologies.scss'
import { CSS } from '../../../utils/domUtils'

const DifferentialPrivacy = () => {
	return (
		<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT}>
			<div>
				<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_BLOCK}>
					<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_TITLE}>WHAT DOES IT DO?</div>
					<div>
						Differential privacy is a collection of mathematical techniques which add controlled amounts of
						‘noise’ to raw data in order to distort or change it without losing its value.
					</div>
					<div>
						Depending on the amount of noise added to a dataset, differentially private data can still
						produce highly accurate and valuable results. However, anyone looking at the output of analysis
						conducted on differentially private data would not be able to tell whether a specific
						individual&apos;s data was included in the original dataset or not
					</div>
					<div>
						Differential privacy is mostly used to protect the privacy of the results of computation and
						analysis - specifically, to make it impossible for the original data to be reidentified or
						linked back to individuals by looking at the results.
					</div>
				</div>
				<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_BLOCK}>
					<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_TITLE}>DOES XTENDR USE IT?</div>
					<div>
						Differential privacy is not currently integrated into the xtendr Collaboration Platform -
						however, it can be implemented as part of custom solutions if required.
					</div>
				</div>
			</div>
			<div>
				<div className={CSS.IMG_WRAPPER}>
					<img src={differentialPrivacy} alt={'Differential Privacy'} />
				</div>
			</div>
		</div>
	)
}

export default DifferentialPrivacy
