import '../../styles/howItWorks/_rest.scss'
import { CSS } from '../../utils/domUtils'
import YouMightBeInterested from '../common/YouMightBeInterested'
import { modifiedArticleItems } from '../data/articles'

const Rest: React.FC = () => {
	return (
		<div className={CSS.HOW_IT_WORKS_REST}>
			<div className={CSS.LIGHT_BACKGROUND}>
				<div className={CSS.CONTAINER}>
					<YouMightBeInterested items={modifiedArticleItems} />
				</div>
			</div>
		</div>
	)
}

export default Rest
