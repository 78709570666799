import '../../../styles/privacyEnhancingTechnologies/_keyPrivacyEnhancingTechnologies.scss'
import { CSS } from '../../../utils/domUtils'

const MultipartyCommutativeHashingProtocol = () => {
	return (
		<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT}>
			<div>
				<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_BLOCK}>
					<div>
						The Multiparty Commutative Hashing Protocol is xtendr’s proprietary method for removing all
						directly identifiable information from data in an irreversible way.
					</div>
					<div>
						This method is typically applied to the most sensitive data (as categorized by the data
						controller) so that highly personal or business-critical information can be stripped away.
					</div>
					<div>
						This approach offers a further layer of protection where required, while still maintaining
						maximum utility of the dataset.
					</div>
				</div>
			</div>
			<div>
				<div style={{ maxWidth: '266px' }}>
					<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_TITLE}>DOES XTENDR USE IT?</div>
					<div>Yes. We use the Multiparty Commutative Hashing Protocol in combination with FHE.</div>
				</div>
			</div>
		</div>
	)
}

export default MultipartyCommutativeHashingProtocol
