import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'

type DemoButtonProps = {
	buttonText: string
	extraClasses?: string
}

const DemoButton = ({ buttonText, extraClasses }: DemoButtonProps) => (
	<a href={PATHS.DEMO_LINK} target="_blank" rel="noreferrer">
		<button className={`${CSS.BUTTON_GREEN} ${extraClasses}`}>
			<div>{buttonText}</div>
		</button>
	</a>
)

export default DemoButton
