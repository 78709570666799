import React from 'react'
import { Helmet } from 'react-helmet'

import '../../styles/collaborationPlatform/_common.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import GreenLink from '../common/GreenLink'
import CombiningCard from './CombiningCard'
import DemoCard from './DemoCard'
import Features from './Features'
import HowItWorks from './HowItWorks'
import Outcomes from './Outcomes'
import TitleCard from './TitleCard'

const CollaborationPlatformRoute: React.FC = () => (
	<>
		<Helmet>
			<title>The xtendr Data Collaboration Platform</title>
		</Helmet>
		<TitleCard />
		<div className={CSS.LIGHT_BACKGROUND}>
			<CombiningCard />
			<HowItWorks />
			<GreenLink
				title="How we protect data"
				route={PATHS.HOW_IT_WORKS}
				margin={window.innerWidth > 1800 ? '33%' : '25%'}
			/>
			<Features />
			<Outcomes />
			<DemoCard />
		</div>
	</>
)

export default CollaborationPlatformRoute
