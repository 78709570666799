import React from 'react'

import industryImg from '../../resources/img/industry-block.svg'
import '../../styles/industryOverview/_industryOutcomes.scss'
import { CSS } from '../../utils/domUtils'
import { IndustryOverviewType } from './IndustryText'

const IndustryOutcomes: React.FC<Partial<IndustryOverviewType>> = ({ Description, outcomes }) => (
	<div className={CSS.INDUSTRY_OUTCOMES}>
		<div className={CSS.INDUSTRY_BLOCK}>
			<div className={CSS.IMG_WRAPPER}>
				<img src={industryImg} alt={'Industry outcomes'} />
			</div>
			<div className={CSS.INDUSTRY_DESCRIPTION}>
				<div>{Description && <Description />}</div>
			</div>
		</div>
		<div className={CSS.CONTAINER}>
			<div className={CSS.INDUSTRY_OUTCOMES_BLOCK}>
				<h3 className={CSS.INDUSTRY_OUTCOMES_SUBTITLE}>Outcomes</h3>
				<div className={CSS.INDUSTRY_OUTCOMES_LIST}>
					{outcomes &&
						outcomes.map((outcome, index) => (
							<div key={index} className={CSS.INDUSTRY_OUTCOMES_LIST_ITEM}>
								<div>→</div>
								{outcome}
							</div>
						))}
				</div>
			</div>
		</div>
	</div>
)

export default IndustryOutcomes
