import React from 'react'

import financeIndustry from '../../resources/img/caseStudies/financeIndustry.jpg'
import healthcareIndustry from '../../resources/img/caseStudies/healthcareIndustry.jpg'
import manufacturingIndustry from '../../resources/img/caseStudies/manufacturingIndustry.jpg'

export type IndustryOverviewType = {
	industry: string
	img: string
	title: string
	shortDescription: string
	StatementOne: React.FC
	StatementTwo: React.FC
	Description: React.FC
	outcomes: string[]
	link: string
}

export const healthOverviewData: IndustryOverviewType = {
	industry: 'Healthcare',
	img: healthcareIndustry,
	title: 'Improve healthcare research, diagnostics & patient outcomes',
	shortDescription: 'with secure data sharing, collaboration and privacy preserving machine learning.',
	StatementOne: () => (
		<>
			Healthcare <u>data has the power</u> to improve medical processes, patient care, and HealthTech development.
		</>
	),
	StatementTwo: () => (
		<>
			However, since so much medical data contains sensitive information, privacy concerns leave{' '}
			<b>many organizations feeling unable to leverage it.</b>
		</>
	),
	Description: () => (
		<>
			Using our secure data sharing and collaboration techniques, secured by a range of Privacy Enhancing
			Technologies, <b>xtendr enables healthcare organizations to protect the privacy of their data</b> while it
			is being shared, maintaining legal compliance and ethical responsibility while still gaining valuable
			insights.
		</>
	),
	outcomes: [
		'Maximize the value of unused data',
		'Control the privacy of data during sharing and analysis',
		'Utilize more data for research and clinical trials',
		'Safely share data with external analytics solution providers',
		'Improve the privacy & prediction accuracy of ML models',
	],
	link: '/healthcare',
}

export const financeOverviewData: IndustryOverviewType = {
	industry: 'Finance',
	img: financeIndustry,
	title: 'Enhance financial decision-making & risk management',
	shortDescription: 'with secure data sharing, collaboration, and privacy-preserving analytics.',
	StatementOne: () => (
		<>
			Financial <u>data has the potential</u> to revolutionize risk assessment, decision making processes and
			financial product innovation.
		</>
	),
	StatementTwo: () => (
		<>
			However, due to the sensitive nature of financial information,{' '}
			<b>many organizations struggle to utilize it fully while ensuring privacy and compliance.</b>
		</>
	),
	Description: () => (
		<>
			Using our secure data sharing and collaboration solutions, powered by Fully Homomorphic Encryption,{' '}
			<b>xtendr enables financial institutions to protect sensitive data during analysis and sharing.</b> This
			ensures legal compliance and ethical responsibility, while still extracting critical insights for better
			decision-making and risk management.
		</>
	),
	outcomes: [
		'Unlock the value of siloed financial data',
		'Maintain privacy and compliance during data collaboration',
		'Leverage more data for advanced risk models and analytics',
		'Safely share data with third-party financial service providers',
		'Enhance the accuracy and privacy of financial forecasting models',
	],
	link: '/finance',
}

export const manufacturingOverviewData: IndustryOverviewType = {
	industry: 'Manufacturing',
	img: manufacturingIndustry,
	title: 'Optimize manufacturing efficiency & innovation',
	shortDescription: 'with secure data sharing, collaboration, and privacy-preserving analytics.',
	StatementOne: () => (
		<>
			Manufacturing <u>data can drive significant improvements</u> in operational efficiency, product innovation,
			and supply chain management.
		</>
	),
	StatementTwo: () => (
		<>
			However, the{' '}
			<b>
				sensitive nature of proprietary manufacturing data often limits organizations&apos; ability to
				collaborate and innovate
			</b>{' '}
			without risking intellectual property or competitive advantage.
		</>
	),
	Description: () => (
		<>
			Using our secure data sharing and collaboration solutions, based on Fully Homomorphic Encryption,{' '}
			<b>xtendr enables manufacturing companies to protect their sensitive data</b> during analysis and sharing.
			This ensures that data privacy and intellectual property are protected while gaining actionable insights for
			optimizing operations and driving innovation.
		</>
	),
	outcomes: [
		'Maximize the value of production and supply chain data',
		'Safeguard intellectual property during data collaboration',
		'Improve operational efficiency through data-driven insights',
		'Securely share data with external partners and service providers',
		'Enhance product innovation while protecting sensitive information',
	],
	link: '/manufacturing',
}

export const industryOverviewData = [healthOverviewData, financeOverviewData, manufacturingOverviewData]
