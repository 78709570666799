import React, { useState } from 'react'

import { ReactComponent as ArrowDown } from '../../resources/img/firstSectionArrowDown.svg'
import privacyTitleImg from '../../resources/img/privacyTitleImg.png'
import '../../styles/privacyEnhancingTechnologies/_titleCard.scss'
import { CSS } from '../../utils/domUtils'
import Header from '../common/Header'

const TitleCard: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<>
			<div className={`${CSS.TITLE_CARD} ${CSS.BACKGROUND}`}>
				<div className={CSS.CONTAINER}>
					<Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
					<div className={`${CSS.FLEX_CENTER} ${CSS.FLEX_COLUMN}`}>
						<h1 className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>
							Privacy Enhancing Technologies (PETs)
						</h1>
						<div className={CSS.CARD_TEXT}>
							What are privacy enhancing technologies, and why do they matter?
						</div>
					</div>

					<div className={CSS.TITLE_ARROW}>
						<ArrowDown />
					</div>
				</div>
			</div>
			<div className={CSS.LIGHT_BACKGROUND}>
				<div className={CSS.CONTAINER}>
					<div className={CSS.IMG_WRAPPER}>
						<img className={CSS.IMG} src={privacyTitleImg} alt={'Privacy Enhancing Technologies'} />
					</div>
				</div>
			</div>
		</>
	)
}

export default TitleCard
