import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { TextField } from '@mui/material'
import React, { useState } from 'react'

import { EmailSeverity, SnackBarSeverity, applicationHeader } from '../../utils/const'
import { CSS } from '../../utils/domUtils'
import { textFieldSX } from '../../utils/muiSxHelpers'
import { PATHS } from '../../utils/paths'
import { emailPattern } from '../../utils/validationPatterns'
import EmailSnackbarComponent from '../common/EmailSnackBarComponent'

const NewsLetter = () => {
	const [email, setEmail] = useState('')
	const [snackbarState, setSnackbarState] = useState({
		open: false,
		message: '',
		severity: SnackBarSeverity.SUCCESS as EmailSeverity,
	})
	const updateSnackbarState = (open: boolean, message: string, severity: EmailSeverity) => {
		setSnackbarState({ open, message, severity })
	}
	const handleSnackbarClose = () => {
		setSnackbarState({ ...snackbarState, open: false })
	}

	const onSubscribe = async () => {
		await fetch(PATHS.SUBSCRIBE_LINK, {
			method: 'POST',
			headers: applicationHeader,
			body: JSON.stringify({ email }),
		})
			.then(() => {
				updateSnackbarState(
					true,
					`Thank you! We have sent you a confirmation email - please validate your subscription by following the link. If you don't receive anything, please check your spam folder.`,
					SnackBarSeverity.SUCCESS
				)
				setEmail('')
			})
			.catch(() => {
				updateSnackbarState(
					true,
					`Sorry, we couldn't subscribe you. Please check whether you have entered your email address correctly, or try again later.`,
					SnackBarSeverity.ERROR
				)
			})
	}

	return (
		<div className={CSS.NEWSLETTER}>
			<h2 className={CSS.CARD_TITLE}>Get our updates</h2>
			<div className={CSS.CARD_SUBTITLE}>
				Sign up for our newsletter for new case studies, whitepapers and product and service releases.
			</div>
			<div className={`${CSS.EMAIL_INPUT} ${CSS.FLEX_ROW}`}>
				<TextField
					label={'YOUR EMAIL'}
					variant="outlined"
					value={email}
					onChange={(email) => setEmail(email.target.value)}
					sx={textFieldSX(false)}
				/>
				<button onClick={onSubscribe} id={CSS.SUBSCRIBE_BTN} disabled={!emailPattern.test(email)}>
					<div className={CSS.NEWSLETTER_BUTTON_TEXT}>Subscribe</div>
					<div className={`${CSS.DISPLAY_SMALLSCREEN} ${CSS.NEWSLETTER_BUTTON}`}>
						<ArrowForwardIcon />
					</div>
				</button>
				<EmailSnackbarComponent
					open={snackbarState.open}
					message={snackbarState.message}
					severity={snackbarState.severity}
					onClose={handleSnackbarClose}
				/>
			</div>
		</div>
	)
}

export default NewsLetter
