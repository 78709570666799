import React, { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { ReactComponent as Solutions1 } from '../../resources/img/sideMenu/solutions1.svg'
import { ReactComponent as Solutions2 } from '../../resources/img/sideMenu/solutions2.svg'
import { ReactComponent as Solutions3 } from '../../resources/img/sideMenu/solutions3.svg'
import { ReactComponent as SolutionsSmall1 } from '../../resources/img/sideMenu/solutionsSmall1.svg'
import { ReactComponent as SolutionsSmall2 } from '../../resources/img/sideMenu/solutionsSmall2.svg'
import { ReactComponent as SolutionsSmall3 } from '../../resources/img/sideMenu/solutionsSmall3.svg'
import { ReactComponent as Technology1 } from '../../resources/img/sideMenu/technology1.svg'
import { ReactComponent as Technology2 } from '../../resources/img/sideMenu/technology2.svg'
import { ReactComponent as TechnologySmall1 } from '../../resources/img/sideMenu/technologySmall1.svg'
import { ReactComponent as TechnologySmall2 } from '../../resources/img/sideMenu/technologySmall2.svg'
import { ReactComponent as UseCases1 } from '../../resources/img/sideMenu/useCases1.svg'
import { ReactComponent as UseCases2 } from '../../resources/img/sideMenu/useCases2.svg'
import { ReactComponent as UseCases3 } from '../../resources/img/sideMenu/useCases3.svg'
import { ReactComponent as UseCasesSmall1 } from '../../resources/img/sideMenu/useCasesSmall1.svg'
import { ReactComponent as UseCasesSmall2 } from '../../resources/img/sideMenu/useCasesSmall2.svg'
import { ReactComponent as UseCasesSmall3 } from '../../resources/img/sideMenu/useCasesSmall3.svg'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import IllustrationCard from './IllustrationCard'

type SideMenuProps = {
	setIsMenuOpen: (isOpen: boolean) => void
	isMenuOpen: boolean
	activeItem: number | null
	setActiveItem: (numb: number) => void
}

type IllustrationsProps = {
	isBig: boolean
	items: Array<{ big: FC; small: FC; link: string }>
}

type BigMenuItemProps = {
	text: string
	itemNr: number
	arrowToTop?: boolean
}

const menuItems = [
	{
		id: 1,
		title: 'Solutions',
		arrow: '→',
		photos: [
			{
				big: Solutions1,
				small: SolutionsSmall1,
				link: PATHS.CUSTOM_COLLABORATION_SOLUTIONS,
			},
			{
				big: Solutions2,
				small: SolutionsSmall2,
				link: PATHS.COLLABORATION_PLATFORM,
			},
			{
				big: Solutions3,
				small: SolutionsSmall3,
				link: PATHS.DEMO,
			},
		],
	},
	{
		id: 2,
		title: 'Technology',
		arrow: '→',
		photos: [
			{
				big: Technology1,
				small: TechnologySmall1,
				link: PATHS.PRIVACY_ENHANCING_TECHNOLOGIES,
			},
			{
				big: Technology2,
				small: TechnologySmall2,
				link: PATHS.HOW_IT_WORKS,
			},
		],
	},
	{
		id: 3,
		title: 'Use-cases',
		arrow: '→',
		photos: [
			{
				big: UseCases1,
				small: UseCasesSmall1,
				link: `${PATHS.INDUSTRY_OVERVIEW}${PATHS.FINANCE}`,
			},
			{
				big: UseCases2,
				small: UseCasesSmall2,
				link: `${PATHS.INDUSTRY_OVERVIEW}${PATHS.HEALTHCARE}`,
			},
			{
				big: UseCases3,
				small: UseCasesSmall3,
				link: `${PATHS.INDUSTRY_OVERVIEW}${PATHS.MANUFACTURING}`,
			},
		],
	},
	{
		id: 4,
		title: 'Resources',
		arrow: '↗',
		photos: [],
	},
]

const SideMenu = ({ setIsMenuOpen, isMenuOpen, activeItem, setActiveItem }: SideMenuProps) => {
	const navigate = useNavigate()

	const Illustrations = ({ isBig, items }: IllustrationsProps) => (
		<>
			{items.map((item, index) => (
				<IllustrationCard
					key={index}
					illustration={isBig ? item.big : item.small}
					redirectLink={item.link}
					setIsMenuOpen={setIsMenuOpen}
				/>
			))}
		</>
	)

	const BigMenuItem = ({ text, itemNr, arrowToTop = false }: BigMenuItemProps) => (
		<div
			className={`${CSS.MENU_ITEM} ${activeItem === itemNr && CSS.HOVER}`}
			onMouseOver={() => setActiveItem(arrowToTop ? 0 : itemNr)}
			onClick={() => arrowToTop && navigate(PATHS.RESOURCES)}
		>
			{text}
			<div className={CSS.ARROW_ICON}>{arrowToTop ? ' ↗' : ' →'}</div>
		</div>
	)

	return (
		<div className={`${CSS.SIDE_MENU} ${isMenuOpen && CSS.MENU_OPEN}`}>
			{' '}
			<div className={`${CSS.CONTAINER} ${CSS.FLEX_COLUMN}`}>
				<div
					className={`${CSS.FLEX_ROW} ${CSS.MENU_WRAPPER} ${CSS.DISPLAY_BIGSCREEN}`}
					onMouseLeave={() => setActiveItem(0)}
				>
					<div className={`${CSS.MENU} ${CSS.FLEX_COLUMN}`}>
						{menuItems.map((item) => (
							<BigMenuItem
								key={item.id}
								text={item.title}
								itemNr={item.id}
								arrowToTop={item.arrow === '↗'}
							/>
						))}
					</div>
					{menuItems
						.filter((item) => activeItem === item.id && item.photos.length > 0)
						.map((item) => (
							<div
								className={`${CSS.ILLUSTRATIONS_WRAPPER} ${CSS.ILLUSTRATIONS_WRAPPER}-${item.id}`}
								key={item.id}
							>
								<Illustrations isBig={true} items={item.photos} />
							</div>
						))}
				</div>
				<div
					className={`${CSS.MENU_WRAPPER} ${CSS.DISPLAY_MIDDLESCREEN}`}
					onMouseLeave={() => setActiveItem(0)}
				>
					<div className={`${CSS.MENU} ${CSS.FLEX_COLUMN}`}>
						{menuItems.map((item) => (
							<div
								key={item.id}
								className={`${CSS.MENU_ITEM} ${activeItem === item.id && CSS.HOVER}`}
								onMouseOver={() => setActiveItem(item.id)}
								onClick={() => item.arrow === '↗' && navigate(PATHS.RESOURCES)}
							>
								{item.title}
								<div className={CSS.ARROW_ICON}>{` ${item.arrow}`}</div>
							</div>
						))}
					</div>
					{menuItems
						.filter((item) => activeItem === item.id && item.photos.length > 0)
						.map((item) => (
							<div
								className={`${CSS.ILLUSTRATIONS_WRAPPER} ${CSS.ILLUSTRATIONS_WRAPPER}-${item.id}`}
								key={item.id}
							>
								<div className={CSS.ILLUSTRATION_INNER_WRAPPER}>
									<Illustrations isBig={true} items={item.photos} />
								</div>
							</div>
						))}
				</div>
				{activeItem === 0 && (
					<div className={`${CSS.MENU_WRAPPER} ${CSS.DISPLAY_SMALLSCREEN}`}>
						{menuItems.map((item) => (
							<div
								key={item.id}
								className={CSS.MENU_ITEM}
								onClick={() =>
									item.arrow === '↗' ? navigate(PATHS.RESOURCES) : setActiveItem(item.id)
								}
							>{`${item.title} ${item.arrow}`}</div>
						))}
						<Link to={PATHS.CONTACT} className={CSS.CONTACT_LINK}>
							Contact us ↗
						</Link>
					</div>
				)}
				{menuItems
					.filter((item) => activeItem === item.id && item.photos.length > 0)
					.map((item) => (
						<div className={`${CSS.SUBMENU_WRAPPER} ${CSS.DISPLAY_SMALLSCREEN}`} key={item.id}>
							<div
								className={CSS.MENU_ITEM_ACTIVE}
								onClick={() => setActiveItem(0)}
							>{`← ${item.title}`}</div>
							<div className={`${CSS.ILLUSTRATIONS_WRAPPER} ${CSS.ILLUSTRATIONS_WRAPPER}-${item.id}`}>
								<Illustrations isBig={false} items={item.photos} />
							</div>
						</div>
					))}
				<div className={CSS.HEADER_FOOTER}>
					<div className={`${CSS.HEADER_LINKS}`}>
						<Link className={CSS.HEADER_LINK} to={PATHS.FAQ}>
							FAQ
						</Link>
						<Link className={CSS.HEADER_LINK} to={PATHS.ABOUT_US}>
							ABOUT US
						</Link>
						<Link className={CSS.HEADER_LINK} to={PATHS.CAREERS}>
							CAREERS
						</Link>
					</div>
					<a
						className={`${CSS.LINKEDIN_BIGSCREEN}`}
						href={PATHS.LINKEDIN_COMPANY}
						target="_blank"
						rel="noreferrer"
					>
						Linkedin →
					</a>
				</div>
			</div>
		</div>
	)
}

export default SideMenu
