import React from 'react'
import { Helmet } from 'react-helmet'

import '../../styles/collaborationPlatform/_common.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import GreenLink from '../common/GreenLink'
import Customizations from './Customizations'
import IdealBlock from './IdealBlock'
import SecurityBlock from './SecurityBlock'
import TitleCard from './TitleCard'

const CustomCollaborationSolutionsRoute: React.FC = () => (
	<>
		<Helmet>
			<title>Fully customizable collaboration solutions</title>
		</Helmet>
		<TitleCard />
		<div className={CSS.LIGHT_BACKGROUND}>
			<SecurityBlock />
			<Customizations />
			<GreenLink
				route={PATHS.HOW_IT_WORKS}
				title={'How we protect data'}
				margin={window.innerWidth > 1800 ? '36vw' : '25%'}
			/>
			<IdealBlock />
		</div>
	</>
)

export default CustomCollaborationSolutionsRoute
