import { useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

// import '../styles/_pageTransition.scss';
import '../../styles/_pageTransition.scss'

const PageTransition = ({ children }: { children: React.ReactNode }) => {
	const location = useLocation()

	return (
		<TransitionGroup>
			<CSSTransition key={location.key} timeout={300} classNames="page">
				{children}
			</CSSTransition>
		</TransitionGroup>
	)
}

export default PageTransition
