import React, { Fragment, useCallback } from 'react'
import { Link } from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import '../../styles/_caseStudies.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import { IndustryOverviewType, industryOverviewData } from '../useCases/IndustryText'

type useCasePanelProps = {
	industry: string
}

const UseCasesPanel: React.FC<useCasePanelProps> = ({ industry }) => {
	const reorderIndustryData = (industries: IndustryOverviewType[], industry: string): IndustryOverviewType[] => {
		const index = industries.findIndex((item) => item.industry === industry)

		if (index === -1) return industries

		return [industries[index], ...industries.filter((_, i) => i !== index)]
	}

	const reorderedIndustryData = reorderIndustryData(industryOverviewData, industry)

	const renderItems = useCallback(
		({ img, industry, title, Description, link }: IndustryOverviewType) => (
			<div className={CSS.CASE_STUDIES_ITEM}>
				<div className={CSS.CASE_STUDIES_ITEM_PHOTO}>
					<img src={img} alt={title} />
				</div>
				<div className={CSS.CASE_STUDIES_ITEM_BLOCK}>
					<div>
						<h3 className={CSS.INDUSTRY}>{industry}</h3>
						<h3 className={CSS.CASE_STUDIES_ITEM_TITLE}>{title}</h3>
						<div className={CSS.CASE_STUDIES_ITEM_DESCRIPTION}>{Description && <Description />}</div>
					</div>
					<Link to={`${PATHS.USE_CASES}${link}`} className={CSS.CASE_STUDIES_ITEM_LINK}>
						Go to use case →
					</Link>
				</div>
			</div>
		),
		[]
	)

	return (
		<div className={CSS.CONTAINER}>
			<div className={CSS.CASE_STUDIES}>
				<h2 className={CSS.CASE_STUDIES_TITLE}>Use cases</h2>
				{window.innerWidth >= 900 ? (
					<Swiper
						pagination={{
							type: 'fraction',
						}}
						navigation={true}
						modules={[Pagination, Navigation]}
						className={CSS.CASE_STUDIES_SWIPER}
					>
						{reorderedIndustryData.map((item: IndustryOverviewType, index: number) => (
							<SwiperSlide key={index}>{renderItems(item)}</SwiperSlide>
						))}
					</Swiper>
				) : (
					<div className={CSS.CASE_STUDIES_SWIPER}>
						{reorderedIndustryData.map((item: IndustryOverviewType, index: number) => (
							<Fragment key={index}>{renderItems(item)}</Fragment>
						))}
					</div>
				)}
			</div>
		</div>
	)
}

export default UseCasesPanel
