import '../../styles/specificUseCase/_outcomes.scss'
import { CSS } from '../../utils/domUtils'
import { UsecaseType } from './useCaseTexts'

const Outcomes: React.FC<Partial<UsecaseType>> = ({ outcomes }) => {
	return (
		<div className={`${CSS.DARK_CARD} ${CSS.OUTCOMES}`}>
			<div className={CSS.OUTCOMES_SUBTITLE}>Outcomes</div>
			<div className={CSS.OUTCOMES_TITLE}>{outcomes}</div>
		</div>
	)
}

export default Outcomes
