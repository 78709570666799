import secureMultipartyComputation from '../../../resources/img/SecureMultipartyComputation.png'
import '../../../styles/privacyEnhancingTechnologies/_keyPrivacyEnhancingTechnologies.scss'
import { CSS } from '../../../utils/domUtils'

const SecureMultipartyComputation = () => {
	return (
		<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT}>
			<div>
				<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_BLOCK}>
					<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_TITLE}>WHAT DOES IT DO?</div>
					<div>
						SMPC is a term used to describe a variety of techniques which allow multiple contributors to
						find results of analysis run on their shared data, without any of them having to reveal the data
						that they have contributed.
					</div>
					<div>
						One application of SMPC is to store data in a distributed way - either remaining local to
						participants, or encrypted and randomly distributed between them in such a way that no
						information about its origins can be discovered. This removes the need for a third party to be
						involved in the collaboration process, as data therefore doesn’t need to be stored in a single
						centralized location.
					</div>
					<div>
						Secure multiparty computation can also be used to distribute encryption keys in a protected way.
					</div>
				</div>
				<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_BLOCK}>
					<div className={CSS.KEY_PRIVACY_ENHANCING_TECHNOLOGIES_ITEM_CONTENT_TITLE}>DOES XTENDR USE IT?</div>
					<div>
						SMPC is not currently integrated into the xtendr Collaboration Platform - however, it can be
						implemented as part of custom solutions.
					</div>
				</div>
			</div>
			<div>
				<div className={CSS.IMG_WRAPPER}>
					<img src={secureMultipartyComputation} alt={'Secure Multiparty Computation'} />
				</div>
			</div>
		</div>
	)
}

export default SecureMultipartyComputation
