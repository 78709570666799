import React from 'react'
import { Link } from 'react-router-dom'

import laptopGiantIllustration from '../../resources/img/screenGiant.svg'
import laptopMobileIllustration from '../../resources/img/smallScreen.png'
import '../../styles/homePage/_collaborationCard.scss'
import '../../styles/homePage/_consultation.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import CollaborationCustomizable from './CollaborationCustomizable'

const CollaborationCard: React.FC = () => (
	<div className={`${CSS.COLLABORATION_CARD} ${CSS.BACKGROUND}`}>
		<div className={CSS.LEFT_MARGIN}>
			<div className={`${CSS.CONTAINER} ${CSS.COLLAB_WRAPPER}`}>
				<div className={CSS.FLEX_ROW}>
					<div className={CSS.COLLABORATION_TEXT}>
						<h2 className={CSS.CARD_TITLE}>The xtendr Collaboration Platform</h2>
						<div className={CSS.CARD_SUBTITLE}>
							Designed for simple, streamlined & secure collaboration projects. Featuring{' '}
							<b>pre-set queries</b> and an <b>optional custom SQL query builder</b>.
						</div>
						<div className={CSS.IMG_WRAPPER}>
							<img
								className={CSS.DISPLAY_SMALLSCREEN}
								src={laptopMobileIllustration}
								alt={'Collaboration platform screenshot'}
							/>
						</div>
						<div className={CSS.CARD_SUBTITLE}>
							Easy-to-use adjustable access controls and an accessible interface that{' '}
							<b>doesn’t require programming knowledge to use.</b>
						</div>
						<div className={CSS.CARD_SUBTITLE}>
							Different security configurations available, implemented <b>according to your needs</b>{' '}
							during a fully supported setup phase.
						</div>
						<div className={CSS.TEXT_LINK}>
							<Link to={PATHS.COLLABORATION_PLATFORM}>HOW IT WORKS&nbsp;&nbsp; →</Link>
						</div>
					</div>
					<div className={CSS.LAPTOP_IMG}>
						<img
							className={CSS.DISPLAY_GIANTSCREEN}
							src={laptopGiantIllustration}
							alt={'Collaboration platform screenshot'}
						/>
					</div>
				</div>
			</div>
		</div>
		<CollaborationCustomizable />
		<div className={CSS.LEFT_MARGIN}>
			<div className={`${CSS.CONTAINER} ${CSS.CONSULT_WRAPPER}`}>
				<div className={CSS.CONSULTATION}>
					<h3 className={CSS.CARD_TITLE}>consultation</h3>
					<div className={CSS.CARD_SUBTITLE}>
						Every xtendr project begins with a full consultation{' '}
						<span>to help us understand your collaboration needs and challenges</span>, including advice on
						how collaboration can best work for you.
					</div>
				</div>
			</div>
		</div>
	</div>
)

export default CollaborationCard
