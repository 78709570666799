import React from 'react'
import { Link } from 'react-router-dom'

import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'

const Links = () => {
	const scrollToTop = () => {
		window.scrollTo(0, 0)
	}

	return (
		<>
			<div className={`${CSS.FLEX_ROW} ${CSS.LINKS} ${CSS.DISPLAY_BIGSCREEN}`}>
				<div className={`${CSS.FLEX_COLUMN}`}>
					<Link to={PATHS.COLLABORATION_PLATFORM} onClick={scrollToTop}>
						Collaboration Platform
					</Link>
					<Link to={PATHS.CUSTOM_COLLABORATION_SOLUTIONS} onClick={scrollToTop}>
						Custom Solutions
					</Link>
					<Link to={PATHS.PRIVACY_ENHANCING_TECHNOLOGIES} onClick={scrollToTop}>
						Privacy Enhancing Technologies
					</Link>
				</div>
				<div className={`${CSS.FLEX_COLUMN}`}>
					<Link to={PATHS.HOW_IT_WORKS} onClick={scrollToTop}>
						How it Works
					</Link>
					<Link to={PATHS.RESOURCES} onClick={scrollToTop}>
						Resources
					</Link>
					<Link to={PATHS.FAQ} onClick={scrollToTop}>
						FAQ
					</Link>
					<Link to={PATHS.ABOUT_US} onClick={scrollToTop}>
						About Us
					</Link>
				</div>
				<div className={`${CSS.FLEX_COLUMN}`}>
					<Link to={PATHS.CAREERS} onClick={scrollToTop}>
						Careers{' '}
					</Link>
					<Link to={PATHS.CONTACT} onClick={scrollToTop}>
						Contact
					</Link>
					<a
						className={CSS.LINKEDIN_BIGSCREEN}
						href={PATHS.LINKEDIN_COMPANY}
						target="_blank"
						rel="noreferrer"
					>
						Linkedin →
					</a>
				</div>
			</div>
			<div className={`${CSS.LINKS_SMALLSCREEN}`}>
				<div className={`${CSS.LINK_COLUMN}`}>
					<Link to={PATHS.COLLABORATION_PLATFORM} onClick={scrollToTop}>
						Collaboration Platform
					</Link>
					<Link to={PATHS.CUSTOM_COLLABORATION_SOLUTIONS} onClick={scrollToTop}>
						Custom Solutions
					</Link>
					<Link to={PATHS.PRIVACY_ENHANCING_TECHNOLOGIES} onClick={scrollToTop}>
						Privacy Enhancing Technologies
					</Link>
					<Link to={PATHS.HOW_IT_WORKS} onClick={scrollToTop}>
						How it Works
					</Link>
				</div>
				<div className={`${CSS.LINK_COLUMN}`}>
					<Link to={PATHS.RESOURCES} onClick={scrollToTop}>
						Resources
					</Link>
					<Link to={PATHS.FAQ} onClick={scrollToTop}>
						FAQ
					</Link>
					<Link to={PATHS.ABOUT_US} onClick={scrollToTop}>
						About Us
					</Link>
					<Link to={PATHS.CAREERS} onClick={scrollToTop}>
						Careers{' '}
					</Link>
					<Link to={PATHS.CONTACT}>Contact</Link>
				</div>
			</div>
			<a className={CSS.LINKEDIN_SMALLSCREEN} href={PATHS.LINKEDIN_COMPANY} target="_blank" rel="noreferrer">
				Linkedin →
			</a>
		</>
	)
}

export default Links
