import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React, { useCallback, useState } from 'react'

import { ReactComponent as CheckedIcon } from '../../../resources/img/checkedIcon.svg'
import { ReactComponent as UnCheckedIcon } from '../../../resources/img/uncheckedIcon.svg'

const CustomCheckboxes = () => {
	const [value, setValue] = useState<string>('')

	const handleChangeValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value)
	}, [])

	return (
		<RadioGroup value={value} onChange={handleChangeValue} row={true}>
			<FormControlLabel
				value="yes"
				control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} />}
				label="YES"
			/>
			<FormControlLabel
				value="no"
				control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} />}
				label="NO"
			/>
		</RadioGroup>
	)
}

export default CustomCheckboxes
