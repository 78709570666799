import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { ReactComponent as ArrowDown } from '../../resources/img/arrowDownGreen.svg'
import '../../styles/_careers.scss'
import { CSS } from '../../utils/domUtils'
import { PATHS } from '../../utils/paths'
import Header from '../common/Header'

const CareersRoute = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<div className={`${CSS.CAREERS} ${CSS.BACKGROUND}`}>
			<Helmet>
				<title>Careers - Join xtendr</title>
			</Helmet>
			<div className={CSS.WRAPPER}>
				<Header isLight={true} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
				<div className={CSS.CAREERS_BLOCK}>
					<h1 className={CSS.CARD_TITLE}>Join xtendr</h1>
					<div className={CSS.TEXT}>
						<h2 className={CSS.MAIN_TEXT}>
							You can find all of our latest job vacancies on{' '}
							<a href={PATHS.LINKEDIN_JOBS} target="_blank" rel="noreferrer">
								LinkedIn →
							</a>
						</h2>
						<div className={CSS.CARD_SUBTITLE}>
							We are also always keen to hear from experienced cryptographers. If you’re looking for a new
							challenge working with{' '}
							<Link to={PATHS.PRIVACY_ENHANCING_TECHNOLOGIES}>Privacy Enhancing Technologies</Link> in the
							rapidly-evolving field of data collaboration, we’d like to talk to you!
							<div className={CSS.CARD_TEXT}>You can reach us any time:</div>
							<div className={CSS.FLEX}>
								<a
									className={CSS.LINKEDIN_BTN}
									href={PATHS.LINKEDIN_COMPANY}
									target="_blank"
									rel="noreferrer"
								>
									Linkedin →
								</a>
								<a
									className={CSS.LINKEDIN_BTN}
									href={`mailto:HELLO@XTENDR.IO`}
									target="_blank"
									rel="noreferrer"
								>
									HELLO@XTENDR.IO →
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={CSS.TITLE_ARROW}>
				<ArrowDown />
			</div>
		</div>
	)
}

export default CareersRoute
