import React, { useState } from 'react'

import { ReactComponent as ArrowDown } from '../../resources/img/firstSectionArrowDown.svg'
import '../../styles/specificUseCase/_titleCard.scss'
import { CSS } from '../../utils/domUtils'
import Header from '../common/Header'
import { UsecaseType } from './useCaseTexts'

const TitleCard: React.FC<Partial<UsecaseType>> = ({ title, img, shortDescription }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)

	return (
		<>
			<div className={`${CSS.TITLE_CARD} ${CSS.BACKGROUND}`}>
				<div className={CSS.CONTAINER}>
					<Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
					<div className={`${CSS.FLEX_CENTER} ${CSS.FLEX_COLUMN}`}>
						<div className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>{title}</div>
						<div className={CSS.CARD_TEXT}>{shortDescription}</div>
					</div>
					<div className={CSS.TITLE_ARROW}>
						<ArrowDown />
					</div>
				</div>
			</div>
			<div className={CSS.LIGHT_BACKGROUND}>
				<div className={CSS.CONTAINER}>
					<div className={CSS.IMG_WRAPPER}>
						<img className={`${CSS.IMG} ${CSS.ROUNDED}`} src={img} alt={''} />
					</div>
				</div>
			</div>
		</>
	)
}

export default TitleCard
