import { useEffect, useState } from 'react'

const useScrollPosition = () => {
	const [scrollPosition, setScrollPosition] = useState(document.documentElement.scrollTop)

	useEffect(() => {
		const handleScroll = () => {
			setScrollPosition(document.documentElement.scrollTop)
		}

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return scrollPosition
}

export default useScrollPosition
